import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';

import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { getControlledErrorText } from '../misc';

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    maxWidth: 450,
  },
});

function ControlledSelect({ control, name, label, type, errors, rules, defaultValue, values }) {
  const renderValues = () => (
    values.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ))
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={
        ({ field }) => (
          <FormControl sx={{ maxWidth: 450, minHeight: 90 }} variant="outlined">
            <span>{label}</span>
            <StyledTextField
              {...field}
              size="small"
              margin="normal"
              select
              type={type}
              error={!!errors[name]}
              helperText={getControlledErrorText(name, errors)}
            >
              {renderValues()}
            </StyledTextField>
          </FormControl>
        )
      }
    />
  );
}

ControlledSelect.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.shape({})),
};

ControlledSelect.defaultProps = {
  control: {},
  name: '',
  label: '',
  type: 'text',
  errors: {},
  rules: {},
  defaultValue: '',
  values: [],
};

export default ControlledSelect;
