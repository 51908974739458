/* eslint quote-props: "off" */
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import styled from 'styled-components';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import Player from '../../Player';
import Playlist from '../Playlist';

import { getUser } from '../../../store/slices/userReducer';
import useAuth from '../../../hooks/useAuthFunctions';
import { options, settings } from '../constants';
import {
  SMALL_SCREEN_SIZE,
  MEDIUM_SCREEN_SIZE,
  USER_TYPE_HOST,
  HOST_LOGIN_PATH,
} from '../../common/constants';
import BorderedLogoSVG from '../../../images/BorderedLogoSVG.svg';

const Container = styled.div`
  display: flex;
  height: ${(props) => (props.isOpen ? '100vh' : 'none')};

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    flex-direction: column;
    overflow: ${(props) => (props.disableScrolling ? 'hidden' : 'auto')};
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 80%;

  @media (max-width: ${MEDIUM_SCREEN_SIZE}) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: auto;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    padding: 8px 0;
  }
`;

const VenueImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 8px 16px;
  background-color: ${(props) => (props.isSelected ? '#1F1D29' : '')};
  margin-bottom: auto;
`;

const SettingsLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  display: ${(props) => (props.isVisible ? 'inherit' : 'none')};
`;

const StyledButton = styled(Button)`
  font: inherit;
  font-size: 20px;
  color: inherit;
  text-transform: none;
  margin-bottom: 6px;

  :hover {
    background-color: transparent;
    color: #fff;
  }
`;

const Options = styled(AppBar)`
  align-items: center;

  &.MuiAppBar-root {
    padding: 40px 16px;
    max-width: 240px;
    background-color: #131313;
    gap: 48px;
    height: 100%;

    @media (max-width: ${SMALL_SCREEN_SIZE}) {
      width: 100%;
      max-width: none;
      height: ${(props) => (props.$isDesktop ? '100%' : 'auto')};
      gap: ${(props) => (props.$isDesktop ? '40px' : '0px')};
      padding: 8px 24px;
    }
  }
`;

const StyledToolbar = styled(Toolbar)`
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: ${SMALL_SCREEN_SIZE}) {
    padding-left: 0;
    padding-right: 0;
  }
`;

const StyledLink = styled(NavLink)({
  color: '#FFF',
  fontSize: '20px',
  fontWeight: '400',
  margin: '10px 0',
  padding: '8px 16px',
  textDecoration: 'none',

  '&.active': {
    backgroundColor: '#1F1D29',
    borderRadius: '100px',
    color: 'inherit',
    fontStyle: 'normal',
  },

  ':hover': {
    color: 'inherit',
  },
});

const StyledSettingsLink = styled(StyledButton)({
  padding: '6px',
  marginBottom: '0px',
  '&.active': {
    backgroundColor: '#FFF',
    color: '#1F1D29',
  },
});

function HostDashboard() {
  const isMobile = useMediaQuery(`(max-width: ${SMALL_SCREEN_SIZE})`);
  const [isSettingsSelected, setIsSettingsSelected] = useState(false);
  const [openMenu, setOpenMenu] = useState(!isMobile && isSettingsSelected); // if mobile menu is selected close at rendering.

  const user = useSelector(getUser);
  const { logout } = useAuth();

  const renderNavigationLink = (type, data) =>
    data.map(({ header, path, icon }) => {
      if (type === 'options') {
        return (
          <StyledLink
            key={path}
            className={({ isActive }) => (isActive ? 'active' : '')}
            to={path}
            end
          >
            {header}
          </StyledLink>
        );
      }
      return (
        <StyledSettingsLink
          key={path}
          component={NavLink}
          startIcon={icon}
          className={({ isActive }) => (isActive ? 'active' : '')}
          to={path}
        >
          {header}
        </StyledSettingsLink>
      );
    });

  const renderMenuButton = () => (
    <StyledButton
      sx={{
        padding: '0px',
        minWidth: '0px',
      }}
      onClick={() => setOpenMenu((state) => !state)}
    >
      {openMenu ? <CloseIcon /> : <MenuIcon />}
    </StyledButton>
  );

  return (
    <Container disableScrolling={isMobile && openMenu} isOpen={!isMobile || openMenu}>
      <Options $isDesktop={openMenu.toString()} position="relative">
        <DetailsContainer>
          <VenueImage alt="venue image" src={user.image || BorderedLogoSVG} />
          {`${user.displayName || USER_TYPE_HOST} Dashboard`}
          {isMobile ? renderMenuButton() : null}
        </DetailsContainer>
        {openMenu || !isMobile ? (
          <StyledToolbar>
            {renderNavigationLink('options', options)}
            <SettingsContainer isSelected={isSettingsSelected}>
              <StyledButton onClick={() => setIsSettingsSelected((state) => !state)}>
                Settings
              </StyledButton>
              <SettingsLinksContainer isVisible={isSettingsSelected}>
                {renderNavigationLink('settings', settings)}
              </SettingsLinksContainer>
            </SettingsContainer>
            <StyledLink
              to={HOST_LOGIN_PATH}
              onClick={() => {
                logout();
              }}
              end
              style={{
                marginBottom: '25%',
              }}
            >
              Log out
            </StyledLink>
          </StyledToolbar>
        ) : null}
      </Options>
      <ContentContainer>
        <Playlist />
        <Outlet />
      </ContentContainer>
      {(!isMobile || !openMenu) && <Player />}
    </Container>
  );
}

export default HostDashboard;
