import React, { useState, useEffect, useDeferredValue } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import MuiIconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';

import SongDetails from '../SongDetails';
import IconButton from '../../../common/IconButton';
import Dialog from '../../../common/Dialog';

import { GA_HOST_ADDED_SONG_ACTIVITY, GA_HOST_ADD_SONG } from '../../../common/constants';
import { getRequestSongsList, hostAddSong } from '../../../../services/api';
import useGAEventTracker from '../../../../hooks/useGAEventTracker';
import theme from '../../../common/theme';
import { notifyError, notifyInfo } from '../../../../store/slices/notificationReducer';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const SongsList = styled.div`
  max-height: 300px;
  overflow-y: auto;
  padding: 8px;
  margin-bottom: 16px;
  scrollbar-color: #c7c7cc transparent;
`;

const StyledSearchBar = styled(TextField)({
  padding: '10px',
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '100px',
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 16px',
    textOverflow: 'ellipsis',
  },
});

const StyledCard = styled(Card)({
  '&.MuiCard-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    borderRadius: '0px',
    background: 'none',
    border: `1px solid ${theme.palette.juxy.secondary}`,
    borderWidth: '0 0 1px 0',
    padding: '8px 10px',
    gap: '16px',
    boxShadow: 'none',
  },
});

const AddSongButtonStyle = {
  textTransform: 'none',
  color: 'white',
  backgroundColor: '#1F1D29',
  fontSize: '16px',
  border: '1px solid white',
  borderRadius: '50px',
  minHeight: '40px',
  maxWidth: '130px',
  minWidth: 'unset',
  margin: '10px',
  flexDirection: 'row-reverse',
  alignSelf: 'center',
  boxShadow: 'none',
  alignSelf: 'end',
  '&:hover': {
    backgroundColor: 'white',
    color: theme.palette.juxy.main,
  },
  '&:disabled': {
    border: `1px solid ${theme.palette.juxy.secondary}40`,
    color: `${theme.palette.juxy.secondary}40`,
  },
  '.MuiButton-startIcon': {
    margin: '0 3px 0 0',
  },
};

function AddSongModal({ isOpen = false, onClose = () => {} }) {
  const [requestSongsList, setRequestSongsList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSongId, setSelectedSongId] = useState(undefined);

  const deferredSearch = useDeferredValue(searchTerm);
  const dispatch = useDispatch();

  const gaEventTracker = useGAEventTracker(GA_HOST_ADD_SONG);

  useEffect(() => {
    const getAvailableSongsList = async () => {
      const receivedSongList = await getRequestSongsList(deferredSearch);
      setRequestSongsList(receivedSongList);
    };

    getAvailableSongsList();
  }, [deferredSearch]);

  const handleSearchOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleOnClose = () => {
    setSearchTerm('');
    setSelectedSongId(undefined);
    onClose();
  };

  const handleOnSubmit = async () => {
    if (!selectedSongId) {
      dispatch(notifyInfo({ message: 'Please choose a song' }));
      return;
    }

    try {
      await hostAddSong(selectedSongId);
      gaEventTracker(GA_HOST_ADDED_SONG_ACTIVITY);
      handleOnClose();
    } catch (error) {
      dispatch(notifyError({ message: error.message }));
    }
  };

  const renderSongList = () => {
    if (!requestSongsList || requestSongsList.length === 0) {
      return <p style={{ textAlign: 'center' }}>No matching songs were found</p>;
    }

    return requestSongsList
      ?.filter((song) =>
        searchTerm === '' ||
        song.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        song.artist?.toLowerCase().includes(searchTerm.toLowerCase())
          ? song
          : null
      )
      .map((song) => (
        <StyledCard
          key={song.id}
          onClick={() => setSelectedSongId((prev) => (prev === song.id ? '' : song.id))}
        >
          <SongDetails
            songName={song.name}
            artist={song.artist}
            image={song.image}
            style={{
              background: selectedSongId === song.id ? '#FFFFFF' : 'none',
              color: selectedSongId === song.id ? '#000000' : '#FFFFFF',
              width: '100%',
              borderRadius: '10px',
              padding: '8px',
            }}
          />
        </StyledCard>
      ));
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose} fullWidth>
      <HeaderContainer>
        <StyledSearchBar
          size="large"
          placeholder="Search for a song or by artist"
          value={searchTerm}
          onChange={handleSearchOnChange}
          InputProps={{ endAdornment: <SearchIcon /> }}
        />
        <MuiIconButton
          edge="end"
          color="inherit"
          onClick={handleOnClose}
          aria-label="close"
          sx={{ margin: 0 }}
        >
          <CloseIcon />
        </MuiIconButton>
      </HeaderContainer>
      <SongsList>{renderSongList()}</SongsList>
      <IconButton
        label="Add Song"
        startIcon={<AddIcon />}
        sx={AddSongButtonStyle}
        onClick={handleOnSubmit}
        disabled={!selectedSongId}
      />
    </Dialog>
  );
}

export default AddSongModal;
