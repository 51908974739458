import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useSelector } from 'react-redux';
import PlaylistOptionCard from '../PlaylistOptionCard';
import theme from '../../common/theme';
import VoteCard from '../VoteCard';
import muteSound from '../../../images/muteSound.png';
import sound from '../../../images/sound.png';
import { getUser, getUserVolumeComplaint } from '../../../store/slices/userReducer';
import { complainVolume, getProfile } from '../../../services/api';

const votedComplaint = {
  0: 'low',
  1: 'high',
};

const votedComplaintIndex = {
  low: 0,
  high: 1,
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    margin-top: 30px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    color: ${theme.palette.juxy.secondary};
    font-size: 16px;
    font-weight: 400;
`;

const VoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Versus = styled.span`
  font-size: 28px;
  margin: 34px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  margin: 10px 30px;
`;

function VolumeComplaintPoll({
  open = false,
  onClose = () => {},
  successfulCallback = () => {},
}) {
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const [voted, setVoted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please select your choice');
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    if (!open) {
      setSelectedCardIndex(-1);
    }

    getProfile().then((profile) => {
      const complaint = profile?.complaint;
      setVoted(complaint);
      setSelectedCardIndex(votedComplaintIndex[complaint]);
    });
  }, []);

  const handleAlertErrorOnClose = () => { setShowError(false); };

  const handleCardSelection = (cardIndex) => {
    if (voted) { return; }
    setSelectedCardIndex(cardIndex);
  };

  const handleOnSubmit = () => {
    if (selectedCardIndex < 0) {
      setErrorMessage('Please select your choice');
      setShowError(true);
      return;
    }
    const volume = votedComplaint[selectedCardIndex];
    setVoted(volume);
    complainVolume(volume);
    onClose();
    successfulCallback();
  };

  return (
    <PlaylistOptionCard
      fullScreen
      open={open}
      onClose={onClose}
    >
      <Container>
        <DescriptionContainer>
          <h1 style={{ color: 'white' }}>Volume complaint</h1>
          <span>{voted ? 'Thanks for your vote!' : 'Tell us what you think about the volume'}</span>
        </DescriptionContainer>
        <VoteContainer>
          <VoteCard
            onSelected={() => handleCardSelection(0)}
            isSelected={selectedCardIndex === 0}
            title="Volume is too low"
            image={muteSound}
            voted={!!voted}
          />
          <Versus>Vs.</Versus>
          <VoteCard
            onSelected={() => handleCardSelection(1)}
            isSelected={selectedCardIndex === 1}
            title="Volume is too high"
            image={sound}
            voted={!!voted}
          />
        </VoteContainer>
        <ButtonsContainer>
          <Button
            sx={{
              backgroundImage: theme.palette.juxy.gradient,
              borderRadius: '50px',
              height: '52px',
              width: '100px',
              color: '#FFFFFF',
              fontSize: '16px',
            }}
            onClick={voted ? onClose : handleOnSubmit}
          >
            {voted ? 'Finish' : 'Submit'}
          </Button>
        </ButtonsContainer>
        <Snackbar open={showError} autoHideDuration={6000} onClose={handleAlertErrorOnClose}>
          <MuiAlert onClose={handleAlertErrorOnClose} severity="error" sx={{ width: '100%', fontSize: '16px' }}>
            {errorMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </PlaylistOptionCard>
  );
}

VolumeComplaintPoll.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default VolumeComplaintPoll;
