import React from 'react';
import styled from 'styled-components';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import UnderConstruction from './UnderConstruction';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 24px 0 24px;
`;

const Logo = styled.img`
  border-radius: 10px;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-size: 32px;
  line-height: normal;
  text-align: center;
`;

const Paragraph = styled.span`
  font-size: 18px;
  line-height: normal;
`;

const Icons = styled.div`
  display: flex;
  gap: 12px;
  position: absolute;
  bottom: 40px;
`;

function ComingSoon() {
  return (
    <Container>
      <UnderConstruction title={''} text={''} />
      <div style={{ maxWidth: '445px', marginBottom: '48px' }}>
        <Paragraph>
          The first crowd-focused, data-driven music solution,
          that aims to bring the right music to any audience.
        </Paragraph>
      </div>
      <Title>Stay tuned,</Title>
      <Title> Juxy is coming soon...</Title>
      <Icons>
        <a href="https://www.linkedin.com/company/juxy/" target="_blank" rel="noreferrer">
          <LinkedInIcon sx={{ height: '28px', width: '28px', color: '#ffffff' }} />
        </a>
        <a
          href="mailto:team@juxy.io"
          style={{ display: 'flex', color: '#ffffff', height: '28px', alignItems: 'center' }}
        >
          <MailOutlineIcon sx={{ height: '28px', width: '28px', color: '#ffffff', marginRight: '4px' }} />
          team@juxy.io
        </a>
      </Icons>
    </Container>
  );
}

export default ComingSoon;
