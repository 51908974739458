/* eslint no-unsafe-optional-chaining: "off" */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleIcon from '@mui/icons-material/Circle';

import img from '../../images/JuxyDiscLogo.png';

const VenueImage = styled(CardMedia)`
  max-height: 100px;
  max-width: 100px;
  padding: 10px;
  border-radius: 14px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 20px;
  font-weight: 400;
`;

const Details = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;
  font-weight: 200;
`;

const cardStyle = {
  display: 'flex',
  width: '100%',
  maxWidth: '400px',
  height: '95px',
  backgroundColor: '#4a4550bd',
  color: '#fff',
  ':hover': {
    backgroundColor: '#4a4550',
  },
};

function VenueCard({ cardTitle, venueName, currentSong, image, onClick }) {
  return (
    <Card sx={cardStyle} onClick={onClick}>
      <VenueImage sx={{ width: 100 }} component="img" alt={cardTitle} image={image} />
      <DescriptionContainer>
        <Description>
          <Title>{cardTitle}</Title>
          <Details>
            {venueName}
            <CircleIcon sx={{ fontSize: '6px' }} />
            {currentSong}
          </Details>
        </Description>
        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '20px', marginRight: '10px' }} />
      </DescriptionContainer>
    </Card>
  );
}

VenueCard.propTypes = {
  cardTitle: PropTypes.string,
  venueName: PropTypes.string,
  currentSong: PropTypes.string,
  image: PropTypes.string,
};

VenueCard.defaultProps = {
  cardTitle: '',
  venueName: '',
  currentSong: '',
  image: img,
};

export default VenueCard;
