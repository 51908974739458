/* eslint no-unused-vars: "off", max-len: "off" */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import FastRewindOutlinedIcon from '@mui/icons-material/FastRewindOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';

import logo from '../../../../images/JuxyDiscLogo.png'

import theme from '../../../common/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: ${(props) => props.style?.width || 'auto'};
`;

const StyledCard = styled(Card)({
  '&.MuiCard-root': {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    color: 'white',
    borderRadius: '0px',
    padding: '5px 0',
    background: 'none',
    border: `1px solid ${theme.palette.juxy.secondary}`,
    borderWidth: (props) => (props.sx.topBorder ? '1px 0' : '0 0 1px 0'),
  },
});

const Label = styled.div`
  display: flex;
  gap: 8px;
  color: #666666;
  font-size: 16px;
  margin: 5px 0;
`;

const SongImage = styled(CardMedia)`
  height: 76px;
  width: 76px;
  padding: 7px;
  border-radius: 14px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: normal; 
`; // justify-content: normal overrides auto injected -webkit-justify-content that screws things up

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const SongTitle = styled.span`
  color: ${(props) => (props.playing ? 'white' : theme.palette.juxy.secondary)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:'pre-line';
`;

function SongCard({ song, playing, label, options, style }) {
  const { name, artist, image } = song;

  return (
    <Container playing={playing} style={style}>
      <StyledCard sx={{ ...style }}>
        {label && (
          <Label>
            {(!playing && label === 'Previous Song') && <FastRewindOutlinedIcon />}
            {playing && <PlayArrowOutlinedIcon />}
            {label}
          </Label>
        )}
        <DescriptionContainer>
          <SongImage sx={{ width: '76px' }} component="img" alt="title" image={image || logo} />
          <Description>
            <SongTitle playing={playing}>{name}</SongTitle>
            <SongTitle playing={playing} sx={{ paddingBottom: '5px' }}>{artist}</SongTitle>
          </Description>
          {/*  handles optional card controls rendering */}
          {options}
        </DescriptionContainer>
      </StyledCard>
    </Container>
  );
}

SongCard.propTypes = {
  song: PropTypes.shape({}),
  playing: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.element,
  style: PropTypes.shape({}),
};

SongCard.defaultProps = {
  song: {},
  playing: false,
  label: '',
  style: {},
};

export default SongCard;
