import { useRef, useCallback } from 'react';

function useDebounceCallback(callback = () => {}, wait = 5000) {
  const timeout = useRef();

  return useCallback((...args) => {
    const debounce = () => {
      clearTimeout(timeout.current);
      callback(args);
    }

    clearTimeout(timeout.current);
    timeout.current = setTimeout(debounce, wait);
  }, [callback, wait]);
};

export default useDebounceCallback;
