import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userReducer';
import notificationReducer from './slices/notificationReducer';

export default configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
  },
});
