import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import img from '../../../../../images/JuxyDiscLogo.png';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: flex-start;
    width: 90%
`;

const StyledCard = styled(Card)({
  gap: '8px',
  '&.MuiCard-root': {
    display: 'flex',
    width: '90%',
    maxWidth: '340px',
    color: 'white',
    background: (props) => (`-webkit-linear-gradient(
      left, #9045FF ${props.percentage / 1.8 || 0}%,
      ${props.percentage === 100
        ? '#9045FF' : (props.selected
          ? '#FFFFFF40' : 'transparent')} ${props?.percentage || 0}%)`
    ),
    border: '1px solid white',
    borderRadius: '8px',
  },
  '&:hover': {
    background: (props) => !props.percentage && !props.$voted && '#A8A8A8',
  },
});

const SongImage = styled(CardMedia)`
  width: 80px;
  padding: 8px;
  border-radius: 15px;
`;

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
`;

const Title = styled.span`
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
`;

function VoteCard({ title, subTitle, image, style, percentage, isSelected, onSelected, voted }) {
  return (
    <Container>
      <span style={{ maxWidth: '340px', width: '90%', fontSize: '12px' }}>{!!percentage && `${percentage.toFixed(2)}%`}</span>
      <StyledCard
        percentage={percentage}
        selected={isSelected}
        $voted={voted}
        sx={{ ...style }}
        onClick={() => onSelected()}
      >
        <SongImage component="img" alt="song image" image={image} />
        <DescriptionContainer>
          <Description>
            <Title style={{ fontSize: '18px', fontWeight: 700 }}>{title}</Title>
            {subTitle && <Title style={{ fontSize: '18px', fontWeight: 400 }}>{subTitle}</Title>}
          </Description>
        </DescriptionContainer>
      </StyledCard>
    </Container>
  );
}

VoteCard.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  percentage: PropTypes.number,
  onSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  voted: PropTypes.bool,
};

VoteCard.defaultProps = {
  title: '',
  subTitle: '',
  image: img,
  percentage: 0,
  onSelected: () => {},
  isSelected: false,
  voted: true,
};

export default VoteCard;
