import theme from './theme';

export const submitButtonSx = {
  backgroundImage: theme.palette.juxy.gradient,
  borderRadius: '50px',
  height: '50px',
  minWidth: '300px',
  color: '#FFFFFF',
  margin: '20px 0',
};

export const backButtonSx = {
  borderRadius: '50px',
  height: '50px',
  minWidth: '300px',
  color: '#FFFFFF',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#44A9DB',
    },
  },
};
