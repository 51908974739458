import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PlaylistOptionCard from '../PlaylistOptionCard';
import VoteCard from './VoteCard';

import useGAEventTracker from '../../../../../hooks/useGAEventTracker';
import { GA_GUEST_PLAYLIST, GA_VOTE_SONG_CLICKED_ACTIVITY, GA_VOTE_SONG_SUCCESSFULLY_ACTIVITY } from '../../../../common/constants';
import { getVoteData, voteSong } from '../../../../../services/api';
import CtaButtonStyle from '../CtaButtonStyle';
import theme from '../../../../common/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80%;
    margin-top: 30px;
    gap: 55px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    color: ${theme.palette.juxy.secondary};
    font-size: 16px;
    font-weight: 400;
`;

const Versus = styled.span`
  font-size: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  margin: 10px 30px;
`;

const PollCTAButtonStyle = {
  backgroundImage: 'linear-gradient(143.44deg, #9245FF 15.24%, #44A9DB 91.4%)',
  borderRadius: '50px',
  color: '#FFFFFF',
  fontSize: '16px',
  height: '52px',
  width: '100px',
};

function NextSongPoll({ onSuccess = () => {}, onFailure = () => {} }) {
  const [displaySongPoll, setDisplaySongPoll] = useState(false);
  const [selectedSongIndex, setSelectedSongIndex] = useState(-1);
  const [pollData, setPollData] = useState([]);
  const [voted, setVoted] = useState(false);

  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  useEffect(() => {
    const fetchVoteData = async () => {
      try {
        const data = await getVoteData();
        if (data?.enabled === false || data?.result === 0) {
          onFailure('Cannot get vote options. Please try again later.');
          return;
        }

        setPollData([data?.firstSongData, data?.secondSongData]);
        setVoted(data?.voted)

      } catch (error) {
        onFailure('Failed getting song poll data');
      }
    }

    fetchVoteData();
  }, []);

  
  const handleVoteSongClicked = (requestSuccess = false) => {
    if (!displaySongPoll) {
      gaEventTracker(GA_VOTE_SONG_CLICKED_ACTIVITY);
    } else {
      setSelectedSongIndex(-1); // reset selection when poll closed
    }

    if (requestSuccess) {
      gaEventTracker(GA_VOTE_SONG_SUCCESSFULLY_ACTIVITY);
      onSuccess();
    } else {
      setDisplaySongPoll((prev) => !prev); // avoid closing poll card on success to show vote status
    }
    
  };

  const handleOnSubmit = async () => {
    if (selectedSongIndex < 0) {
      onFailure('Please select a song before voting');
      return;
    }
    
    const data = await voteSong(selectedSongIndex);
    if (!data) {
      onFailure('Failed to submit vote. Please try again later.');
      return
    }

    setPollData((prevData) => ([
      { ...prevData[0], votePercentage: data?.score[0] },
      { ...prevData[1], votePercentage: data?.score[1] },
    ]));
    setVoted(!!data?.songId);
    handleVoteSongClicked(true);
  };

  const handleCardSelection = (cardIndex) => {
    if (voted) { return; }
    setSelectedSongIndex(cardIndex);
  };

  const renderCallToActionButton = () => (
    voted ? (
      <Button
        sx={PollCTAButtonStyle}
        onClick={() => handleVoteSongClicked(false)}
      >
        Finish
      </Button>
    ) : (
      <Button
        sx={PollCTAButtonStyle}
        onClick={handleOnSubmit}
        disabled={selectedSongIndex < 0}
      >
        Vote
      </Button>
    )
  );

  const renderDescriptionContainer = () => (
    voted ? (
      <>
        <h1 style={{ color: 'white' }}>Current vote results</h1>
        <span>Great Choice! those are the current results</span>
      </>
    ) : (
      <>
        <h1 style={{ color: 'white' }}>Vote For Next Song</h1>
        <span>Choose which song you want to listen to next</span>
      </>
    )
  );

  return (
    <>
      <>
        <Button variant="contained" size="large" sx={CtaButtonStyle} onClick={() => handleVoteSongClicked(false)}>
          Vote Next Song
        </Button>
      </>
      <PlaylistOptionCard
        fullScreen
        open={displaySongPoll}
        onClose={() => handleVoteSongClicked(false)}
      >
        <Container>
          {(!pollData.length || pollData.includes(undefined)) ? <CircularProgress color="secondary" /> : (
            <>
              <DescriptionContainer>
                {renderDescriptionContainer()}
              </DescriptionContainer>
              <VoteCard
                onSelected={() => handleCardSelection(0)}
                isSelected={selectedSongIndex === 0}
                title={pollData[0]?.name}
                subTitle={pollData[0]?.artist}
                image={pollData[0]?.image}
                percentage={voted ? pollData[0]?.votePercentage : 0}
                voted={voted}
              />
              <Versus>Vs.</Versus>
              <VoteCard
                onSelected={() => handleCardSelection(1)}
                isSelected={selectedSongIndex === 1}
                title={pollData[1]?.name}
                subTitle={pollData[1]?.artist}
                image={pollData[1]?.image}
                percentage={voted ? pollData[1]?.votePercentage : 0}
                voted={voted}
              />
              <ButtonsContainer>
                {renderCallToActionButton()}
              </ButtonsContainer>
            </>
          )}
        </Container>
      </PlaylistOptionCard>
    </>
  );
}

NextSongPoll.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};


export default NextSongPoll;
