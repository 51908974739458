import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CardMedia from '@mui/material/CardMedia';

import Image from '../../../images/JuxyDiscLogo.png';
import { SMALL_SCREEN_SIZE, MEDIUM_SCREEN_SIZE } from '../../common/constants';

const DescriptionContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  overflow: hidden;
`;

const SongImage = styled(CardMedia)`
  max-width: 50px;
  aspect-ration: 1;
  border-radius: 10px;

  @media (min-width: ${SMALL_SCREEN_SIZE}) {
    max-width: 62px;
  }
`;

const SongName = styled.span`
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  font-weight: bold;

  @media (min-width: ${MEDIUM_SCREEN_SIZE}) {
    font-size: 20px;
  }
`;

const ArtistName = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;

  @media (min-width: ${MEDIUM_SCREEN_SIZE}) {
    font-size: 16px;
  }
`;

function SongDetails({ image = Image, songName = '', artist = '' }) {
  return (
    <DescriptionContainer>
      <SongImage component="img" alt="title" image={image} />
      <Description>
        <SongName title={songName}>{songName}</SongName>
        <ArtistName>{artist}</ArtistName>
      </Description>
    </DescriptionContainer>
  );
}

SongDetails.propTypes = {
  image: PropTypes.string,
  songName: PropTypes.string,
  artist: PropTypes.string,
};

export default SongDetails;
