import { useEffect } from 'react';
import { getSearch } from '../components/common/misc';

const messageTranslator = {
  email_not_registered: 'Email is not registered',
  user_declined: '',
};

function useSpotifyAuth() {
  useEffect(() => {
    const { success, msg } = getSearch();
    if (success) {
      window.opener?.postMessage({
        type: 'spotify',
        payload: { success, message: success !== '0' ? '' : messageTranslator[msg] },
      }, '*');
    } else {
      // fallback to close new tab for when user declines requested scope.
      window.opener?.postMessage({ type: 'spotify' }, '*');
    }
  }, []);
}

export default useSpotifyAuth;
