import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import theme from '../common/theme';
import SongCard from '../Guests/SongCard';
import { SpotifyIcon } from '../common/icons';

const CtaStyle = {
  backgroundColor: 'transparent',
  border: '1px white solid',
  borderRadius: '50px',
  height: '50px',
  margin: '10px 0',
  width: '90%',
  alignSelf: 'center',
  textTransform: 'none',
  fontFamily: 'inherit',
  fontWeight: 'normal',
  '&:hover': {
    backgroundColor: theme.palette.juxy.secondary,
  },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    width: 100%;
`;

function MusicLibraryCard({ song, onSpotifySelect, onRemoveStarredSelect }) {
  return (
    <Container>
      <SongCard
        song={song}
        style={{ topBorder: true, mt: '16px', width: '90%', justifyContent: 'none' }}
      />
      <Button
        variant="contained"
        color="juxy"
        startIcon={<SpotifyIcon />}
        sx={CtaStyle}
        onClick={onSpotifySelect}
      >
        Open in Spotify
      </Button>
      <Button
        variant="contained"
        color="juxy"
        startIcon={<DeleteOutlinedIcon />}
        sx={CtaStyle}
        onClick={onRemoveStarredSelect}
      >
        Remove from starred
      </Button>
    </Container>
  );
}

MusicLibraryCard.propTypes = {
  song: PropTypes.shape({}),
  onRemoveStarredSelect: PropTypes.func,
  onSpotifySelect: PropTypes.func,
};

MusicLibraryCard.defaultProps = {
  song: {},
  onRemoveStarredSelect: () => {},
  onSpotifySelect: () => {},
};

export default MusicLibraryCard;
