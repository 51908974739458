import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

const style = {
  textTransform: 'none',
  color: 'black',
  backgroundColor: 'white',
  fontSize: '16px',
  minWidth: 200,
  borderRadius: '50px',
  minHeight: '40px',
  margin: '10px 0',
  '&:hover': {
    backgroundColor: 'white',
    color: '#9c27b0',
  },
  '.MuiButton-startIcon': {
    margin: '0 3px 0 0',
  },
};

function IconButton({ type, startIcon, label, href, sx, onClick, disabled }) {
  return (
    <Button
      type={type}
      variant="contained"
      startIcon={startIcon}
      href={href}
      onClick={onClick}
      disabled={disabled}
      sx={{ ...style, ...sx }}
    >
      {label}
    </Button>
  );
}

IconButton.propTypes = {
  type: PropTypes.string,
  startIcon: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  href: PropTypes.string,
  sx: PropTypes.PropTypes.shape({}),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

IconButton.defaultProps = {
  type: 'link',
  label: '',
  href: '',
  sx: {},
  onClick: () => {},
  disabled: false,
};

export default IconButton;
