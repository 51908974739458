import React, { useState } from 'react';
import styled from 'styled-components';

import Slider from '@mui/material/Slider';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

import { setVolume } from '../../../services/api';
import useDebounceCallback from '../../../hooks/useDebounceCallback';

import theme from '../../common/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const VolumeSlider = styled(Slider)({
  width: '120px',
  color: theme.palette.juxy.main,

  '& .MuiSlider-thumb': {
    color: theme.palette.juxy.secondary,
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: `0 0 0 6px ${theme.palette.juxy.main}30`,
    },
  },

  '& .MuiSlider-valueLabel': {
    backgroundColor: '#131313',
  },
});

const DEFAULT_VOLUME_PERCENT = 75;
const DEBOUNCE_TIMEOUT_MS = 300;

function VolumeControl({ onError }) {
  const [volumePercent, setVolumePercent] = useState(DEFAULT_VOLUME_PERCENT);

  const debouncedVolumeChange = useDebounceCallback
    (async (volumeLevelRequest) => {
      if (volumeLevelRequest === undefined) {
        return;
      }

      let newVolume = volumePercent;
      try {
        newVolume = await setVolume(volumeLevelRequest);
      } catch (error) {
        onError();
      } finally {
        setVolumePercent(isNaN(newVolume) ? DEFAULT_VOLUME_PERCENT : newVolume);
      }
    }, DEBOUNCE_TIMEOUT_MS);

  return (
    <Container>
      <VolumeUpIcon />
      <VolumeSlider
        aria-label="Volume"
        value={volumePercent}
        onChange={(_, newVolume) => {
          setVolumePercent(newVolume);
          debouncedVolumeChange(newVolume);
        }}
        valueLabelDisplay="auto"
      />
    </Container>
  );
}

export default VolumeControl;
