import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useGAEventTracker from '../../../hooks/useGAEventTracker';

import SpotifyAuth from '../../Login/ServiceLogin/SpotifyAuth';

import {
  GA_HOST_REGISTRATION,
  GA_SPOTIFY_PREFERENCES_ACTIVITY,
  HOST_DASHBOARD_PATH,
  USER_TYPE_HOST,
} from '../../common/constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

function ConnectAppStep({ showAlert, setAlertMessage }) {
  const gaEventTracker = useGAEventTracker(GA_HOST_REGISTRATION);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMessageReceived = (message) => {
    const isSuccessMessage = !!message.payload?.success;
    dispatch(verifyUser(isSuccessMessage));
    if (!isSuccessMessage) {
      // spotify login/authentication failed.
      renderAlert(message.payload.message);
      return;
    }
    navigate(HOST_DASHBOARD_PATH);
  };

  const renderAlert = (error) => {
    setAlertMessage(error);
    showAlert(true);
  };

  return (
    <Container>
      <SpotifyAuth
        userType={USER_TYPE_HOST}
        onClick={() => gaEventTracker(GA_SPOTIFY_PREFERENCES_ACTIVITY)}
        onMessageReceived={handleMessageReceived}
      />
      {/* TODO: add more streaming apps authentication methods when ready */}
    </Container>
  );
}

export default ConnectAppStep;
