import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import VenueCard from './VenueCard';
import { SMALL_SCREEN_SIZE } from '../common/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  gap: 8px;
  width: 100%;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    align-items: center;
  }
`;

function Venues({ venueList, onSelect }) {
  // venue list is currently returned as active playlists list, therefore changed venueType to currentSong
  const renderVenueList = () =>
    venueList?.map((venue) => (
      <VenueCard
        key={venue.id}
        cardTitle={venue.title}
        venueName={venue.name || 'Wonderland'}
        currentSong={venue.currentSong}
        image={venue.image}
        onClick={() => onSelect(venue)}
      />
    ));

  return (
    <Container>
      {venueList?.length > 0 ? (
        renderVenueList()
      ) : (
        <span style={{ color: 'white' }}>
          No venues nearby
        </span>
      )}
    </Container>
  );
}

Venues.propTypes = {
  venueList: PropTypes.arrayOf(PropTypes.shape({})),
  onSelect: PropTypes.func,
};

Venues.defaultProps = {
  venueList: [],
  onSelect: () => {},
};

export default Venues;
