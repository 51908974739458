import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import CtaStyle from '../CtaButtonStyle';
import PlaylistOptionDrawer from '../PlaylistOptionDrawer';

import useGAEventTracker from '../../../../../hooks/useGAEventTracker';
import { removeGuestFromVenue } from '../../../../../services/api';
import { GA_GUEST_PLAYLIST,
         GA_USER_LEFT_PLAYLIST_ACTIVITY,
         GUEST_DASHBOARD_PATH } from '../../../../common/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
  padding-top: 8px;
`;

const LeavePlaylistButton = styled(Button)({
  color: 'white',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '12px',
  fontWeight: 'normal',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'unset',
  },
});

function LeavePlaylist({ onFailure = () => {} }) {
  const [openLeavePlaylistDrawer, setOpenLeavePlaylistDrawer] = useState(false);
  
  const navigate = useNavigate();
  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  const handleLeavePlaylistClicked = async () => {
    gaEventTracker(GA_USER_LEFT_PLAYLIST_ACTIVITY);

    try {
      const leaveSuccess = await removeGuestFromVenue();
      setOpenLeavePlaylistDrawer(false);
      if (leaveSuccess) { // TODO: how to handle failure?
        navigate(GUEST_DASHBOARD_PATH);
      }
    } catch (error) {
      onFailure(error.message);
    }
  };

  return (
    <>
      <LeavePlaylistButton
        variant="text"
        size="large"
        onClick={() => setOpenLeavePlaylistDrawer(true)}
      >
        Leave Playlist
      </LeavePlaylistButton>
      <PlaylistOptionDrawer
        open={openLeavePlaylistDrawer}
        onClose={() => setOpenLeavePlaylistDrawer(false)}
      >
        <Container>
          <p>Are you sure you want to leave this playlist?</p>
          <Button
            variant="contained"
            size="large"
            sx={{
              ...CtaStyle,
              '&:hover': {
                backgroundColor: '#360d0d',
              },
            }}
            onClick={handleLeavePlaylistClicked}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={CtaStyle}
            onClick={() => setOpenLeavePlaylistDrawer(false)}
          >
            No
          </Button>
        </Container>
      </PlaylistOptionDrawer>
    </>
  )
}

LeavePlaylist.propTypes = {
  onFailure: PropTypes.func,
};

export default LeavePlaylist;
