import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Controller } from 'react-hook-form';
import { getControlledErrorText } from '../misc';

const Label = styled.span`
  font-size: 22px;
`;

const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    maxWidth: '450px',
  },
});

function ControlledTextField(props) {
  const {
    control,
    name,
    label,
    type,
    errors,
    rules,
    defaultValue,
    handleShowPasswordOnClick,
    showPassword,
    toggleSelected,
    placeholder,
    helperText,
  } = props;
  const isPasswordField = type === 'password' || showPassword;

  const renderShowPasswordIcon = () => (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={handleShowPasswordOnClick}
        edge="end"
      >
        {showPassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  );

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={
          ({ field }) => (
            <FormControl sx={{ maxWidth: 450, minHeight: 90, margin: '10px 0' }} variant="outlined">
              <Label>{`${label}`}</Label>
              {!!helperText && helperText}
              <StyledTextField
                {...field}
                size="small"
                type={type}
                margin="dense"
                error={!!errors[name]}
                placeholder={placeholder}
                helperText={getControlledErrorText(name, errors)}
                InputProps={{ endAdornment: isPasswordField && renderShowPasswordIcon() }}
                onSelect={(e) => toggleSelected(e)}
                onBlur={(e) => toggleSelected(e)}
              />
            </FormControl>
          )
        }
    />
  );
}

ControlledTextField.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  errors: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  handleShowPasswordOnClick: PropTypes.func,
  toggleSelected: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.node,
};

ControlledTextField.defaultProps = {
  control: {},
  name: '',
  label: '',
  type: 'text',
  errors: {},
  rules: {},
  defaultValue: '',
  handleShowPasswordOnClick: () => {},
  toggleSelected: () => {},
  placeholder: '',
  helperText: '',
};

export default ControlledTextField;
