import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MusicNoteIcon from '@mui/icons-material/MusicNote';
import Divider from '@mui/material/Divider';

import useGAEventTracker from '../../../hooks/useGAEventTracker';
import IconButton from '../../common/IconButton';
import SpotifyAuth from '../../Login/ServiceLogin/SpotifyAuth';
import { verifyUser } from '../../../store/slices/userReducer';
import {
  GA_GUEST_REGISTRATION,
  GA_MANUAL_PREFERENCES_ACTIVITY,
  GA_SPOTIFY_PREFERENCES_ACTIVITY,
  HOME_PATH,
  USER_TYPE_GUEST,
} from '../../common/constants';
import theme from '../../common/theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0;
`;

const SyncText = styled.span`
  font-size: 20px;
  color: ${theme.palette.juxy.secondary};
`;

function UserPreferencesStep({ showAlert, setAlertMessage, setRegistrationStep }) {
  const gaEventTracker = useGAEventTracker(GA_GUEST_REGISTRATION);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleManualPreferencesOnClick = () => {
    setRegistrationStep(2);
    gaEventTracker(GA_MANUAL_PREFERENCES_ACTIVITY);
  };

  const renderAlert = (error) => {
    setAlertMessage(error);
    showAlert(true);
  };

  const handleMessageReceived = (message) => {
    dispatch(verifyUser(!!message.payload?.success));
    if (message.payload?.success === '0') {
      // spotify login/authentication failed.
      renderAlert(message.payload.message);
      return;
    }
    navigate(HOME_PATH);
  };

  return (
    <Container>
      <TextContainer>
        <h2>Now, let us know what you like!</h2>
        <SyncText>The playlist will be influenced by your taste when you sync your music.</SyncText>
      </TextContainer>
      <SpotifyAuth
        label="Continue with Spotify"
        userType={USER_TYPE_GUEST}
        onMessageReceived={handleMessageReceived}
        onClick={() => gaEventTracker(GA_SPOTIFY_PREFERENCES_ACTIVITY)}
      />
      <Divider
        flexItem
        sx={{
          minWidth: 350,
          margin: '10px auto',
          '&::before, &::after': {
            borderColor: 'white',
          },
        }}
      >
        OR
      </Divider>
      <IconButton
        startIcon={<MusicNoteIcon />}
        label="Manual Preferences"
        onClick={handleManualPreferencesOnClick}
      />
    </Container>
  );
}

UserPreferencesStep.propTypes = {
  setRegistrationStep: PropTypes.func,
  showAlert: PropTypes.func,
  setAlertMessage: PropTypes.func,
  formValues: PropTypes.shape({}),
};

UserPreferencesStep.defaultProps = {
  formValues: {},
  setRegistrationStep: () => {},
  showAlert: () => {},
  setAlertMessage: () => {},
};

export default UserPreferencesStep;
