import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';

const StyledDrawer = styled(Drawer)`
  & .MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.2);
  }

  & .MuiDrawer-paper {
    background: #131313;
    height: ${(props) => props.height || 'auto'};
    color: #fff;
  }
`;

function PlaylistOptionDrawer(props) {
  const { open, onClose, children } = props;

  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      SlideProps={{ direction: 'up' }}
      {...props}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{
          display: 'flex',
          alignSelf: 'end',
          margin: '10px 20px 0 0',
          width: '30px',
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      {children}
    </StyledDrawer>
  );
}

PlaylistOptionDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.element.isRequired,
};

PlaylistOptionDrawer.defaultProps = {
  open: () => { },
  onClose: () => { },
};

export default PlaylistOptionDrawer;
