import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Snackbar from '@mui/material/Snackbar';

import { DEFAULT_ERROR_MESSAGE } from '../../constants';

const StyledSnackbar = styled(Snackbar)`
  opacity: 0.95;
`;

export default function MessageSnackbar({
  isDisplayed = false,
  content = DEFAULT_ERROR_MESSAGE,
  onClose = () => {},
  autoHideDuration = 5000,
}) {
  return (
    <StyledSnackbar
      open={isDisplayed}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      message={content}
    />
  );
}

MessageSnackbar.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
};
