/* eslint react/no-unescaped-entities: "off" */
import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ControlledPasswordField from '../../common/ControlledInputs/ControlledPasswordField';
import Loader from '../../common/Loader';
import Alert from '../../common/Alert';
import theme from '../../common/theme';
import { GUEST_LOGIN_PATH, NAME, SMALL_SCREEN_SIZE } from '../../common/constants';
import { validateResetToken, updateUserPassword } from '../../../services/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${SMALL_SCREEN_SIZE}) {
    max-width: 70%;
    margin: 0 auto;
  }
`;

const HeaderWithBackButton = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  font-size: 40px;
  padding-top: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

const Title = styled.h1`
  margin: 0px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin: 20px;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

const Text = styled.p`
  margin: 0;
`;

const backIconSx = {
  color: 'white',
  fontSize: '32px',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '32px',
  },
};

const actionButtonSx = {
  backgroundImage: theme.palette.juxy.gradient,
  borderRadius: '50px',
  height: '50px',
  minWidth: '300px',
  color: '#FFFFFF',
  margin: '30px 0',
};

function ResetPasswordForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [isResetSuccessfully, setIsResetSuccessfully] = useState(false);
  const { control, handleSubmit, formState: { errors }, watch } = useForm();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleOnClickBack = () => { navigate(GUEST_LOGIN_PATH); };
  const comparePasswords = () => watch('password') === watch('password-confirm');
  const onSubmit = (data) => {
    updateUserPassword(token, data.password)
      .then(({ result, msg }) => {
        if (result === 0) {
          setErrorMessage(msg);
        }
        setIsResetSuccessfully(!!result);
      });
  };

  useEffect(() => {
    const validateToken = async () => {
      const { result, message } = await validateResetToken(token);
      if (result === 0) {
        // TODO: if invalid reset token then redirect to not found page, otherwise show error message
        setErrorMessage(message);
      }
      setIsLoading(false);
    };

    validateToken();
  }, [token]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <HeaderWithBackButton>
        { !isResetSuccessfully && (
          <IconButton sx={backIconSx} onClick={handleOnClickBack}>
            <ArrowBackIcon />
          </IconButton>
        )}
        <Title>Reset Password</Title>
      </HeaderWithBackButton>
      { isResetSuccessfully ? (
        <>
          <TextContainer style={{ padding: '40px 0', textAlign: 'center' }}>
            <Text>Password Reset Successfully</Text>
          </TextContainer>
          <Button
            variant="contained"
            sx={actionButtonSx}
            onClick={() => navigate(GUEST_LOGIN_PATH)}
          >
            Continue to Login
          </Button>
        </>
      ) : (
        <>
          <TextContainer>
            <Text>{`Enter new password for your ${NAME} account`}</Text>
          </TextContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ControlledPasswordField
              name="password"
              label="Password"
              rules={{ required: true, minLength: 6 }}
              errors={errors}
              control={control}
            />
            <ControlledPasswordField
              name="password-confirm"
              label="Re-Enter Password"
              rules={{ required: true, validate: comparePasswords }}
              errors={errors}
              control={control}
            />
            {
              errorMessage ? (
                <Alert isDisplayed={!!errorMessage} message={errorMessage} />
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  sx={actionButtonSx}
                >
                  Continue
                </Button>
              )
            }
          </Form>
        </>
      )}
    </Container>
  );
}

export default ResetPasswordForm;
