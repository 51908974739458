// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/assets/background.jpeg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/assets/background-mobile.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  color: white;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
}

@media (max-width: 640px) {
  body {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  }
}


/* Disable errors overlay, temp solution */
iframe
{
    display: none;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;EACzD,sBAAsB;EACtB,4BAA4B;EAC5B,YAAY;EACZ,YAAY;EACZ,kCAAkC;EAClC,eAAe;AACjB;;AAEA;EACE;IACE,yDAA+D;EACjE;AACF;;;AAGA,0CAA0C;AAC1C;;IAEI,aAAa;AACjB","sourcesContent":["body {\n  background-image: url('../public/assets/background.jpeg');\n  background-size: cover;\n  background-attachment: fixed;\n  height: 100%;\n  color: white;\n  font-family: 'DM Sans', sans-serif;\n  font-size: 16px;\n}\n\n@media (max-width: 640px) {\n  body {\n    background-image: url('../public/assets/background-mobile.jpg');\n  }\n}\n\n\n/* Disable errors overlay, temp solution */\niframe\n{\n    display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
