import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  getNotificationTitle,
  getNotificationMessage,
  getNotificationSeverity,
  isNotificationDisplayed,
  clearNotification,
} from '../../../store/slices/notificationReducer';

import MessageSnackbar from './MessageSnackbar';
import NotificationDialog from './NotificationDialog';

import { NOTIFICATION_SEVERITY_ERROR } from '../constants';

export default function Notification({ children = [] }) {
  const isDisplayed = useSelector(isNotificationDisplayed);
  const severity = useSelector(getNotificationSeverity);
  const title = useSelector(getNotificationTitle);
  const message = useSelector(getNotificationMessage);

  const dispatch = useDispatch();

  if (severity === NOTIFICATION_SEVERITY_ERROR || children.length) {
    return (
      <NotificationDialog
        title={title}
        isDisplayed={isDisplayed}
        onClose={() => dispatch(clearNotification())}
      >
        {(children.length && children) || message}
      </NotificationDialog>
    );
  }

  return (
    <MessageSnackbar
      isDisplayed={isDisplayed}
      content={message}
      onClose={() => dispatch(clearNotification())}
    />
  );
}

Notification.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
};
