import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const style = {
  maxWidth: '400px',
  padding: '16px',
  color: 'white',
  backgroundColor: '#131313',
};

const Title = styled(DialogTitle)`
  display: flex;
  flex-direction: column;
  padding: 8px;
  align-items: center;
  gap: 8px;
`;

export default function NotificationDialog({
  isDisplayed = false,
  onClose = () => {},
  children = [],
  title = '',
}) {
  return (
    <Dialog
      onClose={onClose}
      open={isDisplayed}
      PaperProps={{ style }}
      TransitionComponent={Transition}
    >
      <DialogActions sx={{ padding: 'unset' }}>
        <IconButton
          aria-label="close"
          size="small"
          onClick={onClose}
          sx={{ padding: 'none', color: '#AFAFAF' }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogActions>
      <Title>
        <WarningAmberIcon color="error" sx={{ fontSize: '60px' }} />
        {title}
      </Title>
      <DialogContent
        sx={{textAlign: `${typeof(children) === 'string' ? 'center' : 'unset'}`}}
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

NotificationDialog.propTypes = {
  isDisplayed: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string,
  ]),
  title: PropTypes.string,
};
