import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

import {
  SMALL_SCREEN_SIZE,
  GA_HOST_LOADED_PLAYLIST_ACTIVITY,
  GA_HOST_PLAYLIST_CUSTOMIZATION,
} from '../../../common/constants';
import theme from '../../../common/theme';
import SongDetails from '../../Playlist/SongDetails';
import IconButton from '../../../common/IconButton';
import { getVenueSavedPlaylists, loadPlaylist } from '../../../../services/api';
import useGAEventTracker from '../../../../hooks/useGAEventTracker';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  margin: 44px 0 0 44px;
  padding: 0;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    padding: 8px;
    margin: 0;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1f1d29;
  border-radius: 10px;
`;

const PlaylistsContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;
  padding: 8px;
  margin-bottom: 16px;
  scrollbar-color: #c7c7cc transparent;
`;

const Header = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin: 0;
  padding: 8px;
`;

const StyledSearchBar = styled(TextField)({
  padding: '10px',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '100px',
    fontSize: '16px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '8px 16px',
  },
});

const StyledCard = styled(Card)({
  '&.MuiCard-root': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: 'white',
    borderRadius: '0px',
    background: 'none',
    border: `1px solid ${theme.palette.juxy.secondary}`,
    borderWidth: '0 0 1px 0',
    padding: '8px 10px',
    gap: '16px',
    boxShadow: 'none',
  },
});

const LoadButtonStyle = {
  textTransform: 'none',
  color: 'white',
  backgroundColor: '#1F1D29',
  fontSize: '16px',
  border: '1px solid white',
  borderRadius: '50px',
  minHeight: '40px',
  margin: '10px',
  width: '90%',
  maxWidth: '360px',
  flexDirection: 'row-reverse',
  alignSelf: 'center',
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'white',
    color: theme.palette.juxy.main,
  },
  '.MuiButton-startIcon': {
    margin: '0 3px 0 0',
  },
  [`@media (max-width: ${SMALL_SCREEN_SIZE})`]: {
    alignSelf: 'end',
    maxWidth: '160px',
  },
};

function PlaylistCustomization() {
  const [playlistURL, setPlaylistURL] = useState('');
  const [playlists, setPlaylists] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPlaylist, setSelectedPlaylist] = useState('');
  // disables load button if both loading options are selected or if none are selected (negated XOR)
  const isLoadButtonDisabled = !(
    (playlistURL && !selectedPlaylist) ||
    (!playlistURL && selectedPlaylist)
  );

  const gaEventTracker = useGAEventTracker(GA_HOST_PLAYLIST_CUSTOMIZATION);

  useEffect(() => {
    const getVenuePlaylists = async () => {
      const receivedPlaylists = await getVenueSavedPlaylists();
      setPlaylists(
        receivedPlaylists?.map((playlist) => ({
          id: playlist?.playlistId,
          name: playlist?.name,
          imageURL: playlist?.imageURL,
          author: playlist?.author,
        }))
      );
    };

    getVenuePlaylists();
  }, []);

  const handleSearchOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleURLOnChange = (event) => {
    setPlaylistURL(event.target.value);
  };

  const handleLoadPlaylistSelected = async () => {
    const url = playlistURL || `https://open.spotify.com/playlist/${selectedPlaylist}`;
    try {
      const playlist = await loadPlaylist(url);
      if (playlistURL) {
        // add to venue's playlists if added from URL
        setPlaylists((currentPlaylists) => [
          ...currentPlaylists,
          {
            id: playlist?.playlistId,
            name: playlist?.name,
            imageURL: playlist?.imageURL,
            author: playlist?.author,
          },
        ]);
      }

      gaEventTracker(GA_HOST_LOADED_PLAYLIST_ACTIVITY);

      // reset state
      setPlaylistURL('');
      setSelectedPlaylist('');
      setSearchTerm('');
    } catch (error) {
      // TODO: display error message
      console.error(error);
    }
  };

  const renderSavedPlaylists = () =>
    playlists
      ?.filter((playlist) =>
        searchTerm === '' || playlist.name.toLowerCase().includes(searchTerm.toLowerCase())
          ? playlist
          : null
      )
      .map((playlist) => (
        <StyledCard
          key={playlist.id}
          onClick={() => setSelectedPlaylist((prev) => (prev === playlist.id ? '' : playlist.id))}
        >
          <SongDetails
            songName={playlist.name}
            artist={playlist.author}
            image={playlist.imageURL}
            style={{
              background: selectedPlaylist === playlist.id ? '#FFFFFF' : 'none',
              color: selectedPlaylist === playlist.id ? '#000000' : '#FFFFFF',
              width: '100%',
              borderRadius: '10px',
              padding: '8px',
            }}
          />
        </StyledCard>
      ));

  return (
    <Container>
      Switch to
      <OptionsContainer>
        <StyledSearchBar
          size="large"
          placeholder="Search playlist"
          value={searchTerm}
          onChange={handleSearchOnChange}
          InputProps={{ endAdornment: <SearchIcon /> }}
          style={{
            maxWidth: '300px',
          }}
        />
        <Header>Choose Playlist</Header>
        <PlaylistsContainer>{renderSavedPlaylists()}</PlaylistsContainer>
        <Header style={{ textAlign: 'center' }}>Load Playlist From URL</Header>
        <StyledSearchBar
          size="large"
          placeholder="Insert playlist URL"
          value={playlistURL}
          onChange={handleURLOnChange}
          InputProps={{ endAdornment: <SearchIcon /> }}
        />
        <IconButton
          label="Load playlist"
          startIcon={<ArrowDownward />}
          sx={LoadButtonStyle}
          onClick={handleLoadPlaylistSelected}
          disabled={isLoadButtonDisabled}
        />
      </OptionsContainer>
    </Container>
  );
}

export default PlaylistCustomization;
