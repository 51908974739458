import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Rating from '@mui/material/Rating';
import Button from '@mui/material/Button';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';

import PlaylistOptionDrawer from '../PlaylistOptionDrawer';
import useGAEventTracker from '../../../../../hooks/useGAEventTracker';
import { GA_GUEST_PLAYLIST, GA_PLAYLIST_RATED_ACTIVITY } from '../../../../common/constants';
import theme from '../../../../common/theme';
import usePrevious from '../../../../../hooks/usePrevious';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 8px;
`;

const RatingButton = styled(Button)({
  color: 'white',
  textDecoration: 'underline',
  textTransform: 'none',
  fontSize: '18px',
  fontWeight: 'normal',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'unset',
  },
});

const SubmitButtonStyle = {
  backgroundImage: theme.palette.juxy.gradient,
  borderRadius: '60px',
  border: '1px solid #666',
  height: '62px',
  width: '140px',
  alignSelf: 'center',
  color: '#FFFFFF',
  fontWeight: '200',
  fontSize: '24px',
  textTransform: 'none',
  '&:hover': {
    color: theme.palette.juxy.secondary,
  }
};

function PlaylistRating({ name }) {
  const [openRatePlaylist, setOpenRatePlaylist] = useState(false);
  const [playlistRating, setPlaylistRating] = useState(-1);

  const hasRated = useRef(false); // TODO: set from backend
  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  const handleRatingSubmission = () => {
    // TODO: hook playlist rating
    gaEventTracker(GA_PLAYLIST_RATED_ACTIVITY);
    hasRated.current = true;
    setOpenRatePlaylist(false);
  };

  return (
    <>
      <RatingButton variant="text" size="large" onClick={() => setOpenRatePlaylist(true)}>
        Rate the Playlist
      </RatingButton>
      <PlaylistOptionDrawer
        open={openRatePlaylist}
        onClose={() => setOpenRatePlaylist(false)}
      >
        <Container>
          <h1>Rate the Playlist</h1>
          <span style={{ color: theme.palette.juxy.secondary }}>
            Tell us what you think about the music
          </span>
          <Rating
            name={name}
            value={playlistRating}
            precision={0.5}
            emptyIcon={<StarBorderOutlinedIcon fontSize="inherit" style={{ color: 'white' }} />}
            onChange={(event, rating) => { setPlaylistRating(rating); }}
            sx={{ fontSize: '42px', margin: '18px 0', alignSelf: 'center' }}
          />
          <Button
            onClick={handleRatingSubmission}
            sx={SubmitButtonStyle}
            disabled={hasRated.current}
          >
            Submit
          </Button>
        </Container>
      </PlaylistOptionDrawer>
    </>
  )
};

PlaylistRating.propTypes = {
  name: PropTypes.string,
};

export default PlaylistRating;
