import React from 'react';
import Lottie from 'lottie-react';

import loadingAnimation from '../../../images/juxy loader-llLcr.json';

function JuxySpinner({ loop = true, height = '150px' }) {
  return (
    <Lottie animationData={loadingAnimation} loop={loop} style={{ height }} />
  );
}

export default JuxySpinner;
