import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ControlledTextField from '../ControlledTextField';

function ControlledPasswordField(props) {
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPasswordOnClick = () => { setShowPassword((value) => !value); };
  const type = showPassword ? 'text' : 'password';

  return (
    <ControlledTextField
      {...{ ...props, type, showPassword, handleShowPasswordOnClick }}
    />
  );
}

ControlledPasswordField.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.string,
  errors: PropTypes.shape({}),
  rules: PropTypes.shape({}),
  defaultValue: PropTypes.string,
  handleShowPasswordOnClick: PropTypes.func,
  toggleSelected: PropTypes.func,
  placeholder: PropTypes.string,
  helperText: PropTypes.node,
};

ControlledPasswordField.defaultProps = {
  control: {},
  name: 'password',
  label: 'Password',
  errors: {},
  rules: {},
  defaultValue: '',
  toggleSelected: () => {},
  placeholder: '',
  helperText: '',
};

export default ControlledPasswordField;
