import React from 'react';

import BlockIcon from '@mui/icons-material/Block';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScheduleIcon from '@mui/icons-material/Schedule';

import {
  HOST_PATH,
  CUSTOMIZE_PLAYLIST,
  HOST_STATISTICS,
  BLACKLIST,
  DEFAULT_PLAYLIST,
  SCHEDULE,
  USER_TIME,
} from '../common/constants';

export const options = [
  {
    header: 'Overview',
    path: `/${HOST_PATH}`,
  },
  {
    header: 'Customize playlist',
    path: CUSTOMIZE_PLAYLIST,
  },
  {
    header: 'Stats and Data',
    path: HOST_STATISTICS,
  },
];

export const settings = [
  {
    header: 'Blacklist',
    path: BLACKLIST,
    icon: <BlockIcon />,
  },
  {
    header: 'Default playlist',
    path: DEFAULT_PLAYLIST,
    icon: <FormatListBulletedIcon />,
  },
  {
    header: 'Schedule',
    path: SCHEDULE,
    icon: <CalendarMonthIcon />,
  },
  {
    header: 'User time',
    path: USER_TIME,
    icon: <ScheduleIcon />,
  },
];
