import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../../../common/Dialog';

function PlaylistOptionCard(props) {
  const { open, onClose, children, fullScreen } = props;

  return (
    <Dialog open={open} onClose={onClose} fullScreen={fullScreen} {...props}>
      <IconButton
        edge="end"
        color="inherit"
        onClick={onClose}
        sx={{
          display: 'flex',
          alignSelf: 'end',
          margin: '10px 16px 0 0',
          width: '30px',
        }}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
      {children}
    </Dialog>
  );
}

PlaylistOptionCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fullScreen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

PlaylistOptionCard.defaultProps = {
  open: PropTypes.bool,
  onClose: () => { },
};

export default PlaylistOptionCard;
