import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';

import RequestSong from './RequestSong';
import VolumeComplaintPoll from './VolumeComplaint';
import NextSongPoll from './SongsPoll';

import {
  canRequestSong,
  getIsRequestsEnabled,
  getIsVolumeComplaintEnabled,
  getIsVotingEnabled,
} from '../../../../services/api';
import { notifyError } from '../../../../store/slices/notificationReducer';

function PlaylistOptions() {
  const [displayRequestSong, setDisplayRequestSong] = useState(true);
  const [displayVoteSong, setDisplayVoteSong] = useState(true);
  const [displayVolumeComplaint, setDisplayVolumeComplaint] = useState(false);
  const [showRequestSuccessfulMessage, setShowRequestSuccessfulMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    const getAvailableOptions = async () => {
      const [isVolumeComplaintEnabled, isRequestsEnabled, isCanRequest, isVotingEnabled] =
        await Promise.all([
          getIsVolumeComplaintEnabled(),
          getIsRequestsEnabled(),
          canRequestSong(),
          getIsVotingEnabled(),
        ]);

      setDisplayVolumeComplaint(isVolumeComplaintEnabled);
      setDisplayRequestSong(isRequestsEnabled && isCanRequest);
      setDisplayVoteSong(isVotingEnabled);
    };

    getAvailableOptions();
  }, []);

  return (
    <>
      {displayVoteSong && (
        <NextSongPoll
          onSuccess={() => setShowRequestSuccessfulMessage(true)}
          onFailure={(message = '') => dispatch(notifyError({ message }))}
        />
      )}
      {displayRequestSong && (
        <RequestSong
          onSuccess={() => setShowRequestSuccessfulMessage(true)}
          onFailure={(message = '') => dispatch(notifyError({ message }))}
        />
      )}
      {displayVolumeComplaint && (
        <VolumeComplaintPoll
          onSuccess={() => setShowRequestSuccessfulMessage(true)}
          onFailure={(message = '') => dispatch(notifyError({ message }))}
        />
      )}
      <Snackbar
        open={showRequestSuccessfulMessage}
        autoHideDuration={5000}
        onClose={() => setShowRequestSuccessfulMessage(false)}
        ContentProps={{ sx: { fontSize: 20 } }}
        message="Your request have been submitted!"
      />
    </>
  );
};

export default PlaylistOptions;
