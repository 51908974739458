/* eslint-disable quote-props */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Typography } from '@mui/material';
import Alert from '../common/Alert';

import { isUserLoggedIn, isUserVerified, getUserType } from '../../store/slices/userReducer';
import { clearNotification, notifyError } from '../../store/slices/notificationReducer';
import { SMALL_SCREEN_SIZE, NAME, USER_TYPE_GUEST, DEFAULT_ERROR_MESSAGE } from '../common/constants';
import UserTypeButtons from '../common/UserTypeButtons';
import AlreadyHaveAccount from './AlreadyHaveAccount';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 16px auto;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
  }
`;

const LogoWithBackButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
`;

const backIconSx = {
  color: 'white',
  alignSelf: 'start',
  marginRight: '58px',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
};

const errorTranslator = {
  'spotify_add_remove.py failed for unknown reason': 'Something not right, Please try again later',
  'Email is not registered': 'Please provide your spotify email address',
  'Request failed with status code 401':
    'Cannot register through spotify. Please try again later or register select preferences manually',
  'user already exist': 'User already exists',
  host: `${NAME} is unavailable. But we'll be back soon!`,
};

function RegistrationForm() {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const verified = useSelector(isUserVerified);
  const userType = useSelector(getUserType);
  const [registrationStep, setRegistrationStep] = useState(0);
  const [stepTitle, setStepTitle] = useState('Register');
  const [displayButtons, setDisplayButtons] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoggedIn && !verified) {
      setRegistrationStep(userType === USER_TYPE_GUEST ? 1 : 2);
    }
  }, []);

  const handleOnClickBack = () => {
    setRegistrationStep(registrationStep - 1);
    dispatch(clearNotification());
  };

  const handleError = (message) => {
    dispatch(notifyError({ message: errorTranslator[message] || DEFAULT_ERROR_MESSAGE }));
  };

  return (
    <Container>
      {registrationStep > 1 ? (
        <LogoWithBackButton>
          <Button
            variant="text"
            startIcon={<ArrowBackIosNewIcon />}
            sx={backIconSx}
            onClick={handleOnClickBack}
          />
          <Typography variant="h5">{stepTitle}</Typography>
        </LogoWithBackButton>
      ) : (
        <Typography variant="h5">{stepTitle}</Typography>
      )}
      {displayButtons && <UserTypeButtons />}
      <Outlet
        context={{
          registrationStep,
          setRegistrationStep,
          setStepTitle,
          setDisplayButtons,
          handleError,
        }}
      />
      {registrationStep === 0 && <AlreadyHaveAccount />}
    </Container>
  );
}

export default RegistrationForm;
