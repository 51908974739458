import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fetchProfile } from '../store/thunks/userThunks';
import { logout as storeLogout } from '../store/slices/userReducer';
import {
  guestLogin as requestGuestLogin,
  hostLogin as requestHostLogin,
  isHostConnected,
} from '../services/api';
import {
  NAME,
  HOME_PATH,
  REGISTER_GUEST_PATH,
  REGISTER_HOST_PATH,
  GUEST_DASHBOARD_PATH,
  HOST_DASHBOARD_PATH,
  USER_TYPE_GUEST,
} from '../components/common/constants';

const useAuthFunctions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (credentials = {}, loginFunction = async () => {}) => {
    const { result, verified, msg } = await loginFunction(credentials);
    if (!!result === false) {
      throw Error(msg);
    }

    const { role } = await dispatch(fetchProfile()).unwrap();
    let path;
    if (verified) {
      path = role === USER_TYPE_GUEST ? GUEST_DASHBOARD_PATH : HOST_DASHBOARD_PATH;
    } else {
      path = role === USER_TYPE_GUEST ? REGISTER_GUEST_PATH : REGISTER_HOST_PATH;
    }
    navigate(path);
  };

  const guestLogin = async (credentials = {}) => {
    await login(credentials, requestGuestLogin);
    navigate(GUEST_DASHBOARD_PATH);
  };

  const logout = async () => {
    dispatch(storeLogout());
    navigate(HOME_PATH);
  };

  const hostLogin = async (credentials = {}) => {
    await login(credentials, requestHostLogin);
    navigate(HOST_DASHBOARD_PATH);
  };

  return { guestLogin, hostLogin, logout };
};

export default useAuthFunctions;
