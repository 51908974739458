/* eslint no-unused-vars: "off" */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { notifyError } from '../../../store/slices/notificationReducer';

import PlaylistSongs from './PlaylistSongs';
import LeavePlaylist from './PlaylistOptions/LeavePlaylist';
import PlaylistOptions from './PlaylistOptions';
import PlaylistRating from './PlaylistOptions/PlaylistRating';
import JuxySpinner from '../../common/JuxySpinner';

import { getCurrentSong, getPlaylistSongs } from '../../../services/api';
import { NAME } from '../../common/constants';
import usePrevious from '../../../hooks/usePrevious';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 20px 0;
  gap: 20px;
  padding-top: 8px;
`;

function Playlist() {
  const [playlistName, setPlaylistName] = useState(`${NAME}'s Playlist`);
  const [prevSong, setPrevSong] = useState(undefined);
  const [currentSong, setCurrentSong] = useState(undefined);

  const dispatch = useDispatch();
  const prevCurrentSongValue = usePrevious(currentSong);

  const fetchPlaylistSongs = async () => {
    try {
      const [prev, curr] = await getPlaylistSongs();
      if (curr) {
        setCurrentSong({ isLiked: curr.isLiked, song: curr.song });
      }

      if (prev) {
        setPrevSong({ isLiked: prev.isLiked, song: prev.song });
      }
    } catch (error) {
      handleFailure('Failed fetching songs list. Please try again later.');
    }
  };

  const pollCurrentSong = async () => {
    try {
      const { song, isLiked } = await getCurrentSong();
      if (!song) {
        return false;
      }

      handleIsNewSong({ song, isLiked });
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    fetchPlaylistSongs();

    let retries = 0;
    const timer = setInterval(async () => {
      if (retries < 3) {
        retries = (await pollCurrentSong()) ? 0 : retries + 1;
      } else {
        setCurrentSong(undefined);
      }
    }, 10000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (
      !!prevCurrentSongValue &&
      prevCurrentSongValue?.song?.id !== currentSong?.song?.id &&
      prevSong?.song?.id !== prevCurrentSongValue?.song?.id
    ) {
      setPrevSong(prevCurrentSongValue);
    }
  }, [currentSong, prevCurrentSongValue]);

  const handleIsNewSong = (nextSongMeta) => {
    if (!currentSong || currentSong?.id !== nextSongMeta.song.id) {
      setCurrentSong(nextSongMeta);
    }
  };

  const handleFailure = (message) => {
    dispatch(notifyError({ message }));
  };

  if (!currentSong) {
    return (
      <Container>
        <JuxySpinner />
      </Container>
    );
  }

  return (
    <Container>
      <h2>{playlistName}</h2>
      <PlaylistSongs
        currentSong={currentSong}
        prevSong={prevSong}
        onSuccess={fetchPlaylistSongs}
        onFailure={handleFailure}
      />
      <PlaylistOptions />
      <PlaylistRating name={`${playlistName} rating`} />
      <LeavePlaylist onFailure={handleFailure} />
    </Container>
  );
}

export default Playlist;
