/* eslint-disable quote-props */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Outlet } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Alert from '../common/Alert';

import { isUserLoggedIn, isUserVerified, getUserType } from '../../store/slices/userReducer';
import { SMALL_SCREEN_SIZE, NAME, USER_TYPE_GUEST } from '../common/constants';
import UserTypeButtons from '../common/UserTypeButtons';
import AlreadyHaveAccount from './AlreadyHaveAccount';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 10px auto;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
  }
`;

const StepTitle = styled.span`
  font-size: 30px;
  margin: 25px 0;
`;

const LogoWithBackButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
`;

const backIconSx = {
  color: 'white',
  alignSelf: 'start',
  marginRight: '58px',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
};

const errorTranslator = {
  'spotify_add_remove.py failed for unknown reason': 'Something not right, Please try again later',
  'Email is not registered': 'Please provide your spotify email address',
  'Request failed with status code 401':
    'Cannot register through spotify. Please try again later or register select preferences manually',
  'user already exist': 'User already exists',
  host: `${NAME} is unavailable. But we'll be back soon!`,
};

function RegistrationForm() {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const verified = useSelector(isUserVerified);
  const userType = useSelector(getUserType);
  const [registrationStep, setRegistrationStep] = useState(0);
  const [stepTitle, setStepTitle] = useState('Register');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [displayButtons, setDisplayButtons] = useState(true);

  const setAlert = (message) => setAlertMessage(errorTranslator[message]);

  useEffect(() => {
    if (isLoggedIn && !verified) {
      setRegistrationStep(userType === USER_TYPE_GUEST ? 1 : 2);
    }
  }, []);

  const handleOnClickBack = () => {
    setRegistrationStep(registrationStep - 1);
    setIsShowAlert(false);
  };

  return (
    <Container>
      {registrationStep > 1 ? (
        <LogoWithBackButton>
          <Button
            variant="text"
            startIcon={<ArrowBackIosNewIcon />}
            sx={backIconSx}
            onClick={handleOnClickBack}
          />
          <StepTitle>{stepTitle}</StepTitle>
        </LogoWithBackButton>
      ) : (
        <StepTitle>{stepTitle}</StepTitle>
      )}
      {displayButtons && <UserTypeButtons />}
      <Outlet
        context={{
          registrationStep,
          setRegistrationStep,
          setStepTitle,
          setAlert,
          setIsShowAlert,
          setDisplayButtons,
        }}
      />
      <Alert
        isDisplayed={registrationStep < 0 || isShowAlert}
        variant="filled"
        onClick={() => setIsShowAlert(false)}
        message={alertMessage}
      />
      {registrationStep === 0 && <AlreadyHaveAccount />}
    </Container>
  );
}

export default RegistrationForm;
