import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, useMediaQuery } from '@mui/material';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';

// import useLocation from '../../../hooks/useLocation';
import { getUserName } from '../../../store/slices/userReducer';

import Venues from '../../Venues/Venues';
import VenuesMap from '../../Venues/VenuesMap';
import VenueDataCard from './VenueDataCard';
import PlaylistOptionDrawer from '../PlaylistOptionDrawer';
import { addGuestToVenue, getActiveVenues } from '../../../services/api';

import { GUEST_DASHBOARD_PATH, MEDIUM_SCREEN_SIZE, SMALL_SCREEN_SIZE } from '../../common/constants';

const Container = styled.div`
  display: flex;
  width: 100%;
  font-size: 20px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    flex-direction: column;
    align-items: center;
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  gap: 8px;
  padding: 8px;
  width: inherit;
`;

const VenuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
`;

const CTAButtonStyle = {
  backgroundImage: 'linear-gradient(143.44deg, #9245FF 15.24%, #44A9DB 91.4%)',
  borderRadius: '50px',
  color: '#FFFFFF',
  fontSize: '16px',
  width: '70%',
  maxWidth: '300px',
  alignSelf: 'center',
  margin: '10px',
};

const WelcomeContainer = styled(VenuesContainer)``;

function GuestDashboard() {
  // const userLocation = useLocation();
  const displayName = useSelector(getUserName);
  const navigate = useNavigate();

  const [venuesList, setVenuesList] = useState([]);
  const [errorMessage, setErrorMessage] = useState({ code: 0, message: 'No venues nearby' });
  const [toggleLoader, setToggleLoader] = useState(true);
  const [changeMapView, setChangeMapView] = useState(undefined); // switches map view between desktop and mobile versions
  const [toggleMapView, setToggleMapView] = useState(false); // toggles map view display on mobile
  const [selectedVenue, setSelectedVenue] = useState(null);

  const isMobile = useMediaQuery(`(min-width: ${MEDIUM_SCREEN_SIZE})`);

  useEffect(() => {
    setChangeMapView(isMobile);
  }, [isMobile]);

  // useEffect(() => {
  //   if (!userLocation?.coords) {
  //     // TODO: change according to location unavailable use case.
  //     setVenuesList([]);
  //     setErrorMessage({ ...errorMessage, message: 'Location services required' });
  //     setToggleLoader(false);
  //   } else {
  //     // TODO: get venues around user and set venueList accordingly
  //     setVenuesList([]);
  //     setToggleLoader(false);
  //   }
  // }, [userLocation]);

  useEffect(() => {
    // TODO: store active venues list in store
    const queryActiveVenues = async () => {
      const venues = await getActiveVenues();
      setVenuesList(venues);
    };

    queryActiveVenues();
    setToggleLoader(false);

    const interval = setInterval(queryActiveVenues, 10000);
    return () => clearInterval(interval);
  }, []);

  const renderVenuesMap = () => <VenuesMap venueList={venuesList} /*userLocation={userLocation}*/ />;

  const renderListMapViewButton = () =>
    toggleMapView && isMobile ? <ListIcon fontSize="inherit" /> : <MapIcon fontSize="inherit" />;

  const renderVenuesList = () => {
    if (toggleLoader) {
      return <span style={{ alignSelf: 'center' }}>Waiting for your location...</span>;
    }

    return (
      <Venues
        venueList={venuesList}
        errorMessage={errorMessage}
        onSelect={(venue) => {
          setSelectedVenue(venue);
        }}
      />
    );
  };

  const handleJoinSelectedVenue = async () => {
    if (!selectedVenue) {
      return;
    }
    
    try {
      const { result, msg }= await addGuestToVenue(selectedVenue.id);
      if (!!result == false) {
        setErrorMessage({ code: 1, message: msg });
        setSelectedVenue(null);
        return;
      }
      navigate(`${GUEST_DASHBOARD_PATH}/${selectedVenue.id}`);
    } catch (error) {
      setErrorMessage({ code: 1, message: error.message });
      setSelectedVenue(null);
    }
  };

  return (
    <Container>
      <MainContainer>
        <WelcomeContainer>
          <h3 style={{ fontSize: '24px', margin: 0 }}>{`Hello ${displayName},`}</h3>
          <IconButton size="large" aria-label="scanQR" sx={{ color: 'white' }}>
            <QrCodeScannerIcon fontSize="inherit" />
          </IconButton>
        </WelcomeContainer>
        {/* TODO: add searchbar when feature is ready */}
        <VenuesContainer>
          <span style={{ fontSize: 'inherit' }}>Playlists around you</span>
          {!changeMapView && (
            <IconButton
              size="large"
              aria-label="toggleListMapView"
              sx={{ color: 'white' }}
              onClick={() => setToggleMapView(!toggleMapView)}
            >
              {renderListMapViewButton()}
            </IconButton>
          )}
        </VenuesContainer>
        {toggleMapView && !isMobile ? renderVenuesMap() : renderVenuesList()}
      </MainContainer>
      {changeMapView && renderVenuesMap()}
      <PlaylistOptionDrawer open={selectedVenue !== null} onClose={() => setSelectedVenue(null)}>
        <MainContainer>
          {selectedVenue && <VenueDataCard image={selectedVenue.image} title={selectedVenue.title} name={selectedVenue.name} />}
          <Button sx={CTAButtonStyle} onClick={handleJoinSelectedVenue}>
            Join The Party!
          </Button>
        </MainContainer>
      </PlaylistOptionDrawer>
    </Container>
  );
}

GuestDashboard.propTypes = {
  displayName: PropTypes.string,
};

GuestDashboard.defaultProps = {
  displayName: 'User',
};

export default GuestDashboard;
