import React from 'react';
import PropTypes from 'prop-types';

import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import theme from './theme';

function ToggleButtonGroup({ values, selctedValues, onChange, ariaLabel, exclusive, ...props }) {
  return (
    <MuiToggleButtonGroup
      color="secondary"
      value={selctedValues}
      exclusive={exclusive}
      onChange={onChange}
      aria-label={ariaLabel}
      {...props}
    >
      {values.map((value) => (
        <ToggleButton
          key={value?.value || value}
          value={value?.value || value}
          sx={{
            color: '#FFFFFF',
            backgroundColor: '#131313 !important',
            margin: '5px 10px 5px 0 !important',
            maxHeight: '80px',
            fontSize: '16px',
            padding: '0 10px',
            border: '1px solid #FFFFFF !important',
            borderRadius: '15px !important',
            '@media (hover: hover)': {
              '&:hover': {
                backgroundColor: '#8c8c8c !important',
              },
            },
            '&.Mui-selected': {
              color: '#FFFFFF',
              backgroundImage: `${theme.palette.juxy.gradient} !important`,
            },
          }}
        >
          {value?.label || value}
        </ToggleButton>
      ))}
    </MuiToggleButtonGroup>
  );
}

ToggleButtonGroup.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape({})),
  selctedValues: PropTypes.string,
  onChange: PropTypes.func,
  ariaLabel: PropTypes.string,
  exclusive: PropTypes.bool, // with exclusive selection, selecting one option deselects any other.
};

ToggleButtonGroup.defaultProps = {
  values: [],
  selctedValues: '',
  onChange: () => {},
  ariaLabel: 'select-button',
  exclusive: false,
};

export default ToggleButtonGroup;
