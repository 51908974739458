import React from 'react';
import Statistics from '../Statistics';

function Overview() {
  return (
    <></>
    // <Statistics />
  );
}

export default Overview;
