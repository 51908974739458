import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import theme from '../common/theme';
import { GUEST_LOGIN_PATH } from '../common/constants';

const Container = styled.div`
  margin: ${(props) => props.margin};
`;

const Text = styled.span`
  color: ${theme.palette.juxy.secondary};
  font-size: 16px;
`;

const LoginLink = styled(Link)`
  color: ${theme.palette.juxy.secondary};
  padding: '0 7px';
  margin-left: 5px;
  text-decoration: underline;
`;

function AlreadyHaveAccount({ margin }) {
  return (
    <Container margin={margin}>
      <Text>Already have an account?</Text>
      <LoginLink to={GUEST_LOGIN_PATH}>Login</LoginLink>
    </Container>
  );
}

AlreadyHaveAccount.propTypes = {
  margin: PropTypes.string,
};

AlreadyHaveAccount.defaultProps = {
  margin: '15px 0',
};

export default AlreadyHaveAccount;
