import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';

import { GENRES_LIST, HOME_PATH, SMALL_SCREEN_SIZE } from '../../common/constants';
import { setManualPreferences } from '../../../services/api';
import { verifyUser } from '../../../store/slices/userReducer';
import theme from '../../common/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const NumberOfGenresError = styled.span`
    color: ${(props) => (props.asError ? '#d32f2f' : theme.palette.juxy.secondary)};
    margin-bottom: 10px;
    font-weight: ${(props) => (props.asError ? 'bold' : 200)};
`;

function ManualPreferencesStep({ setAlertMessage, showAlert }) {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [invalidNumberOfGenres, setInvalidNumberOfGenres] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${SMALL_SCREEN_SIZE})`);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnChange = (event, newSelectedGenres) => {
    setSelectedGenres(newSelectedGenres);
  };

  const handleOnClickSubmit = () => {
    if (selectedGenres.length > 2) {
      setInvalidNumberOfGenres(false);
      setManualPreferences(selectedGenres)
        .then((data) => {
          const { verified, msg } = data;
          if (verified === 0) {
            throw new Error(msg);
          }
          dispatch(verifyUser(!!verified));
          navigate(HOME_PATH);
        })
        .catch((error) => {
          setAlertMessage(error.message);
          showAlert(true);
        });
    } else {
      setInvalidNumberOfGenres(true);
    }
  };

  return (
    <Container>
      <h3>Select Your Top Genres</h3>
      <NumberOfGenresError asError={invalidNumberOfGenres}>Please select at least 3 genres</NumberOfGenresError>
      <ToggleButtonGroup
        color="secondary"
        value={selectedGenres}
        exclusive={false}
        onChange={handleOnChange}
        aria-label="Genres"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          overflowY: 'auto',
          height: '250px',
          width: isMobile ? '300px' : '400px',
        }}
      >
        {Object.values(GENRES_LIST).map((genre) => (
          <ToggleButton
            key={genre.value}
            value={genre.value}
            sx={{
              color: 'white',
              backgroundColor: '#5d585f !important',
              margin: '5px 10px 5px 0 !important',
              fontSize: '16px',
              padding: '0 10px',
              opacity: '75%',
              border: '1px !important',
              borderRadius: '15px !important',
              '&.Mui-selected': {
                backgroundColor: '#f7e6ff !important',
              },
            }}
          >
            {genre.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <Button
        type="submit"
        variant="contained"
        onClick={handleOnClickSubmit}
        sx={{
          margin: '30px 0',
          width: '100%',
          borderRadius: '50px',
          backgroundImage: theme.palette.juxy.gradient,
        }}
      >
        Submit
      </Button>
    </Container>
  );
}

ManualPreferencesStep.propTypes = {
  setAlertMessage: PropTypes.func,
  showAlert: PropTypes.func,
};

ManualPreferencesStep.defaultProps = {
  setAlertMessage: () => {},
  showAlert: () => {},
};

export default ManualPreferencesStep;
