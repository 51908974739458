import React from 'react';
import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

import useGAEventTracker from '../../../../../hooks/useGAEventTracker';
import { GA_HOST_ACTIVITY, GA_BLACKLIST_SONG_ACTIVITY } from '../../../../common/constants';
import { blacklistSong } from '../../../../../services/api';
import theme from '../../../../common/theme';

const buttonStyle = {
  color: 'white',
  '&:hover': {
    color: `${theme.palette.juxy.main}`,
  },
};

function BlacklistButton({ songId = '', size = 'small', sx = {} }) {
  const gaEventTracker = useGAEventTracker(GA_HOST_ACTIVITY);

  const handleBlacklistSong = () => {
    gaEventTracker(GA_BLACKLIST_SONG_ACTIVITY);
    blacklistSong(songId).catch((err) => console.error(err.message)); // TODO: handle error
  };

  return (
    <IconButton
      aria-label="blacklist song"
      onClick={handleBlacklistSong}
      size={size}
      sx={{...buttonStyle, ...sx}}
    >
      <NotInterestedIcon fontSize="small" />
    </IconButton>
  );
}

BlacklistButton.propTypes = {
  songId: PropTypes.string.isRequired,
  size: PropTypes.string,
  sx: PropTypes.shape({}),
};

export default BlacklistButton;
