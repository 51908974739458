import React, { useEffect, useState, useDeferredValue } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import ToggleButtonGroup from '../../../../common/ToggleButtonGroup';
import Dialog from '../../../../common/Dialog';
import theme from '../../../../common/theme';
import CtaButtonStyle from '../CtaButtonStyle';

import useGAEventTracker from '../../../../../hooks/useGAEventTracker';
import { getRequestSongsList, requestSong } from '../../../../../services/api';
import { GA_GUEST_PLAYLIST, GA_REQUEST_SONG_CLICKED_ACTIVITY, GA_REQUEST_SONG_SUCCESSFULLY_ACTIVITY } from '../../../../common/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90%;
`;

const SelectSongContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

const DescriptionText = styled.span`
  color: ${theme.palette.juxy.secondary};
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
`;

const StyledTextField = styled(TextField)({
  width: '100%',
  alignItems: 'center',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '15px',
    fontSize: '16px',
    width: '90%',
    maxWidth: 450,
    margin: '25px 0',
  },
});

function RequestSong({ onSuccess = () => {}, onFailure = () => {} }) {
  const [openRequestSongDialog, setOpenRequestSongDialog] = useState(false);
  const [requestSongsList, setRequestSongsList] = useState([]);
  const [selectedSongId, setSelectedSongId] = useState();
  const [searchTerm, setSearchTerm] = useState('');

  const deferredSearch = useDeferredValue(searchTerm);
  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  useEffect(() => {
    getRequestSongsList(deferredSearch).then((songs) => {
      const transformedSongs = songs.map((song) => ({
        value: song.id,
        label: `${song.name} - ${song.artist}`,
      }));
      setRequestSongsList(transformedSongs);
    });
  }, [deferredSearch]);

  const handleRequestSongClicked = (requestSuccess = false) => {
    if (!openRequestSongDialog) {
      gaEventTracker(GA_REQUEST_SONG_CLICKED_ACTIVITY);
    }

    if (requestSuccess) {
      setSelectedSongId(undefined);
      setSearchTerm('');
      gaEventTracker(GA_REQUEST_SONG_SUCCESSFULLY_ACTIVITY);
      onSuccess();
    } else if (openRequestSongDialog) {
      onFailure('Failed to request song');
    }

    setOpenRequestSongDialog((prev) => !prev);
  };

  const handleSearchOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectedSongOnChange = (event, songId) => {
    setSelectedSongId(songId);
  };

  const handleOnSubmit = async () => {
    if (selectedSongId) {
      try {
        const isSongSelected = await requestSong(selectedSongId);
        handleRequestSongClicked(isSongSelected);
      } catch (error) {
        handleRequestSongClicked(false);
      }
    } else {
      onFailure('Please choose one song');
    }
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        sx={CtaButtonStyle}
        onClick={() => handleRequestSongClicked(false)}
      >
        Request a Song
      </Button>
      <Dialog open={openRequestSongDialog} fullScreen onClose={() => setOpenRequestSongDialog(false)}>
        <>
          <MuiIconButton
            edge="end"
            color="inherit"
            onClick={() => setOpenRequestSongDialog(false)}
            sx={{
              display: 'flex',
              alignSelf: 'end !important',
              margin: '10px 20px 0 0',
              width: '30px',
            }}
            aria-label="close"
          >
            <CloseIcon />
          </MuiIconButton>
          <Container>
            <SelectSongContainer>
              <h1 style={{ alignSelf: 'start', marginLeft: '16px' }}>Request Song</h1>
              <DescriptionContainer>
                <DescriptionText>Anything you’d like to hear on the playlist?</DescriptionText>
                <DescriptionText>
                  Send us your song request, and we’ll try to fit the song into the playlist!
                </DescriptionText>
              </DescriptionContainer>
              <StyledTextField
                size="large"
                placeholder="Search song"
                value={searchTerm}
                onChange={handleSearchOnChange}
                InputProps={{ endAdornment: <SearchIcon /> }}
              />
              <ToggleButtonGroup
                values={requestSongsList}
                selctedValues={selectedSongId}
                onChange={handleSelectedSongOnChange}
                exclusive
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  overflowY: 'auto',
                  maxHeight: '70%',
                  maxWidth: '400px',
                  margin: '0 15px',
                }}
              />
            </SelectSongContainer>
            <ButtonsContainer>
              <Button
                variant="text"
                sx={{ color: theme.palette.juxy.secondary, fontSize: '16px' }}
                size="large"
                onClick={() => setOpenRequestSongDialog(false)}
              >
                Back
              </Button>
              <Button
                sx={{
                  backgroundImage: theme.palette.juxy.gradient,
                  borderRadius: '50px',
                  height: '52px',
                  minWidth: '100px',
                  color: '#FFFFFF',
                  fontSize: '16px',
                }}
                disabled={!selectedSongId}
                onClick={handleOnSubmit}
              >
                Submit
              </Button>
            </ButtonsContainer>
          </Container>
        </>
      </Dialog>
    </>
  );
}

RequestSong.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default RequestSong;
