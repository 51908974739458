import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import MapIcon from '@mui/icons-material/Map';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    font-size: 30px;
    color: #FFFFFF30;
`;
// eslint-disable-next-line no-unused-vars
function VenuesMap({ userLocation, venueList }) {
  return (
    <Container>
      <MapIcon sx={{ fontSize: '170px' }} />
      COMING SOON
    </Container>
  );
}

VenuesMap.propTypes = {
  venueList: PropTypes.arrayOf(PropTypes.shape({})),
  userLocation: PropTypes.shape({}),
};

VenuesMap.defaultProps = {
  venueList: [],
  userLocation: {},
};

export default VenuesMap;
