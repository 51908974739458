/* eslint no-unused-vars: "off" */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import Button from '@mui/material/Button';
import ControlledTextField from '../../common/ControlledInputs/ControlledTextField';
import ControlledSelect from '../../common/ControlledInputs/ControlledSelect';
import { SMALL_SCREEN_SIZE, VENUE_SIZES, VENUE_TYPES } from '../../common/constants';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

function VenueDetailsStep({ setRegistrationStep, formValues, setFormValues, showAlert, setAlertMessage }) {
  const { control, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setFormValues({ ...data });
    // TODO: register host, and if successful,...
    setRegistrationStep(2);
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          name="venueName"
          label="Venue Name"
          rules={{ required: true }}
          errors={errors}
          control={control}
          defaultValue={formValues.venueName}
        />
        <ControlledTextField
          name="venueAddress"
          label="Address"
          rules={{ required: true }}
          errors={errors}
          control={control}
          defaultValue={formValues.venueAddress}
        />
        <ControlledTextField
          name="playlistName"
          label="Playlist Name"
          rules={{ required: true }}
          errors={errors}
          control={control}
          defaultValue={formValues.playlistName}
        />
        <ControlledSelect
          name="venueType"
          label="Venue Type"
          control={control}
          errors={errors}
          rules={{ required: true }}
          values={VENUE_TYPES}
          defaultValue={formValues.venueType}
        />
        <ControlledSelect
          name="venueSize"
          label="Number of Guests"
          control={control}
          errors={errors}
          rules={{ required: true }}
          values={VENUE_SIZES}
          defaultValue={formValues.venueSize}
        />
        <Button type="submit" variant="contained" color="secondary" sx={{ margin: '20px 0' }}>Next</Button>
      </Form>
    </Container>
  );
}

VenueDetailsStep.propTypes = {
  setRegistrationStep: PropTypes.func,
  formValues: PropTypes.shape({}),
  setFormValues: PropTypes.func,
  setAlertMessage: PropTypes.func,
  showAlert: PropTypes.func,
};

VenueDetailsStep.defaultProps = {
  setRegistrationStep: () => { },
  formValues: {},
  setFormValues: () => { },
  setAlertMessage: () => { },
  showAlert: () => { },
};

export default VenueDetailsStep;
