import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import useGAEventTracker from '../../../../../hooks/useGAEventTracker';

import Button from '@mui/material/Button';

import PlaylistOptionCard from '../PlaylistOptionCard';
import VoteCard from '../SongsPoll/VoteCard';
import muteSound from '../../../../../images/muteSound.png';
import sound from '../../../../../images/sound.png';

import { complainVolume, getProfile } from '../../../../../services/api';
import { GA_GUEST_PLAYLIST, GA_VOLUME_COMPLAINT_CLICKED_ACTIVITY, GA_VOLUME_COMPLAINT_SUCCESSFULLY_ACTIVITY } from '../../../../common/constants';

import CtaButtonStyle from '../CtaButtonStyle';
import theme from '../../../../common/theme';

const votedComplaint = {
  0: 'low',
  1: 'high',
};

const votedComplaintIndex = {
  low: 0,
  high: 1,
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
    margin-top: 30px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    color: ${theme.palette.juxy.secondary};
    font-size: 16px;
    font-weight: 400;
`;

const VoteContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Versus = styled.span`
  font-size: 28px;
  margin: 34px 0;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  margin: 10px 30px;
`;

function VolumeComplaintPoll({ onSuccess = () => {}, onFailure = () => {} }) {
  const [openLeavePlaylistDrawer, setOpenLeavePlaylistDrawer] = useState(false);
  const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
  const [voted, setVoted] = useState(false);

  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  useEffect(() => {
    getProfile().then((profile) => {
      const complaint = profile?.complaint;
      setVoted(complaint);
      setSelectedCardIndex(votedComplaintIndex[complaint]);
    });
  }, []);

  const handleVolumeComplaintClicked = (requestSuccess = false) => {
    if (!openLeavePlaylistDrawer) {
      gaEventTracker(GA_VOLUME_COMPLAINT_CLICKED_ACTIVITY);
    }
    if (requestSuccess) {
      gaEventTracker(GA_VOLUME_COMPLAINT_SUCCESSFULLY_ACTIVITY);
      onSuccess();
    } else if (openLeavePlaylistDrawer) {
      onFailure('Failed to send volume complaint');
    }

    setOpenLeavePlaylistDrawer((prev) => !prev);
  };

  const handleCardSelection = (cardIndex) => {
    if (voted) { return; }
    setSelectedCardIndex(cardIndex);
  };

  const handleOnSubmit = async () => {
    if (selectedCardIndex < 0) {
      onFailure('Please select your choice'); // TODO: change to use central alerts
      return;
    }
    if (voted) {
      return;
    }
    
    try {
      const volume = votedComplaint[selectedCardIndex];
      const isVolumeComplaintSuccessful = await complainVolume(volume);
      setVoted(isVolumeComplaintSuccessful);
      handleVolumeComplaintClicked(isVolumeComplaintSuccessful);
    } catch (error) {
      handleVolumeComplaintClicked(false);
    }    
  };

  return (
    <>
      <Button
        variant="contained"
        size="large"
        sx={CtaButtonStyle}
        onClick={() => handleVolumeComplaintClicked(false)}
      >
        Volume Complaint
      </Button>
      <PlaylistOptionCard
        fullScreen
        open={openLeavePlaylistDrawer}
        onClose={() => setOpenLeavePlaylistDrawer(false)}
      >
        <Container>
          <DescriptionContainer>
            <h1 style={{ color: 'white' }}>Volume complaint</h1>
            <span>{voted ? 'Thanks for your vote!' : 'Tell us what you think about the volume'}</span>
          </DescriptionContainer>
          <VoteContainer>
            <VoteCard
              onSelected={() => handleCardSelection(0)}
              isSelected={selectedCardIndex === 0}
              title="Volume is too low"
              image={muteSound}
              voted={!!voted}
            />
            <Versus>Vs.</Versus>
            <VoteCard
              onSelected={() => handleCardSelection(1)}
              isSelected={selectedCardIndex === 1}
              title="Volume is too high"
              image={sound}
              voted={!!voted}
            />
          </VoteContainer>
          <ButtonsContainer>
            <Button
              sx={{
                backgroundImage: theme.palette.juxy.gradient,
                borderRadius: '50px',
                height: '52px',
                width: '100px',
                color: '#FFFFFF',
                fontSize: '16px',
              }}
              onClick={voted ? () => setOpenLeavePlaylistDrawer(false) : handleOnSubmit}
            >
              {voted ? 'Finish' : 'Submit'}
            </Button>
          </ButtonsContainer>
        </Container>
      </PlaylistOptionCard>
    </>
  );
}

VolumeComplaintPoll.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
};

export default VolumeComplaintPoll;
