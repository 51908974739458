import React, { useEffect, useState, useDeferredValue } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import MuiIconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';

import { getRequestSongsList, requestSong } from '../../../services/api';
import ToggleButtonGroup from '../../common/ToggleButtonGroup';
import Dialog from '../../common/Dialog';
import theme from '../../common/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
`;

const SelectSongContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 16px;
`;

const DescriptionText = styled.span`
  color: ${theme.palette.juxy.secondary};
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
`;

const StyledTextField = styled(TextField)({
  width: '100%',
  alignItems: 'center',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    borderRadius: '15px',
    fontSize: '16px',
    width: '90%',
    maxWidth: 450,
    margin: '25px 0',
  },
});

function RequestSong({ open, onClose, successfulCallback }) {
  const [requestSongsList, setRequestSongsList] = useState([]);
  const [selectedSongId, setSelectedSongId] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [showError, setShowError] = useState(false);

  const deferredSearch = useDeferredValue(searchTerm);

  useEffect(() => {
    getRequestSongsList().then((songs) => {
      setRequestSongsList(songs);
    });
  }, []);

  useEffect(() => {
    getRequestSongsList(deferredSearch).then((songs) => {
      setRequestSongsList(songs);
    });
  }, [deferredSearch]);

  const handleSearchOnChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectedSongOnChange = (event, songId) => {
    setSelectedSongId(songId);
  };

  const handleAlertErrorOnClose = () => {
    setShowError(false);
  };

  const handleOnSubmit = () => {
    if (selectedSongId) {
      requestSong(selectedSongId).then(() => {
        successfulCallback();
        setSelectedSongId(undefined);
        setSearchTerm('');
      });
    } else {
      setShowError(true);
    }
  };

  return (
    <Dialog open={open} fullScreen onClose={onClose} bgColor="#131313">
      <>
        <MuiIconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          sx={{
            display: 'flex',
            alignSelf: 'end !important',
            margin: '10px 20px 0 0',
            width: '30px',
          }}
          aria-label="close"
        >
          <CloseIcon />
        </MuiIconButton>
        <Container>
          <SelectSongContainer>
            <h1 style={{ alignSelf: 'start', marginLeft: '16px' }}>Request Song</h1>
            <DescriptionContainer>
              <DescriptionText>Anything you’d like to hear on the playlist?</DescriptionText>
              <DescriptionText>Send us your song request, and we’ll try to fit the song into the playlist!</DescriptionText>
            </DescriptionContainer>
            <StyledTextField
              size="large"
              placeholder="Search song"
              value={searchTerm}
              onChange={handleSearchOnChange}
              InputProps={{ endAdornment: <SearchIcon /> }}
            />
            <ToggleButtonGroup
              values={requestSongsList}
              selctedValues={selectedSongId}
              onChange={handleSelectedSongOnChange}
              exclusive
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                overflowY: 'auto',
                maxHeight: '70%',
                maxWidth: '400px',
                margin: '0 15px',
              }}
            />
          </SelectSongContainer>
          <ButtonsContainer>
            <Button
              variant="text"
              sx={{ color: theme.palette.juxy.secondary, fontSize: '16px' }}
              size="large"
              onClick={onClose}
            >
              Back
            </Button>
            <Button
              sx={{
                backgroundImage: theme.palette.juxy.gradient,
                borderRadius: '50px',
                height: '52px',
                minWidth: '100px',
                color: '#FFFFFF',
                fontSize: '16px',
              }}
              onClick={handleOnSubmit}
            >
              Submit
            </Button>
          </ButtonsContainer>
        </Container>
        <Snackbar open={showError} autoHideDuration={6000} onClose={handleAlertErrorOnClose}>
          <MuiAlert onClose={handleAlertErrorOnClose} severity="error" sx={{ width: '100%', fontSize: '16px' }}>
            Please choose one song
          </MuiAlert>
        </Snackbar>
      </>
    </Dialog>
  );
}

RequestSong.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  successfulCallback: PropTypes.func,
};

RequestSong.defaultProps = {
  open: false,
  onClose: () => {},
  successfulCallback: () => {},
};

export default RequestSong;
