import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import ReactGA from 'react-ga4';

import history from './history';
import App from './components/App';

import store from './store/store';

import './index.css';
import theme from './components/common/theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

const GA_TRACKING_ID = process.env?.NODE_ENV === 'development' ? 'G-SZW896RJ2T' : 'G-0V2SWSMNX4';
ReactGA.initialize(GA_TRACKING_ID);

root.render(
  <BrowserRouter history={history}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </BrowserRouter>
);
