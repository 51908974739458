import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';

import useGAEventTracker from '../../../hooks/useGAEventTracker';
import BlacklistButton from '../../Hosts/Playlist/SongControls/BlacklistButton';
import {
  GA_HOST_ACTIVITY,
  GA_LIKED_SONG_ACTIVITY,
  GA_OPENED_STARRED_SONG_ON_SPOTIFY_ACTIVITY,
} from '../../common/constants';
import { openSongOnSpotify } from '../../common/misc';
import { setLikeSong } from '../../../services/api';
import { SpotifyIcon } from '../../common/icons';
import theme from '../../common/theme';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledIconButton = styled(IconButton)({
  color: (props) => (props.$isLiked ? theme.palette.juxy.main : 'white'),
  '&:hover': {
    color: (props) => (props.$isLiked ? 'white' : theme.palette.juxy.main),
  },
  '&.Mui-disabled': {
    color: '#C7CC7C30',
  },
});

function OptionsControls({
  songId = '',
  spotifyId = '',
  isLiked = false,
  onError = () => {},
  onLikeSong = () => {},
}) {
  const gaEventTracker = useGAEventTracker(GA_HOST_ACTIVITY);

  const handleLikeSongClicked = () => {
    gaEventTracker(GA_LIKED_SONG_ACTIVITY);
    if (songId) {
      setLikeSong(songId)
        .then(({ success }) => {
          if (!!success) {
            onLikeSong();
          }
        })
        .catch((err) => onError(err.message));
    }
  };

  const onSpotifySelect = () => {
    gaEventTracker(GA_OPENED_STARRED_SONG_ON_SPOTIFY_ACTIVITY);
    openSongOnSpotify(spotifyId);
  };

  return (
    <Container>
      <StyledIconButton
        aria-label="like song"
        size="small"
        $isLiked={isLiked}
        onClick={handleLikeSongClicked}
      >
        <FavoriteBorderOutlinedIcon fontSize="small" />
      </StyledIconButton>
      <BlacklistButton songId={songId} />
      {/* TODO: change into function to render icon as per user's streaming service */}
      <IconButton
        aria-label="open in streaming service"
        size="small"
        sx={{ color: theme.palette.spotify.main }}
        onClick={onSpotifySelect}
      >
        <SpotifyIcon fontSize="small" />
      </IconButton>
    </Container>
  );
}

OptionsControls.propTypes = {
  songId: PropTypes.string,
  spotifyId: PropTypes.string,
  isLiked: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  onLikeSong: PropTypes.func,
};

export default OptionsControls;
