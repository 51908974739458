import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import PlaylistOptionCard from '../PlaylistOptionCard';
import VoteCard from '../VoteCard';

import useGAEventTracker from '../../../hooks/useGAEventTracker';
import { GA_VOTE_SONG, GA_VOTE_SONG_SUCCESSFULLY_ACTIVITY } from '../../common/constants';
import { getVoteData, voteSong } from '../../../services/api';
import theme from '../../common/theme';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80%;
    margin-top: 30px;
    gap: 55px;
`;

const DescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    color: ${theme.palette.juxy.secondary};
    font-size: 16px;
    font-weight: 400;
`;

const Versus = styled.span`
  font-size: 28px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: stretch;
  margin: 10px 30px;
`;

const CTAButtonStyle = {
  backgroundImage: 'linear-gradient(143.44deg, #9245FF 15.24%, #44A9DB 91.4%)',
  borderRadius: '50px',
  color: '#FFFFFF',
  fontSize: '16px',
  height: '52px',
  width: '100px',
};

function NextSongPoll({ open, onClose }) {
  const [selectedSongIndex, setSelectedSongIndex] = useState(-1);
  const [pollData, setPollData] = useState([]);
  const [voted, setVoted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Please select a song before voting');
  const [showError, setShowError] = useState(false);

  const gaEventTracker = useGAEventTracker(GA_VOTE_SONG);

  useEffect(() => {
    if (!open) {
      setSelectedSongIndex(-1);
      return;
    }
    getVoteData()
      .then((data) => {
        if (data?.enabled === false || data?.result === 0) {
          setErrorMessage('Cannot get vote options. Please try again later.');
          setShowError(true);
          setPollData([]);
          return;
        }

        setPollData([data?.firstSongData, data?.secondSongData]);
        setVoted(data?.voted);
      });
  }, [open]);

  const handleOnSubmit = () => {
    if (selectedSongIndex < 0) {
      setErrorMessage('Please select a song before voting');
      setShowError(true);
      return;
    }
    voteSong(selectedSongIndex)
      .then((data) => {
        if (data === undefined) {
          setErrorMessage('Vote failed. Please try again later.');
          setShowError(true);
          return;
        }
        setPollData((prevData) => ([
          { ...prevData[0], votePercentage: data?.score[0] },
          { ...prevData[1], votePercentage: data?.score[1] },
        ]));
        setVoted(data?.songId);
        gaEventTracker(GA_VOTE_SONG_SUCCESSFULLY_ACTIVITY);
      });
  };

  const handleCardSelection = (cardIndex) => {
    if (voted) { return; }
    setSelectedSongIndex(cardIndex);
  };

  const handleAlertErrorOnClose = () => { setShowError(false); };

  const renderCallToActionButton = () => (
    voted ? (
      <Button
        sx={CTAButtonStyle}
        onClick={onClose}
      >
        Finish
      </Button>
    ) : (
      <Button
        sx={CTAButtonStyle}
        onClick={handleOnSubmit}
      >
        Vote
      </Button>
    )
  );

  const renderDescriptionContainer = () => (
    voted ? (
      <>
        <h1 style={{ color: 'white' }}>Current vote results</h1>
        <span>Great Choice! those are the current results</span>
      </>
    ) : (
      <>
        <h1 style={{ color: 'white' }}>Vote For Next Song</h1>
        <span>Choose which song you want to listen to next</span>
      </>
    )
  );

  return (
    <PlaylistOptionCard
      fullScreen
      open={open}
      onClose={onClose}
    >
      <Container>
        {(!pollData.length || pollData.includes(undefined)) ? <CircularProgress color="secondary" /> : (
          <>
            <DescriptionContainer>
              {renderDescriptionContainer()}
            </DescriptionContainer>
            <VoteCard
              onSelected={() => handleCardSelection(0)}
              isSelected={selectedSongIndex === 0}
              title={pollData[0]?.name}
              subTitle={pollData[0]?.artist}
              image={pollData[0]?.image}
              percentage={voted ? pollData[0]?.votePercentage : 0}
              voted={voted}
            />
            <Versus>Vs.</Versus>
            <VoteCard
              onSelected={() => handleCardSelection(1)}
              isSelected={selectedSongIndex === 1}
              title={pollData[1]?.name}
              subTitle={pollData[1]?.artist}
              image={pollData[1]?.image}
              percentage={voted ? pollData[1]?.votePercentage : 0}
              voted={voted}
            />
            <ButtonsContainer>
              {renderCallToActionButton()}
            </ButtonsContainer>
          </>
        )}
        <Snackbar open={showError} autoHideDuration={6000} onClose={handleAlertErrorOnClose}>
          <MuiAlert onClose={handleAlertErrorOnClose} severity="error" sx={{ width: '100%', fontSize: '16px' }}>
            {errorMessage}
          </MuiAlert>
        </Snackbar>
      </Container>
    </PlaylistOptionCard>
  );
}

NextSongPoll.propTypes = {
  firstSong: PropTypes.shape({}),
  secondSong: PropTypes.shape({}),
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

NextSongPoll.defaultProps = {
  firstSong: {},
  secondSong: {},
  open: true,
  onClose: () => {},
};

export default NextSongPoll;
