import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { ARE_YOU_SURE_MESSAGE } from '../common/constants';

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: 400px;
`;

function DeleteUserDialog({ onCancel, onDelete }) {
  return (
    <Container style={{ minWidth: '200px', padding: '10px' }}>
      {`${ARE_YOU_SURE_MESSAGE} delete your account?`}
      <Container style={{ minWidth: '0px', flexDirection: 'row', gap: '15px' }}>
        <Button variant="contained" color="error" sx={{ margin: '25px 0' }} onClick={() => onCancel()}>CANCEL</Button>
        <Button variant="contained" color="secondary" sx={{ margin: '25px 0' }} onClick={() => onDelete()}>DELETE</Button>
      </Container>
    </Container>
  );
}

DeleteUserDialog.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

DeleteUserDialog.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
};

export default DeleteUserDialog;
