import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    juxy: {
      main: '#9245FF',
      secondary: '#C7C7CC',
      gradient: 'linear-gradient(143.44deg, #9245FF 15.24%, #44A9DB 91.4%)',
    },
    spotify: {
      main: '#1DB954',
      hover: '#25f56e',
    },
  },
  typography: {
    fontFamily: ['DM Sans'],
    fontSize: 16,
    button: {
      textTransform: 'none',
    },
  },
});

export default theme;
