import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useOutletContext } from 'react-router-dom';

import { SMALL_SCREEN_SIZE } from '../../common/constants';
import UserDetails from '../Guest/UserDetailsStep';
import VenueDetailsStep from './VenueDetailsStep';
import ConnectAppStep from './ConnectAppStep';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
  }
`;

const stepTitle = {
  0: 'Host Registration',
  1: 'Venue Details',
  2: 'Connect Streaming App',
};

function RegistrationForm() {
  const { registrationStep, setRegistrationStep, setStepTitle, setAlert, setIsShowAlert, setDisplayButtons } = useOutletContext();
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    setStepTitle(stepTitle[registrationStep]);
    setDisplayButtons(false);
  }, []);

  return (
    <Container>
      {registrationStep === 0
        && (
        <UserDetails
          setRegistrationStep={setRegistrationStep}
          setAlertMessage={setAlert}
          showAlert={setIsShowAlert}
        />
        )}
      {registrationStep === 1
        && (
          <VenueDetailsStep
            formValues={formValues}
            setFormValues={setFormValues}
            setRegistrationStep={setRegistrationStep}
            setAlertMessage={setAlert}
            showAlert={setIsShowAlert}
          />
        )}
      {registrationStep === 2
        && (
          <ConnectAppStep
            formValues={formValues}
            setFormValues={setFormValues}
            setRegistrationStep={setRegistrationStep}
            setAlertMessage={setAlert}
            showAlert={setIsShowAlert}
          />
        )}
    </Container>
  );
}

export default RegistrationForm;
