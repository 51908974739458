import { createSlice } from '@reduxjs/toolkit';

import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_ERROR_MESSAGE_TITLE,
  NOTIFICATION_SEVERITY_SUCCESS,
  NOTIFICATION_SEVERITY_INFO,
  NOTIFICATION_SEVERITY_ERROR,
} from '../../components/common/constants';

const initialState = {
  isDisplayed: false,
  severity: NOTIFICATION_SEVERITY_ERROR,
  title: DEFAULT_ERROR_MESSAGE_TITLE,
  message: DEFAULT_ERROR_MESSAGE,
  code: 400,
};

export const notificationReducer = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearNotification: (state) => ({...state, isDisplayed: false,}),
    notifySuccess: (_, action) => ({
      isDisplayed: true,
      severity: NOTIFICATION_SEVERITY_SUCCESS,
      ...action.payload,
    }),
    notifyInfo: (_, action) => ({
      isDisplayed: true,
      severity: NOTIFICATION_SEVERITY_INFO,
      ...action.payload,
    }),
    notifyError: (_, action) => ({
      isDisplayed: true,
      severity: NOTIFICATION_SEVERITY_ERROR,
      ...action.payload,
    }),
  },
});

export const { clearNotification, notifySuccess, notifyInfo, notifyError } = notificationReducer.actions;

// Selectors
export const getNotification = (state) => state.notification;
export const isNotificationDisplayed = (state) => getNotification(state).isDisplayed;
export const getNotificationSeverity = (state) => getNotification(state).severity;
export const getNotificationTitle = (state) => getNotification(state).title;
export const getNotificationMessage = (state) => getNotification(state).message;

export default notificationReducer.reducer;
