import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Button from '@mui/material/Button';

import useGAEventTracker from '../../hooks/useGAEventTracker';
import ControlledTextField from '../common/ControlledInputs/ControlledTextField';
import ControlledPasswordField from '../common/ControlledInputs/ControlledPasswordField';
import Alert from '../common/Alert';
import Dialog from '../common/Dialog';
import theme from '../common/theme';
import SpotifyButton from '../common/SpotifyButton';
import {
  GA_EDIT_PROFILE,
  GA_USER_UNLINKED_SPOTIFY,
  SMALL_SCREEN_SIZE,
  EMAIL_PATTERN,
  HOME_PATH,
  GUEST_LOGIN_PATH,
} from '../common/constants';
import { getUser, logout, updateProfile } from '../../store/slices/userReducer';
import { updateUserProfile, deleteUserProfile, unlinkSpotifyAccount } from '../../services/api';
import DeleteUserDialog from './DeleteUserDialog';

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 400px;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: 700;
  margin: 26px 0;
`;

const UserDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

const StyledSpotifyButton = styled(SpotifyButton)({
  marginBottom: '25px',
});

function EditProfile() {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const user = useSelector(getUser);
  const { displayName: name, email, musicService, isLoggedIn } = user;

  const [showPassword, setShowPassword] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const gaEventTracker = useGAEventTracker(GA_EDIT_PROFILE);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate(GUEST_LOGIN_PATH);
    }
  }, [isLoggedIn]);

  const onSave = (data) => {
    updateUserProfile(data).then(({ result, ...profile }) => {
      if (!!result == false) {
        setIsShowAlert(true);
        return;
      }
      dispatch(updateProfile(profile));
    });
  };

  const handleDeleteAccountClick = () => {
    setShowDeleteDialog((value) => !value);
  };

  const handleDeleteAccountApprovalClick = () => {
    deleteUserProfile(user);
    handleDeleteAccountClick();
    dispatch(logout());
    navigate(HOME_PATH);
  };

  const handleUnlinkSpotifyClick = () => {
    gaEventTracker(GA_USER_UNLINKED_SPOTIFY);
    unlinkSpotifyAccount();
  };

  return (
    <Container>
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DeleteUserDialog
          onCancel={() => handleDeleteAccountClick()}
          onDelete={() => handleDeleteAccountApprovalClick()}
        />
      </Dialog>
      <Title>Edit profile</Title>
      <AccountCircleOutlinedIcon sx={{ height: '60px', width: '60px' }} />
      <UserDetailsForm onSubmit={handleSubmit(onSave)}>
        <ControlledTextField
          name="name"
          label="Name"
          rules={{ required: true }}
          errors={errors}
          control={control}
          defaultValue={name}
        />
        <ControlledTextField
          name="email"
          label="Email"
          rules={{ required: true, pattern: EMAIL_PATTERN }}
          errors={errors}
          control={control}
          defaultValue={email}
        />
        <ControlledPasswordField
          name="password"
          label="Password"
          rules={{ required: false }} // TODO: think how to handle password change
          errors={errors}
          control={control}
        />
        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundImage: theme.palette.juxy.gradient,
            borderRadius: '50px',
            height: '52px',
            minWidth: '100px',
            color: '#FFFFFF',
            fontSize: '16px',
            marginTop: '10px',
          }}
        >
          Save
        </Button>
      </UserDetailsForm>
      <Alert
        isDisplayed={isShowAlert}
        variant="filled"
        onClick={() => setIsShowAlert(false)}
        message="Something went wrong..."
      />
      {musicService === 'spotify' && (
        <StyledSpotifyButton
          label="Unlink Spotify account"
          onClick={handleUnlinkSpotifyClick}
          sx={{ margin: '35px 0 0 0' }}
        />
      )}
      <Button
        variant="contained"
        color="error"
        sx={{ margin: '35px 0', borderRadius: '50px', height: '40px', width: '250px' }}
        onClick={() => handleDeleteAccountClick()}
      >
        DELETE JUXY ACCOUNT
      </Button>
    </Container>
  );
}

export default EditProfile;
