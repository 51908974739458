import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';

import ControlledTextField from '../../common/ControlledInputs/ControlledTextField';
import ControlledPasswordField from '../../common/ControlledInputs/ControlledPasswordField';
import TermsAndPrivacyPolicyBlock from '../TermsAndPrivacyPolicyBlock';
import useGAEventTracker from '../../../hooks/useGAEventTracker';
import { register } from '../../../services/api';
import { fetchProfile } from '../../../store/thunks/userThunks';
import {
  EMAIL_PATTERN,
  NAME_PATTERN,
  SMALL_SCREEN_SIZE,
  GA_GUEST_REGISTRATION,
  GA_REGISTERED_SUCCESSFULLY_ACTIVITY,
  FIND_SPOTIFY_EMAIL,
} from '../../common/constants';
import { isEmptyObject } from '../../common/misc';
import Loader from '../../common/Loader';
import theme from '../../common/theme';

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  min-width: 400px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    min-width: 300px;
  }
`;

const EmailHelpTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const EmailHelpText = styled.span`
  color: ${theme.palette.juxy.secondary};
  font-size: 16px;
`;

const EmailHelpTextLink = styled(Link)`
  color: ${theme.palette.juxy.secondary};
  font-size: 16px;
  text-decoration: underline;
`;

function UserDetailsStep({ setRegistrationStep = () => {}, onError = () => {} }) {
  const [formValues, setFormValues] = useState({});
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();

  const [termsChecked, setTermsChecked] = useState(formValues.terms);
  const [loading, setLoading] = useState(false);

  const gaEventTracker = useGAEventTracker(GA_GUEST_REGISTRATION);

  const onSubmit = async (values) => {
    values = { ...values, terms: termsChecked };
    setFormValues(values);
    if (!(isEmptyObject(errors) && values?.email)) {
      return;
    }

    setLoading(true);
    try {
      await register(values);
      gaEventTracker(GA_REGISTERED_SUCCESSFULLY_ACTIVITY);
      dispatch(fetchProfile());
      setRegistrationStep(1);
    } catch (err) {
      onError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {loading ? (
        <Loader />
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ControlledTextField
            name="email"
            label="Email"
            rules={{ required: true, pattern: EMAIL_PATTERN }}
            errors={errors}
            control={control}
            placeholder="What's your email?"
            defaultValue={formValues.email}
            helperText={
              <EmailHelpTextContainer>
                <EmailHelpText>Want to sync Juxy with Spotify?</EmailHelpText>
                <EmailHelpText>Use your Spotify account email address</EmailHelpText>
                <EmailHelpTextLink to={FIND_SPOTIFY_EMAIL}>Not sure which?</EmailHelpTextLink>
              </EmailHelpTextContainer>
            }
          />
          <ControlledPasswordField
            name="password"
            label="Password"
            rules={{ required: true, minLength: 6 }}
            errors={errors}
            control={control}
            placeholder="Choose a password"
            defaultValue={formValues.password}
          />
          <ControlledTextField
            name="name"
            label="Name"
            rules={{ required: true, pattern: NAME_PATTERN }}
            errors={errors}
            control={control}
            placeholder="What's your name?"
            defaultValue={formValues.name}
          />
          <TermsAndPrivacyPolicyBlock
            control={control}
            checked={termsChecked}
            setChecked={setTermsChecked}
            defaultValue={termsChecked}
          />
          <Button
            type="submit"
            variant="contained"
            sx={{
              margin: '20px 0',
              borderRadius: '50px',
              backgroundImage: theme.palette.juxy.gradient,
            }}
          >
            Register
          </Button>
        </Form>
      )}
    </Container>
  );
}

UserDetailsStep.propTypes = {
  setRegistrationStep: PropTypes.func,
  onError: PropTypes.func,
};

export default UserDetailsStep;
