import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import useGAEventTracker from '../../hooks/useGAEventTracker';
import { getUser, updateUserLikedSongs } from '../../store/slices/userReducer';
import { getProfile, setLikeSong } from '../../services/api';
import { GA_MUSIC_LIBRARY, GA_OPENED_STARRED_SONG_ON_SPOTIFY_ACTIVITY } from '../common/constants';
import theme from '../common/theme';
import { openSongOnSpotify } from '../common/misc';

import { SpotifyIcon } from '../common/icons';
import SongCard from '../Guests/SongCard';
import PlaylistOptionDrawer from '../Guests/PlaylistOptionDrawer';
import MusicLibraryCard from './MusicLibraryCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
`;

const Body = styled.div`
  padding: 0 16px;
  font-size: 18px;
  color: #666666;
`;

const ProfileImage = styled.img`
    width: 76px;
    height: 76px;
    border-radius: 50px;
`;

function MusicLibrary() {
  const [user, setUser] = useState(useSelector(getUser));
  const [optionsCardOpen, setOptionsCardOpen] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedSong, setSelectedSong] = useState({});
  const [snackbarMessage, setSnackbarMessage] = useState('Your request have been submitted!');

  const dispatch = useDispatch();
  const gaEventTracker = useGAEventTracker(GA_MUSIC_LIBRARY);

  const onOptionCardClose = () => {
    setOptionsCardOpen(false);
  };

  const handleOptionsSelected = (song) => {
    setSelectedSong(song);
    setOptionsCardOpen(true);
  };

  const onSpotifySelect = (song) => {
    gaEventTracker(GA_OPENED_STARRED_SONG_ON_SPOTIFY_ACTIVITY);
    onOptionCardClose();
    song?.engineData?.spotify?.id && openSongOnSpotify(song?.engineData?.spotify?.id);
  };

  const onRemoveStarredSelect = () => {
    setLikeSong(selectedSong?.id)
      .then((res) => {
        if (res.success === 0) {
          throw new Error('Cannot complete remove request. Please try again later');
        }
        return getProfile(); // TODO: consider implementing /get_liked_songs api endpoint
      }).then((profile) => {
        dispatch(updateUserLikedSongs(profile.like_songs));
        setUser((currentUser) => ({ ...currentUser, likedSongs: profile.like_songs }));
      })
      .catch((err) => {
        setSnackbarMessage(err.message);
        setOpenSnackbar(true);
      });
    onOptionCardClose();
  };

  const renderSongCardOptions = (song) => (
    <Container>
      <IconButton
        size="small"
        onClick={() => handleOptionsSelected(song)}
        sx={{ mb: '10px', color: 'white', '&:hover': { backgroundColor: 'rgba(255,255,255, 0.11)' } }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Button
        variant="contained"
        size="small"
        startIcon={<SpotifyIcon />}
        color="spotify"
        sx={{ borderRadius: '50px', '&:hover': { backgroundColor: theme.palette.spotify.hover } }}
        onClick={() => onSpotifySelect(song)}
      >
        Spotify
      </Button>
    </Container>
  );

  return (
    <>
      <Header>
        {user.image
          ? <ProfileImage alt="profile image" src={URL.createObjectURL(user?.image)} />
          : <AccountCircleOutlinedIcon sx={{ height: '76px', width: '76px' }} />}
        { user?.displayName && <p style={{ fontSize: '24px' }}>{ user.displayName }</p> }
      </Header>
      <PlaylistOptionDrawer
        open={optionsCardOpen}
        onClose={onOptionCardClose}
        height="70%"
      >
        <MusicLibraryCard
          song={selectedSong}
          onSpotifySelect={() => onSpotifySelect(selectedSong)}
          onRemoveStarredSelect={onRemoveStarredSelect}
        />
      </PlaylistOptionDrawer>
      <Body>
        <h4 style={{ fontSize: '22px', color: 'white' }}>My Music</h4>
        <p style={{ paddingBottom: '6px' }}>
          {user.likedSongs?.length > 0 ? 'Your starred Songs' : 'You do not have starred songs yet'}
        </p>
        {user?.likedSongs?.map((song, idx) => (
          <SongCard
            key={song?.id}
            song={song}
            style={{ topBorder: idx === 0 }}
            options={renderSongCardOptions(song)}
          />
        ))}
      </Body>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenSnackbar(false)}
        ContentProps={{ sx: { fontSize: 20 } }}
        message={snackbarMessage}
      />
    </>
  );
}

export default MusicLibrary;
