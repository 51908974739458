/* eslint no-unused-vars: "off", max-len: "off" */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import { numberToStringMMSS, normalize } from '../common/misc';
import { MEDIUM_SCREEN_SIZE } from '../common/constants';

const SongProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 14px;
  font-weight: bold;
`;

const ProgressBar = styled(LinearProgress)`
  background-color: #666;
  border-radius: 10px;
  min-width: 400px;
  
  & .MuiLinearProgress-bar {
    background-color: #fff;
  }

  @media (max-width: ${MEDIUM_SCREEN_SIZE}) {
    min-width: 200px;
  }
`;

function SongProgressBar({ songDuration = 0, progress = 0, onSongFinished = () => {}, playing = false }) {
  const [songProgress, setSongProgress] = useState(normalize(progress, songDuration));
  const startTime = normalize(1, songDuration);
  const endTime = normalize(songDuration, songDuration);

  const elapsedTime = (songDuration * songProgress) / 100;

  useEffect(() => {
    const timer = setInterval(() => {
      if (!playing) {
        return;
      }

      if (songProgress >= 100) {
        onSongFinished();
      }

      setSongProgress((prevProgress) => (prevProgress >= endTime ? startTime : prevProgress + startTime));
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [playing, progress]);

  useEffect(() => {
    setSongProgress(normalize(progress, songDuration));
  }, [progress]);

  return (
    <SongProgress>
      <span>{numberToStringMMSS(elapsedTime)}</span>
      <ProgressBar
        variant="determinate"
        value={songProgress}
      />
      <span>{numberToStringMMSS(songDuration)}</span>
    </SongProgress>
  );
}

SongProgressBar.propTypes = {
  songDuration: PropTypes.number,
  progress: PropTypes.number,
  onSongFinished: PropTypes.func,
  playing: PropTypes.bool,
};

export default SongProgressBar;
