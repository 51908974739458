import React from 'react';
import styled from 'styled-components';
import JuxyLogo from '../images/JuxyLogo.png';
import PropTypes from 'prop-types';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px 24px 0 24px;
  gap: 16px;
`;

const Logo = styled.img`
  border-radius: 10px;
  margin-bottom: 24px;
`;

const Title = styled.span`
  font-size: 32px;
  line-height: normal;
  text-align: center;
`;

const Paragraph = styled.span`
  font-size: 18px;
  line-height: normal;
`;

function UnderConstruction({ title='Coming Soon!', text='This section is under construction' }) {
  return (
    <Container>
      <Logo alt="Juxy" width={300} src={JuxyLogo} />
      <Title>{title}</Title>
      <Paragraph>{text}</Paragraph>
    </Container>
  );
}

UnderConstruction.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default UnderConstruction;
