import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox } from '@mui/material';
import { Controller } from 'react-hook-form';
import theme from '../theme';

function ControlledCheckbox({ control, name, label, value, setValue, required, defaultValue }) {
  const handleOnChange = (event) => {
    setValue(event.target.checked);
  };
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={
            ({ field }) => (
              <FormControlLabel
                sx={{ color: theme.palette.juxy.secondary, marginRight: '5px' }}
                control={(
                  <Checkbox
                    {...field}
                    checked={value}
                    onChange={handleOnChange}
                    sx={{
                      color: theme.palette.juxy.secondary,
                      '&.Mui-checked': {
                        color: '#9245FF',
                      },
                    }}
                    required={required}
                  />
              )}
                label={label}
              />
            )
          }
    />
  );
}

ControlledCheckbox.propTypes = {
  control: PropTypes.shape({}),
  name: PropTypes.string,
  label: PropTypes.shape({}),
  value: PropTypes.bool,
  setValue: PropTypes.func,
  required: PropTypes.bool,
  defaultValue: PropTypes.bool,
};

ControlledCheckbox.defaultProps = {
  control: {},
  name: '',
  label: PropTypes.shape({}),
  value: false,
  setValue: () => {},
  required: false,
  defaultValue: false,
};

export default ControlledCheckbox;
