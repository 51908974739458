// application name
export const NAME = 'JUXY';

// user types
export const USER_TYPE_HOST = 'Host';
export const USER_TYPE_GUEST = 'Guest';

// eslint-disable-next-line no-useless-escape
export const EMAIL_PATTERN = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const NAME_PATTERN = /^[^0-9]+$/;

// Route paths
export const HOME_PATH = '/';
export const REGISTER_PATH = '/register';
export const HOST_PATH = 'host';
export const GUEST_PATH = 'guest';
export const SETTINGS_PATH = 'settings';
export const HELP_PATH = '/help';
export const REGISTER_HOST_PATH = `${REGISTER_PATH}/${HOST_PATH}`;
export const REGISTER_GUEST_PATH = `${REGISTER_PATH}/${GUEST_PATH}`;
export const LOGIN_PATH = '/login';
export const HOST_LOGIN_PATH = `${LOGIN_PATH}/${HOST_PATH}`;
export const GUEST_LOGIN_PATH = `${LOGIN_PATH}/${GUEST_PATH}`;
export const PASSWORD_RESET_PATH = '/reset-password';
export const ABOUT_US_PATH = '/about';
export const EDIT_PROFILE_PATH = '/profile';
export const LIBRARY_PATH = 'music';
export const MUSIC_LIBRARY_PATH = `${EDIT_PROFILE_PATH}/${LIBRARY_PATH}`;
export const FIND_SPOTIFY_EMAIL = `${HELP_PATH}/find_spotify_email`;
export const TERMS_AND_CONDITIONS_PATH = '/terms_and_condtions';
export const PRIVACY_POLICY_PATH = '/privacy_policy';
export const CONTACT_US_PATH = '/contact_us';
export const GUEST_DASHBOARD_PATH = `/${GUEST_PATH}`;
export const HOST_DASHBOARD_PATH = `/${HOST_PATH}`;
export const CUSTOMIZE_PLAYLIST = `/${HOST_PATH}/customize`;
export const HOST_STATISTICS = `/${HOST_PATH}/stats`;
export const BLACKLIST = `/${HOST_PATH}/${SETTINGS_PATH}/blacklist`;
export const DEFAULT_PLAYLIST = `/${HOST_PATH}/${SETTINGS_PATH}/defaults`;
export const SCHEDULE = `/${HOST_PATH}/${SETTINGS_PATH}/schedule`;
export const USER_TIME = `/${HOST_PATH}/${SETTINGS_PATH}/limits`;

const required = 'This field is required';
const passwordValidationTexts = {
  minLength: 'Password must be at least 6 characters long',
  validate: 'Passwords do not match',
  required,
};
export const errorTypeTexts = {
  name: {
    pattern: 'Name must consist of letters only',
    required,
  },
  email: {
    pattern: 'Please enter valid email address',
    required,
  },
  yob: {
    min: 'You must be at least 16 years old',
    required,
  },
  password: passwordValidationTexts,
  'password-confirm': passwordValidationTexts,
};

export const MIN_AGE = 13;
export const MAX_AGE = 100;

export const VERY_SMALL_SCREEN_SIZE = '360px';
export const SMALL_SCREEN_SIZE = '640px';
export const MEDIUM_SCREEN_SIZE = '1000px';

export const GENRES_LIST = {
  rock: { label: 'Rock', value: 'rock' },
  folk: { label: 'Folk', value: 'folk' },
  pop: { label: 'Pop', value: 'pop' },
  country: { label: 'Country', value: 'country' },
  blues: { label: 'Blues', value: 'blues' },
  latin: { label: 'Latin', value: 'latin' },
  chill: { label: 'Chill', value: 'chill' },
  disco: { label: 'Disco', value: 'disco' },
  jazz: { label: 'Jazz', value: 'jazz' },
  'r&b': { label: 'R&B', value: 'r&b' },
  'hip-hop': { label: 'Hip-Hop', value: 'hip-hop' },
  rap: { label: 'Rap', value: 'rap' },
  soul: { label: 'Soul', value: 'soul' },
  funk: { label: 'Funk', value: 'funk' },
  metal: { label: 'Metal', value: 'metal' },
  punk: { label: 'Punk', value: 'punk' },
  gospel: { label: 'Gospel', value: 'gospel' },
  trance: { label: 'Trance', value: 'trance' },
  edm: { label: 'EDM', value: 'edm' },
  house: { label: 'House', value: 'house' },
  glitch: { label: 'Glitch', value: 'glitch' },
  bass: { label: 'Bass', value: 'bass' },
  'drum & bass': { label: 'Drum & Bass', value: 'drum & bass' },
  dubstep: { label: 'Dubstep', value: 'dubstep' },
  garage: { label: 'Garage', value: 'garage' },
  Electronica: { label: 'Electronica', value: 'electronica' },
  'hi-tech': { label: 'Hi-Tech', value: 'hi-tech' },
  techno: { label: 'Techno', value: 'techno' },
  djent: { label: 'Djent', value: 'djent' },
  world: { label: 'World', value: 'world' },
  raggaeton: { label: 'Raggaeton', value: 'raggaeton' },
  Raggae: { label: 'Reggae', value: 'raggae' },
  'lo-fi': { label: 'Lo-Fi', value: 'lo-fi' },
  salsa: { label: 'Salsa', value: 'salsa' },
  samba: { label: 'Samba', value: 'samba' },
  ska: { label: 'Ska', value: 'ska' },
  swing: { label: 'Swing', value: 'swing' },
  bachata: { label: 'Bachata', value: 'bachata' },
  motown: { label: 'Motown', value: 'motown' },
};

export const VENUE_SIZES = [
  {
    value: 50,
    label: 'Less than 50',
  },
  {
    value: 100,
    label: '50-100',
  },
  {
    value: 500,
    label: '100-500',
  },
  {
    value: 2000,
    label: '500-2000',
  },
  {
    value: 5000,
    label: '2000-5000',
  },
  {
    value: '5000+',
    label: 'More than 5000',
  },
];

export const VENUE_TYPES = [
  {
    value: 'bar',
    label: 'Bar/Pub',
  },
  {
    value: 'restaurant',
    label: 'Restaurant',
  },
  {
    value: 'gym',
    label: 'Gym',
  },
  {
    value: 'hotel',
    label: 'Hotel',
  },
  {
    value: 'office',
    label: 'Office',
  },
  {
    value: 'clinic',
    label: 'Clinic',
  },
];

const yobGenerator = () => {
  const ageList = [];
  const currentYear = new Date().getFullYear();
  for (let i = MIN_AGE; i <= MAX_AGE; i += 1) {
    ageList.push({ value: (currentYear - i).toString(), label: currentYear - i }); // value: DOB, label: age
  }
  return ageList;
};

export const YOB_LIST = yobGenerator();

export const GENDERS = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

export const ARE_YOU_SURE_MESSAGE = 'Are you sure you want to';
export const DEFAULT_ERROR_MESSAGE_TITLE = 'Oops, something went wrong';
export const DEFAULT_ERROR_MESSAGE = 'Looks like something went wrong on our end, please try again.';

// Google Analytics categories
export const GA_GUEST_HOME_PAGE = 'GuestHomePage';
export const GA_GUEST_REGISTRATION = 'GuestRegistration';
export const GA_HOST_REGISTRATION = 'HostRegistration';
export const GA_GUEST_PLAYLIST = 'GuestPlaylistPage';
export const GA_VOTE_SONG = 'VoteSong';
export const GA_MUSIC_LIBRARY = 'MusicLibrary';
export const GA_EDIT_PROFILE = 'EditProfile';
export const GA_HOST_PLAYLIST_CUSTOMIZATION = 'HostPlaylistCustomization';
export const GA_HOST_ADD_SONG = 'HostAddSongModal';

// Google Analytics activities
export const GA_GUEST_ACTIVITY = 'Guest';
export const GA_HOST_ACTIVITY = 'Host';
export const GA_MANUAL_PREFERENCES_ACTIVITY = 'ManualPreferences';
export const GA_SPOTIFY_PREFERENCES_ACTIVITY = 'SpotifyPreferences';
export const GA_REGISTERED_SUCCESSFULLY_ACTIVITY = 'RegisteredSuccessfully';
export const GA_LIKED_SONG_ACTIVITY = 'LikedSong';
export const GA_BLACKLIST_SONG_ACTIVITY = 'BlacklistSong';
export const GA_REMOVE_SONG_ACTIVITY = 'RemoveSong';
export const GA_REQUEST_SONG_CLICKED_ACTIVITY = 'RequestSongClicked';
export const GA_REQUEST_SONG_SUCCESSFULLY_ACTIVITY = 'RequestSongSuccessfully';
export const GA_VOTE_SONG_CLICKED_ACTIVITY = 'VoteSongClicked';
export const GA_VOTE_SONG_SUCCESSFULLY_ACTIVITY = 'VoteSongSuccessfully';
export const GA_OPENED_STARRED_SONG_ON_SPOTIFY_ACTIVITY = 'OpenedSongOnSpotify';
export const GA_VOLUME_COMPLAINT_CLICKED_ACTIVITY = 'VolumeComplaintClicked';
export const GA_VOLUME_COMPLAINT_SUCCESSFULLY_ACTIVITY = 'VolumeComplaintSuccessfully';
export const GA_PLAYLIST_RATED_ACTIVITY = 'PlaylistRatedSuccessfully';
export const GA_USER_LEFT_PLAYLIST_ACTIVITY = 'LeavePlaylistRequested';
export const GA_USER_UNLINKED_SPOTIFY = 'UserUnlinkSpotifyAccount';
export const GA_HOST_LOADED_PLAYLIST_ACTIVITY = 'HostLoadedPlaylist';
export const GA_HOST_ADDED_SONG_ACTIVITY = 'HostAddedSong';

// Playlist update socket events
export const SONG_ADDED_EVENT = 'SONG_ADDED';
export const SONG_REMOVED_EVENT = 'SONG_REMOVED';
export const NEW_PLAYLIST_EVENT = 'NEW_PLAYLIST';

// Notification severities
export const NOTIFICATION_SEVERITY_SUCCESS = 'success';
export const NOTIFICATION_SEVERITY_INFO = 'info';
export const NOTIFICATION_SEVERITY_ERROR = 'error';
