import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';

import SongCard from './SongCard';

import useGAEventTracker from '../../../../hooks/useGAEventTracker';
import { updateUserLikedSongs } from '../../../../store/slices/userReducer';
import { isEmptyObject } from '../../../common/misc';
import { GA_GUEST_PLAYLIST, GA_LIKED_SONG_ACTIVITY } from '../../../common/constants';
import { setLikeSong, getProfile } from '../../../../services/api';

const SongCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 90%;
`;

function PlaylistSongs({ currentSong = {}, prevSong = {}, onSuccess = () => {}, onFailure = () => {} }) {
  const dispatch = useDispatch();
  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);

  const handleToggleLikedSong = async (songId) => {
    if (!songId) {
      return;
    }
    
    try {
      const result = await setLikeSong(songId);
      if (result.success === 0) {
        onFailure('Cannot complete like request. Please try again later');
      }
    } catch (error) {
      onFailure(error.message);
    }

    try {
      const profile = getProfile();

      const likedSongsIds = profile?.liked_songs?.map((song) => song.id);
      dispatch(updateUserLikedSongs(likedSongsIds));
      gaEventTracker(GA_LIKED_SONG_ACTIVITY);
      onSuccess();
    } catch (error) {
      onFailure("Failed updating liked songs list. Please try again later.");
    }
  };

  const renderSongCardOptions = (songId, likedSong) => (
    <IconButton
      sx={{ color: 'white', maxWidth: '20%', margin: '5px' }}
      onClick={() => handleToggleLikedSong(songId)}
    >
      {likedSong ? (
        <StarIcon style={{ width: '32px', height: '32px' }} />
      ) : (
        <StarBorderOutlinedIcon style={{ width: '32px', height: '32px' }} />
      )}
    </IconButton>
  );

  return (
    <SongCardContainer>
      <SongCard
        playing
        song={currentSong?.song}
        label="Now Playing"
        style={{ topBorder: true }}
        options={renderSongCardOptions(currentSong?.song?.id, currentSong?.isLiked)}
      />
      {!isEmptyObject(prevSong) ? (
        <SongCard
          song={prevSong?.song}
          label="Previous Song"
          options={renderSongCardOptions(prevSong?.song?.id, prevSong?.isLiked)}
        />
      ) : null}
    </SongCardContainer>
  )
}

PlaylistSongs.propTypes = {
  currentSong: PropTypes.shape({}),
  prevSong: PropTypes.shape({}),
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
}

export default PlaylistSongs
