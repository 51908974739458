import ReactGA from 'react-ga4';

const useGAEventTracker = (category) => {
  const eventTracker = (action, label, value) => {
    ReactGA.event({ category, action, label, value });
  };
  return eventTracker;
};

export default useGAEventTracker;
