/* eslint no-unused-vars: off */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import useGAEventTracker from '../../hooks/useGAEventTracker';

import { SMALL_SCREEN_SIZE,
  REGISTER_GUEST_PATH,
  REGISTER_HOST_PATH,
  GA_GUEST_HOME_PAGE,
  GA_GUEST_ACTIVITY,
  GA_HOST_ACTIVITY,
} from './constants';
import theme from './theme';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
  }
`;

function UserTypeButtons() {
  const gaEventTracker = useGAEventTracker(GA_GUEST_HOME_PAGE);
  return (
    <Container>
      <Button
        onClick={() => gaEventTracker(GA_GUEST_ACTIVITY)}
        component={Link}
        variant="contained"
        type="button"
        to={REGISTER_GUEST_PATH}
        sx={{
          margin: '20px 0',
          width: '300px',
          height: '55px',
          fontSize: '22px',
          backgroundImage: theme.palette.juxy.gradient,
          borderRadius: '50px',
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: '#8c8c8c !important',
            },
          },
        }}
      >
        Join the party
      </Button>
    </Container>
  );
}

export default UserTypeButtons;
