import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@mui/material';
import styled from 'styled-components';

import ButtonGroup from '@mui/material/ButtonGroup';
import IconButton from '@mui/material/IconButton';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';

import useSocket from '../../../hooks/useSocket';
import SongProgressBar from '../../Guests/SongProgressBar';
import {
  play,
  pause,
  requestNextSong,
  requestPrevSong,
} from '../../../services/api';
import theme from '../../common/theme';
import { SMALL_SCREEN_SIZE } from '../../common/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    flex-direction: row-reverse;
  }
`;

const Controls = styled(ButtonGroup)({
  gap: '10px',

  '& .MuiButtonBase-root': {
    color: theme.palette.juxy.secondary,
    padding: 0,
    '&:hover': {
      background: 'none',
    },
  },

  '& .MuiSvgIcon-root': {
    fontSize: '32px',
  },
});

function MusicControls({ onError = () => {} }) {
  const isDesktop = useMediaQuery(`(min-width: ${SMALL_SCREEN_SIZE})`);
  const [isPlaying, setIsPlaying] = useState(false);
  const [songDuration, setSongDuration] = useState(140);
  const [songProgress, setSongProgress] = useState(1);

  const togglePlayerState = async () => {
    try {
      if (isPlaying) {
        await pause();
      } else {
        await play();
      }
      setIsPlaying((prev) => !prev);
    } catch (error) {
      onError(error.message);
    }
  };

  const handleNextSongRequest = async () => {
    try {
      await requestNextSong();
    } catch (error) {
      onError(error.message);
    }
  };

  const handlePrevSongRequest = async () => {
    try {
      await requestPrevSong();
    } catch (error) {
      onError(error.message);
    }
  };

  const handleNewSong = ({ song }) => {
    setSongDuration((+song.duration / 1000));
    setSongProgress((+song.progress / 1000));
  };

  const handleSongUpdate = ({ progress }) => {
    setSongProgress((+progress / 1000));
  };

  useSocket(undefined, { new_song: handleNewSong, update_song: handleSongUpdate });

  return (
    <Container>
      <Controls>
        {isDesktop && (
          <IconButton aria-label="Previous" onClick={handlePrevSongRequest}>
            <SkipPreviousOutlinedIcon />
          </IconButton>
        )}
        <IconButton aria-label="Play" onClick={togglePlayerState}>
          { isPlaying ? 
            <PauseOutlinedIcon style={{ fontSize: '48px' }} /> :
            <PlayArrowOutlinedIcon style={{ fontSize: '48px' }} /> 
          }
        </IconButton>
        <IconButton aria-label="Next" onClick={handleNextSongRequest}>
          <SkipNextOutlinedIcon />
        </IconButton>
      </Controls>
      {isDesktop && (
        <SongProgressBar songDuration={songDuration} progress={songProgress} playing={isPlaying} />
      )}
    </Container>
  );
}

MusicControls.propTypes = {
  onError: PropTypes.func,
};

export default MusicControls;
