/* eslint react/no-unescaped-entities: "off" */
import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';

import ControlledTextField from '../../common/ControlledInputs/ControlledTextField';
import Alert from '../../common/Alert';
import { EMAIL_PATTERN, HOME_PATH, NAME, SMALL_SCREEN_SIZE } from '../../common/constants';
import theme from '../../common/theme';
import { submitButtonSx, backButtonSx } from '../../common/xsStyles';
import { requestPasswordResetEmail } from '../../../services/api';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 450px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    padding: 0 30px;
  }
`;

const HeaderWithBackButton = styled.div`
  display: flex;
  align-self: flex-start;
  font-size: 40px;
  padding-top: 20px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin: 10px 0px;
`;

const Text = styled.p`
  margin: 0;
`;

const IconButtonLabel = styled.div`
  display: flex;
  align-items: center;
`;

const backIconButtonSx = {
  height: '50px',
  minWidth: '300px',
  color: '#FFFFFF',
  fontSize: '16px',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '16px',
  },
};

const arrowIconSx = {
  marginRight: '10px',
};

function ForgotPasswordForm() {
  const [isEmailSentSuccessfully, setIsEmailSentSuccessfully] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { control, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  const handleOnClickBack = () => { navigate(-1); };
  const onSubmit = (data) => {
    requestPasswordResetEmail(data.email)
      .then(({ result, message }) => {
        if (result === 0) {
          setErrorMessage(message);
        }
        setIsEmailSentSuccessfully(!!result);
      });
  };

  return (
    <Container>
      <HeaderWithBackButton>
        <Typography variant="h4" gutterBottom>
          Forgot Password
        </Typography>
      </HeaderWithBackButton>
      { isEmailSentSuccessfully ? (
        <>
          <TextContainer style={{ textAlign: 'center' }}>
            <Text>Email with a reset link was sent to your inbox</Text>
          </TextContainer>
          <Button
            variant="contained"
            sx={submitButtonSx}
            onClick={() => navigate(HOME_PATH)}
          >
            Done
          </Button>
        </>
      ) : (
        <>
          <TextContainer>
            <Text>Forgot your password? Don't worry, we got you.</Text>
            <Text>{`Enter your ${NAME} user email address, and we will send you a link to reset your password`}</Text>
          </TextContainer>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <ControlledTextField
              name="email"
              label="Email"
              rules={{ required: true, pattern: EMAIL_PATTERN }}
              errors={errors}
              control={control}
            />
            <Alert isDisplayed={!!errorMessage} message={errorMessage} onClick={() => setErrorMessage('')} />
            <Button
              type="submit"
              variant="contained"
              sx={submitButtonSx}
            >
              Continue
            </Button>
            <IconButton sx={backButtonSx} onClick={handleOnClickBack}>
              <IconButtonLabel>
                <ArrowBackIcon sx={arrowIconSx} />
                <Typography variant="body2">Back</Typography>
              </IconButtonLabel>
            </IconButton>
          </Form>
        </>
      )}
    </Container>
  );
}

export default ForgotPasswordForm;
