import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

const Container = styled.div`
    display: flex;
    justify-content: center;
    gap: 15px;
`;

function DeleteUserDialog({ onCancel, onDelete }) {
  // TODO: Update design
  return (
    <Container>
      <Button variant="contained" color="error" sx={{ margin: '25px 0' }} onClick={() => onCancel()}>CANCEL</Button>
      <Button variant="contained" color="secondary" sx={{ margin: '25px 0' }} onClick={() => onDelete()}>DELETE</Button>
    </Container>
  );
}

DeleteUserDialog.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
};

DeleteUserDialog.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
};

export default DeleteUserDialog;
