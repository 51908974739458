import { createSlice } from '@reduxjs/toolkit';
import { logout as requestLogout } from '../../services/api';

import { USER_TYPE_HOST } from '../../components/common/constants';

const initialState = {
  isLoggedIn: false,
  verified: false,
  role: undefined, // User or Host
  type: undefined, // manual or connected with streaming service
  id: undefined,
  email: undefined,
  display_name: undefined,
  gender: undefined,
  yob: undefined,
  image: undefined,
  likedSongs: [],
  venues: [],
};

export const userReducer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => ({ ...action.payload }),
    logout: () => {
      requestLogout();
      return initialState;
    },
    verifyUser: (state, action) => ({ ...state, verified: action.payload }),
    updateUserLikedSongs: (state, action) => ({ ...state, likedSongs: action.payload }),
    addLikedSong: (state, action) => ({ ...state, likedSongs: [...state.likedSongs, action.payload] }),
    removeLikedSong: (state, action) => ({ ...state, likedSongs: state.likedSongs.filter((s) => s.id != action.payload.id) }),
    updateProfile: (state, action) => ({ ...state, ...action.payload }),
  },
});

export const {
  login,
  logout,
  verifyUser,
  updateUserLikedSongs,
  addLikedSong,
  removeLikedSong,
  updateProfile,
} = userReducer.actions;

// Selectors
export const getUser = (state) => state.user;
export const getUserEmail = (state) => getUser(state).email;
export const getUserId = (state) => getUser(state).id;
export const getUserName = (state) => getUser(state).displayName;
export const getUserType = (state) => getUser(state).type;
export const getUserRole = (state) => getUser(state).role;
export const getUserGender = (state) => getUser(state).gender;
export const getUserYearOfBirth = (state) => getUser(state).yob;
export const getLikedSongs = (state) => getUser(state).likedSongs;
export const getUserVolumeComplaint = (state) => getUser(state).complaint;
export const isUserVerified = (state) => getUser(state).verified;
export const isUserLoggedIn = (state) => getUser(state).isLoggedIn;
export const isGuestUser = (state) => getUser(state).role !== USER_TYPE_HOST;
export const isHostUser = (state) => getUser(state).role === USER_TYPE_HOST;

export default userReducer.reducer;
