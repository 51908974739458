import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getUserRole, isUserLoggedIn } from '../../../store/slices/userReducer';
import {
  GUEST_LOGIN_PATH,
  HOST_LOGIN_PATH,
  USER_TYPE_GUEST,
  USER_TYPE_HOST,
} from '../constants';

function ProtectedRoute({ children, allowedUserType }) {
  const isLoggedIn = useSelector(isUserLoggedIn);
  const role = useSelector(getUserRole);
  const fallbackPath = role === USER_TYPE_HOST ? HOST_LOGIN_PATH : GUEST_LOGIN_PATH;

  if (!isLoggedIn || (allowedUserType && role !== allowedUserType)) {
    return <Navigate to={fallbackPath} />;
  }

  return children;
}

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  allowedUserType: PropTypes.oneOf([USER_TYPE_GUEST, USER_TYPE_HOST, undefined]),
};

export default ProtectedRoute;
