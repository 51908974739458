import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin: 30px 0;
`;

function ContactUs() {
  return (
    <Container>
      <Title>Contact us</Title>
      <div>
        Your feedback is important to us!
      </div>
      <div>
        Contact us at:
      </div>
      <div>
        juxyhelp@gmail.com
      </div>
    </Container>
  );
}

export default ContactUs;
