/* eslint no-param-reassign: "off", no-underscore-dangle: "off" */
import axios from 'axios';

export const API_BASE_URL = `${process.env.REACT_APP_SERVER_ADDR}/api`;

const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
});

export const getProfile = () =>
  api
    .get('/api/profile')
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err.message);
    });

export const isHostConnected = async () =>
  api
    .get('/api/is_host_connected')
    .then((res) => res.data.result)
    .catch((err) => console.log(err)); // TODO: handle error

export const guestLogin = async (credentials) => {
  const error = {
    result: 0,
    msg: 'Incorrect email or password',
  };

  return api
    .post('/api/login', credentials)
    .then((res) => {
      if (res.data.result === 0) {
        throw new Error();
      }
      return res.data;
    })
    .catch(() => error);
};

export const hostLogin = async (hostCredentials) => {
  const error = {
    result: 0,
    msg: 'Incorrect email or password',
  };

  try {
    const { data } = await api.post('business/login', hostCredentials);
    if (!!!data.result) {
      throw new Error(data.msg);
    }
    return data;
  } catch (err) {
    error.msg = err.message;
    return error;
  }
};

export const logout = async () =>
  api
    .post('/api/logout')
    .then((res) => res)
    .catch((err) => console.log(err.message)); // TODO: decide what to do if backend returns an error

export const register = (data) =>
  api.post('/api/register', data).then((res) => {
    if (res.data.result === 0) {
      throw new Error(res.msg);
    }
    return res.data;
  });

export const isRegistered = (email) =>
  api
    .get('/api/is_registered', { params: { email } })
    .then((res) => res.data.result)
    .catch((err) => err.message);

export const updateUserProfile = (data) =>
  api
    .post('/api/update', data)
    .then((res) => {
      if (res.data.result === 0) {
        return res.data;
      }
      return getProfile();
    })
    .catch((err) => err);

export const deleteUserProfile = (userProfile) =>
  api
    .post('/api/delete', userProfile)
    .then((res) => {
      if (res.data.result === 0) {
        throw new Error(res.message);
      }
    })
    .catch((err) => err); // TODO: decide on proper action if BE returns failure message

export const updateUserPassword = (token, password) =>
  api
    .post('/api/update_password', { token, password })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err.message);
    });

export const requestPasswordResetEmail = (email) =>
  api
    .post('/api/forgot_password', { email })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err.message);
    });

export const validateResetToken = (token) =>
  api
    .post('/api/validate_token', { token })
    .then((res) => res.data)
    .catch((err) => {
      console.error(err.message);
    });

export const setManualPreferences = (genres) =>
  api
    .post('/api/manual', { genres })
    .then((res) => {
      if (res.data.result === 0) {
        throw new Error(res.data.msg);
      }
      return res.data;
    })
    .catch((err) => err); // TODO: decide on proper action

export const getCurrentSong = () =>
  api
    .get('/api/cur_song')
    .then((res) => {
      if (res.data.result === 0) {
        return undefined;
      }
      return res.data;
    })
    .catch(() => undefined);

export const getPrevSong = () =>
  api
    .get('/api/prev_song')
    .then((res) => {
      if (res.data.result === 0) {
        return undefined;
      }
      return res.data;
    })
    .catch(() => undefined);

export const getNextSong = () =>
  api
    .get('/api/next_song')
    .then((res) => {
      if (res.data.result === 0) {
        return undefined;
      }
      return res.data;
    })
    .catch(() => undefined);

export const setLikeSong = (id) =>
  api
    .post('/api/like_song', { id })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Operation failed, please try again later');
    });

export const blacklistSong = (id) =>
  api
    .post('/business/blacklist_song', { id })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Operation failed, please try again later');
    });

export const removeSong = (id) =>
  api
    .delete('/business/remove_song', { data: { id } })
    .then((res) => res.data)
    .catch(() => {
      throw new Error('Operation failed, please try again later');
    });

export const getActiveVenues = () =>
  api
    .get('/api/active_venues')
    .then((res) => res.data)
    .catch(() => []);

export const getPlaylistSongs = () =>
  Promise.all([getPrevSong(), getCurrentSong(), getNextSong()])
    .then((values) => values)
    .catch(() => {
      throw new Error('Failed getting playlist songs');
    });

export const getVenueCurrentPlaylist = (venueId, count = 10) =>
  api
    .get('/api/venue_playlist', { params: { count, venueId } })
    .then((res) => (res?.data?.result ? res.data.playlist : []))
    .catch(() => []);

export const getVenueSavedPlaylists = () =>
  api
    .get('/business/venue_playlists')
    .then((res) => (res?.data?.result ? res.data.playlists : []))
    .catch(() => []);

export const loadPlaylist = (playlistURL) =>
  api
    .post('/business/load_playlist', { playlist: playlistURL })
    .then((res) => (res?.data?.result ? res.data.playlist : new Error(res.data.msg)));

export const getIsSiteEnabled = () =>
  api
    .get('/is_site_enabled')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const getIsRequestsEnabled = () =>
  api
    .get('/is_requests_enabled')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const getRequestSongsList = (query) =>
  api
    .get('/api/request_song', { params: { q: query } })
    .then((res) => {
      if (res?.data?.result === 0) {
        return [];
      }

      res.data?.songs.forEach((song) => {
        song.value = song._id;
        song.label = `${song.songName} - ${song.artists[0].name}`;
      });

      return res.data?.songs;
    })
    .catch(() => undefined);

export const requestSong = (song) =>
  api
    .post('/api/request_song', { song })
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const getIsVotingEnabled = () =>
  api
    .get('/is_votes_enabled')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const getVoteData = () =>
  api
    .get('/api/vote')
    .then((data) => {
      if (data?.data?.enabled === false || data?.data?.result === 0) {
        return undefined;
      }

      const { score, candidates, voted } = data.data;
      const firstSongData = {
        id: candidates[0]?._id,
        artist: candidates[0]?.artists[0]?.name,
        name: candidates[0]?.songName,
        image: candidates[0]?.imageURL,
        votePercentage: score[0],
      };
      const secondSongData = {
        id: candidates[1]?._id,
        artist: candidates[1]?.artists[0]?.name,
        name: candidates[1]?.songName,
        image: candidates[1]?.imageURL,
        votePercentage: score[1],
      };
      return { firstSongData, secondSongData, voted };
    })
    .catch(() => undefined);

export const voteSong = (index) =>
  api
    .post('/api/vote', { index })
    .then((score) => {
      if (score?.data?.result === 0) {
        throw new Error();
      }
      return score?.data;
    })
    .catch(() => undefined);

export const canRequestSong = () =>
  api
    .get('/api/can_request')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const getIsVolumeComplaintEnabled = () =>
  api
    .get('/is_voice_complaint_enabled')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const complainVolume = (complaint) =>
  api
    .post('/api/volume_complaint', { complaint })
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const unlinkSpotifyAccount = () =>
  api
    .post('api/unlink_spotify')
    .then((res) => res?.data?.result)
    .catch(() => undefined);

export const play = () =>
  api.put('/api/play').then(({ data }) => {
    if (!!data?.result) {
      return data.result;
    } else {
      throw new Error(data?.message);
    }
  });

export const pause = () =>
  api.put('/api/pause').then(({ data }) => {
    if (!!data?.result) {
      return data.result;
    } else {
      throw new Error(data?.message);
    }
  });

export const requestNextSong = () =>
  api.put('/api/forward').then(({ data }) => {
    if (!!data?.result) {
      return data?.result;
    } else {
      throw new Error(data?.message);
    }
  });

export const requestPrevSong = () =>
  api.put('/api/backwards').then(({ data }) => {
    if (!!data?.result) {
      return data?.result;
    } else {
      throw new Error(data?.message);
    }
  });

export const setVolume = (requestedVolume) =>
  api.put('/api/set_volume', { volume: requestedVolume }).then(({ data }) => {
    if (!!data?.result) {
      return data?.volume;
    } else {
      throw new Error(data?.message);
    }
  });

export const addGuestToVenue = async (venueId) => {
  try {
    const { data } = await api.post('/api/connect_user_to_venue', { venue: venueId });
    return data;
  } catch (error) {
    new Error('Something went wrong. Please try again.');
  }
};
