/* eslint no-unsafe-optional-chaining: "off", no-use-before-define: "off", no-unused-vars: "off" */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import StarIcon from '@mui/icons-material/Star';

import SongCard from './SongCard';
import NextSongPoll from './SongsPoll/NextSongPoll';
import RequestSong from './RequestSong/RequestSong';
import VolumeComplaintPoll from './VolumeComplaint/VolumeComplaintPoll';
import PlaylistRating from './PlaylistRating/PlaylistRating';
import JuxySpinner from '../common/JuxySpinner';

import {
  getCurrentSong,
  getPlaylistSongs,
  getIsRequestsEnabled,
  getIsVotingEnabled,
  setLikeSong,
  getProfile,
  canRequestSong,
  getIsVolumeComplaintEnabled,
} from '../../services/api';
import { updateUserLikedSongs } from '../../store/slices/userReducer';
import {
  NAME,
  GA_GUEST_PLAYLIST,
  GA_LIKED_SONG_ACTIVITY,
  GA_REQUEST_SONG_CLICKED_ACTIVITY,
  GA_REQUEST_SONG_SUCCESSFULLY_ACTIVITY,
  GA_VOTE_SONG_CLICKED_ACTIVITY,
  GA_VOLUME_COMPLAINT_CLICKED_ACTIVITY,
  GA_VOLUME_COMPLAINT_SUCCESSFULLY_ACTIVITY,
} from '../common/constants';
import Alert from '../common/Alert';
import usePrevious from '../../hooks/usePrevious';
import useGAEventTracker from '../../hooks/useGAEventTracker';

const CtaStyle = {
  backgroundColor: 'transparent',
  border: '1px white solid',
  borderRadius: '50px',
  height: '50px',
  width: '80%',
  maxWidth: '320px',
  fontSize: '16px',
  margin: '6px 0',
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: '#8c8c8c !important',
    },
  },
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 20px 0;
    gap: 20px;
    padding-top: 8px;
`;

const SongCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 90%;
`;

const RatingButton = styled(Button)({
  color: 'white',
  textDecoration: 'underline',
  textTransform: 'none',
  fontSize: '18px',
  fontWeight: 'normal',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'none',
  },
});

function Playlist() {
  const dispatch = useDispatch();

  const [displayAlert, setDisplayAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [displayRequestSong, setDisplayRequestSong] = useState(true);
  const [displayVoteSong, setDisplayVoteSong] = useState(true);
  const [displayVolumeComplaint, setDisplayVolumeComplaint] = useState(false);
  const [disabledRequestSong, setDisabledRequestSong] = useState(false);
  const [playlistName, setPlaylistName] = useState(`${NAME}'s Playlist`);
  const [prevSong, setPrevSong] = useState(undefined);
  const [currentSong, setCurrentSong] = useState(undefined);
  const [openRequestSong, setOpenRequestSong] = useState(false);
  const [openRequestSongSuccessfullyBar, setOpenRequestSongSuccessfullyBar] = useState(false);
  const [openSongPoll, setOpenSongPoll] = useState(false);
  const [openVolumeComplaintPoll, setOpenVolumeComplaintPoll] = useState(false);
  const [openRatePlaylist, setOpenRatePlaylist] = useState(false);

  const gaEventTracker = useGAEventTracker(GA_GUEST_PLAYLIST);
  const prevCurrentSongValue = usePrevious(currentSong);

  useEffect(() => {
    Promise.all([getIsRequestsEnabled(), getIsVotingEnabled(), getIsVolumeComplaintEnabled()])
      .then((value) => {
        const [isRequestsEnabled, isVotingEnabled, isVolumeComplaintEnabled] = value;
        setDisplayRequestSong(isRequestsEnabled);
        setDisplayVoteSong(isVotingEnabled);
        setDisplayVolumeComplaint(isVolumeComplaintEnabled);
      });
    // TODO: get Playlist data based on playlistId...
  }, []);

  useEffect(() => {
    canRequestSong()
      .then((value) => { setDisabledRequestSong(!value); });
  }, []);

  useEffect(() => {
    if (!!prevCurrentSongValue && prevCurrentSongValue?.song?.id !== currentSong?.song?.id && prevSong?.song?.id !== prevCurrentSongValue?.song?.id) {
      setPrevSong(prevCurrentSongValue);
    }
  }, [currentSong, prevCurrentSongValue]);

  useEffect(() => {
    getPlaylistSongs()
      .then((songs) => {
        const [prev, curr] = songs;
        if (curr) {
          setCurrentSong({ song: curr.song, isLiked: curr.isLiked });
        }
        if (prev) {
          setPrevSong({ song: prev.song, isLiked: prev.isLiked });
        }
      })
      .catch((err) => handleFailure(err.message)); // TODO: handle error appropriately
  }, []);

  useEffect(() => {
    let retries = 0;
    const timer = setInterval(() => {
      getCurrentSong()
        .then((res) => {
          if (!res) {
            return;
          }
          const { song, isLiked } = res;
          if (song) {
            retries = 0;
            handleIsNewSong({ song, isLiked });
          } else if (retries < 3) {
            retries += 1;
          } else {
            setCurrentSong(undefined);
          }
        });
    }, 10000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleIsNewSong = (nextSongMeta) => {
    if (!currentSong || currentSong?.id !== nextSongMeta.song.id) {
      setCurrentSong(nextSongMeta);
    }
  };

  const handleToggleLikedSong = (songId) => {
    if (!songId) {
      return;
    }
    setLikeSong(songId)
      .then((res) => {
        if (res.success === 0) {
          throw new Error('Cannot complete like request. Please try again later');
        }
        // request both songs instead of checking which was liked and get only it, as we're sending a request anyway.
        return getPlaylistSongs();
      })
      .then((songs) => {
        const [prev, current] = songs;
        setCurrentSong({ isLiked: current?.isLiked, song: current?.song });
        if (prevSong) {
          setPrevSong({ isLiked: prev?.isLiked, song: prev?.song });
        }
        // update user's liked songs list in store
        return getProfile(); // TODO: consider implementing /get_liked_songs api endpoint
      })
      .then((profile) => {
        dispatch(updateUserLikedSongs(profile.like_songs));
        gaEventTracker(GA_LIKED_SONG_ACTIVITY);
      })
      .catch((err) => handleFailure(err.message)); // TODO: handle like unsuccessful.
  };

  const handleOpenRequestSongOnClick = () => {
    setOpenRequestSong(true);
    gaEventTracker(GA_REQUEST_SONG_CLICKED_ACTIVITY);
  };

  const handleRequestSongOnClose = () => {
    setOpenRequestSong(false);
  };

  const handleRequestSongSuccessfully = () => {
    handleRequestSongOnClose();
    setOpenRequestSongSuccessfullyBar(true);
    gaEventTracker(GA_REQUEST_SONG_SUCCESSFULLY_ACTIVITY);
  };

  const handleVolumeComplaintPollSuccessfully = () => {
    setOpenRequestSongSuccessfullyBar(true);
    gaEventTracker(GA_VOLUME_COMPLAINT_SUCCESSFULLY_ACTIVITY);
  };

  const handleRequestSongBarOnClose = () => {
    setOpenRequestSongSuccessfullyBar(false);
  };

  const handleOpenVoteSongClick = () => {
    setOpenSongPoll(true);
    gaEventTracker(GA_VOTE_SONG_CLICKED_ACTIVITY);
  };

  const handleVolumeComplaintClick = () => {
    setOpenVolumeComplaintPoll(true);
    gaEventTracker(GA_VOLUME_COMPLAINT_CLICKED_ACTIVITY);
  };

  const handleFailure = (message) => {
    setAlertMessage(message);
    setDisplayAlert(true);
  };

  const renderSongCardOptions = (songId, likedSong) => (
    <IconButton
      sx={{ color: 'white', maxWidth: '20%', margin: '5px' }}
      onClick={() => handleToggleLikedSong(songId)}
    >
      {likedSong
        ? <StarIcon style={{ width: '32px', height: '32px' }} />
        : <StarBorderOutlinedIcon style={{ width: '32px', height: '32px' }} />}
    </IconButton>
  );

  if (!currentSong) {
    return (
      <Container>
        <JuxySpinner />
      </Container>
    );
  }

  return (
    <Container>
      <h2>{playlistName}</h2>
      <SongCardContainer>
        <SongCard
          playing
          song={currentSong?.song}
          label="Now Playing"
          style={{ topBorder: true }}
          options={renderSongCardOptions(currentSong?.song?.id, currentSong?.isLiked)}
        />
        {prevSong ? (
          <SongCard
            song={prevSong?.song}
            label="Previous Song"
            options={renderSongCardOptions(prevSong?.song?.id, prevSong?.isLiked)}
          />
        ) : null}
      </SongCardContainer>
      {displayVoteSong && (
        <>
          <Button
            variant="contained"
            size="large"
            sx={CtaStyle}
            onClick={handleOpenVoteSongClick}
          >
            Vote Next Song
          </Button>
          <NextSongPoll
            open={openSongPoll}
            onClose={() => setOpenSongPoll(false)}
          />
        </>
      )}
      {displayRequestSong && !disabledRequestSong && (
        <>
          <Button
            variant="contained"
            size="large"
            sx={CtaStyle}
            onClick={handleOpenRequestSongOnClick}
          >
            Request a Song
          </Button>
          <RequestSong
            open={openRequestSong}
            onClose={handleRequestSongOnClose}
            successfulCallback={handleRequestSongSuccessfully}
          />
        </>
      )}
      {displayVolumeComplaint && (
        <>
          <Button
            variant="contained"
            size="large"
            sx={CtaStyle}
            onClick={handleVolumeComplaintClick}
          >
            Volume Complaint
          </Button>
          <VolumeComplaintPoll
            open={openVolumeComplaintPoll}
            onClose={() => setOpenVolumeComplaintPoll(false)}
            successfulCallback={handleVolumeComplaintPollSuccessfully}
          />
        </>
      )}
      <RatingButton
        variant="text"
        size="large"
        onClick={() => setOpenRatePlaylist(true)}
      >
        Rate the Playlist
      </RatingButton>
      <PlaylistRating
        open={openRatePlaylist}
        name={`${playlistName} rating`}
        onClose={() => setOpenRatePlaylist(false)}
      />
      <Snackbar
        open={openRequestSongSuccessfullyBar}
        autoHideDuration={5000}
        onClose={handleRequestSongBarOnClose}
        ContentProps={{ sx: { fontSize: 20 } }}
        message="Your request have been submitted!"
      />
      <Alert
        isDisplayed={displayAlert}
        variant="filled"
        onClick={() => setDisplayAlert(false)}
        message={alertMessage}
      />
    </Container>
  );
}

export default Playlist;
