import React, { useState, useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import ReactGA from 'react-ga4';

import Header from './common/Header';
import ProtectedRoute from './common/ProtectedRoute';
import Home from './Home';
import About from './About';
import TermsAndConditions from './TermsAndConditions';
import RegistrationForm from './Registration/RegistrationForm';
import GuestRegistrationForm from './Registration/Guest/GuestRegistrationForm';
import HostRegistrationForm from './Registration/Host/HostRegistrationForm';
import LoginForm from './Login/LoginForm';
import GuestLogin from './Login/Guest/index';
import HostLogin from './Login/Host/index';
import ForgotPasswordForm from './PasswordReset/ForgotPasswordForm';
import ResetPasswordForm from './PasswordReset/ResetPasswordForm';
import EditProfile from './Profile/EditProfile';
import MusicLibrary from './Profile/MusicLibrary';
import EmailHelp from './Registration/EmailHelp';
import Footer from './common/Footer';
import ContactUs from './ContactUs';
import PrivacyPolicy from './PrivacyPolicy';
import HostDashboard from './Hosts/Dashboard';
import Overview from './Hosts/Dashboard/Overview';
import PlaylistCustomization from './Hosts/Dashboard/PlaylistCustomization';
// import Statistics from './Hosts/Dashboard/Statistics';
// import Blacklist from './Hosts/Dashboard/Blacklist';
// import DefaultPlaylist from './Hosts/Dashboard/DefaultPlaylist';
// import Schedule from './Hosts/Dashboard/Schedule';
// import UserLimits from './Hosts/Dashboard/UserLimits';
import GuestDashboard from './Guests/Dashboard';
import Playlist from './Guests/Playlist';
import UnderConstruction from './UnderConstruction';
import ComingSoon from './ComingSoon';

import { getIsSiteEnabled } from '../services/api';
import { fetchProfile } from '../store/thunks/userThunks';
import { isGuestUser } from '../store/slices/userReducer';
import {
  USER_TYPE_GUEST,
  USER_TYPE_HOST,
  HOST_DASHBOARD_PATH,
  GUEST_DASHBOARD_PATH,
  REGISTER_HOST_PATH,
  REGISTER_GUEST_PATH,
  PASSWORD_RESET_PATH,
  EDIT_PROFILE_PATH,
  MUSIC_LIBRARY_PATH,
  ABOUT_US_PATH,
  FIND_SPOTIFY_EMAIL,
  TERMS_AND_CONDITIONS_PATH,
  PRIVACY_POLICY_PATH,
  CONTACT_US_PATH,
  HOST_LOGIN_PATH,
  GUEST_LOGIN_PATH,
  CUSTOMIZE_PLAYLIST,
  HOST_STATISTICS,
  BLACKLIST,
  DEFAULT_PLAYLIST,
  SCHEDULE,
  USER_TIME,
} from './common/constants';

import './App.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSiteEnabled, setIsSiteEnabled] = useState(false);
  const isGuest = useSelector(isGuestUser);

  const dispatch = useDispatch();

  useEffect(() => {
    const handleAppMount = async () => {
      const isEnabled = await getIsSiteEnabled();
      if (isEnabled) {
        await dispatch(fetchProfile());
      }
      setIsSiteEnabled(isEnabled);
      setIsLoading(false);
    };

    handleAppMount();
  }, [isGuest]);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.location.search,
    });
  }, []);

  if (isLoading) {
    return <CircularProgress color="secondary" sx={style} />;
  }

  if (!isSiteEnabled) {
    return <ComingSoon />;
  }

  return (
    <>
      {isGuest && <Header />}
      <Routes>
        <Route index element={<Home />} />
        <Route element={<RegistrationForm />}>
          <Route path={REGISTER_HOST_PATH} element={<HostRegistrationForm />} />
          <Route path={REGISTER_GUEST_PATH} element={<GuestRegistrationForm />} />
        </Route>
        <Route element={<LoginForm />}>
          <Route path={HOST_LOGIN_PATH} element={<HostLogin />} />
          <Route path={GUEST_LOGIN_PATH} element={<GuestLogin />} />
        </Route>
        <Route path={PASSWORD_RESET_PATH}>
          <Route index element={<ForgotPasswordForm />} />
          <Route path=":token" element={<ResetPasswordForm />} />
        </Route>
        <Route path={ABOUT_US_PATH} exact element={<About />} />
        <Route
          path={EDIT_PROFILE_PATH}
          element={
            <ProtectedRoute>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<EditProfile />} />
          <Route path={MUSIC_LIBRARY_PATH} element={<MusicLibrary />} />
        </Route>
        <Route path={FIND_SPOTIFY_EMAIL} exact element={<EmailHelp />} />
        <Route path={TERMS_AND_CONDITIONS_PATH} exact element={<TermsAndConditions />} />
        <Route path={PRIVACY_POLICY_PATH} exact element={<PrivacyPolicy />} />
        <Route path={CONTACT_US_PATH} exact element={<ContactUs />} />
        <Route
          path={HOST_DASHBOARD_PATH}
          element={
            <ProtectedRoute allowedUserType={USER_TYPE_HOST}>
              <HostDashboard />
            </ProtectedRoute>
          }
        >
          <Route index element={<Overview />} />
          <Route path={CUSTOMIZE_PLAYLIST} element={<PlaylistCustomization />} />
          <Route path={HOST_STATISTICS} element={<UnderConstruction />} />
          <Route path={BLACKLIST} element={<UnderConstruction />} />
          <Route path={DEFAULT_PLAYLIST} element={<UnderConstruction />} />
          <Route path={SCHEDULE} element={<UnderConstruction />} />
          <Route path={USER_TIME} element={<UnderConstruction />} />
        </Route>
        <Route
          path={GUEST_DASHBOARD_PATH}
          element={
            <ProtectedRoute allowOnly={USER_TYPE_GUEST}>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<GuestDashboard />} />
          <Route path=":venueId" element={<Playlist />} />
        </Route>
        <Route
          path={GUEST_DASHBOARD_PATH}
          element={
            <ProtectedRoute allowOnly={USER_TYPE_GUEST}>
              <Outlet />
            </ProtectedRoute>
          }
        >
          <Route index element={<GuestDashboard />} />
          <Route path=":venueId" element={<Playlist />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
