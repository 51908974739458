import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Link, useOutletContext } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@mui/material/Button';

import useAuth from '../../../hooks/useAuthFunctions';
import { REGISTER_GUEST_PATH } from '../../common/constants';

import theme from '../../common/theme';

const StyledLink = styled(Link)`
  color: ${theme.palette.juxy.secondary};
  margin-left: 5px;
  text-decoration: underline;
`;

const Text = styled.span`
  color: ${theme.palette.juxy.secondary};
  font-size: 16px;
`;

function GuestLogin() {
  const { handleSubmit } = useFormContext();
  const { renderAlert } = useOutletContext();
  const { guestLogin } = useAuth();

  const onSubmit = async (data) => {
    try {
      await guestLogin(data);
    } catch (error) {
      renderAlert(error.message);
    }
  };

  return (
    <>
      <Button
        type="button"
        variant="contained"
        color="secondary"
        sx={{ margin: '30px 0' }}
        onClick={handleSubmit((data) => onSubmit(data))}
      >
        Submit
      </Button>
      <div>
        <Text>Don`t have an account yet?</Text>
        <StyledLink to={REGISTER_GUEST_PATH}>Register</StyledLink>
      </div>
    </>
  );
}

export default GuestLogin;
