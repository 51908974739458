import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import useSocket from '../../../hooks/useSocket';
import {
  getUserId,
  addLikedSong,
  getLikedSongs,
  removeLikedSong,
} from '../../../store/slices/userReducer';
import { getVenueCurrentPlaylist } from '../../../services/api';
import { SMALL_SCREEN_SIZE, SONG_ADDED_EVENT, NEW_PLAYLIST_EVENT, SONG_REMOVED_EVENT } from '../../common/constants';

import SongCard from './SongCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  max-height: 75%;
  margin: 44px 0 0 44px;
  padding: 0;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    padding: 8px;
    margin: 0;
    max-height: 50%;
  }
`;

const PlaylistContainer = styled.div`
  max-height: 90%;
  padding: 10px;
  background-color: #1f1d29;
  border-radius: 10px;
  overflow-y: auto;
  scrollbar-color: #c7c7cc transparent;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  gap: 16px;
  padding: 0 10px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    gap: 10px;
  }
`;

const OptionsLabel = styled.p`
  font-size: 10px;
  color: #c7c7cc;
  margin: 0;
`;

function Playlist({ title = 'Current Playlist', buttons = [] }) {
  const [songList, setSongList] = useState([]);
  const [nowPlaying, setNowPlaying] = useState(undefined);
  const userId = useSelector(getUserId);
  const userLikedSongs = useSelector(getLikedSongs);

  const dispatch = useDispatch();

  const isLikedSong = (song) => userLikedSongs.some((s) => s.id === song?.id);
 
  const handlePlaylistUpdate = useCallback((eventData) => {
    switch (eventData.action) {
      case SONG_ADDED_EVENT:
        setSongList((prevList) => [...prevList, eventData.song]);
        break;
      case SONG_REMOVED_EVENT:
        setSongList((prevList) => prevList.filter(({ id }) => id !== eventData.song.id));
        if (eventData.song?.id === nowPlaying) {
          setNowPlaying(undefined);
        }
        break;
      case NEW_PLAYLIST_EVENT:
        setSongList(eventData.songs);
        break;
      default:
        return;
    }
  });

  const handleLikeSongClicked = (song) => {
    const isLiked = isLikedSong(song);
    const dispatchedSong = {
      id: song.id,
      name: song.name,
      artist: song.artist,
      image: song.image,
    };

    if (!isLiked) {
      dispatch(addLikedSong(dispatchedSong));
    } else {
      dispatch(removeLikedSong(dispatchedSong));
    }
  };

  const renderList = () =>
    songList?.map((song, index) => (
      <SongCard
        key={song.id || index}
        song={{ ...song, isPlaying: nowPlaying?.id === song.id, isLiked: isLikedSong(song) }}
        listIndex={index + 1}
        onLikeSongClick={() => handleLikeSongClicked(song)}
      />
    ));

  useSocket(undefined, { playlist_update: handlePlaylistUpdate, new_song: (eventData) => {setNowPlaying(eventData?.song)} });

  useEffect(() => {
    getVenueCurrentPlaylist(userId).then((list) => setSongList(list));
  }, []);

  return (
    <Container>
      <OptionsContainer>
        {title}
        {buttons}
        <OptionsContainer>
          <OptionsLabel>Fav</OptionsLabel>
          <OptionsLabel>Blacklist</OptionsLabel>
          <OptionsLabel>Remove</OptionsLabel>
        </OptionsContainer>
      </OptionsContainer>
      <PlaylistContainer>{renderList()}</PlaylistContainer>
    </Container>
  );
}

export default Playlist;
