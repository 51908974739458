import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const DEFAULT_WS_URL = `${process.env.REACT_APP_SERVER_WS_ADDR}/ws/business/ws`;

/**
 * hook for a web socket reference.
 *
 * @param {string} socketUrl The url for the socket to connect to.
 * @param {Object.<string, function>} eventHandlers A map of event names and event handlers for the socket to act upon.
 * @returns {Object} A reference to the defined web socket.
 */
const useSocket = (socketUrl = DEFAULT_WS_URL, eventHandlers = {}) => {
  const socketRef = useRef(null);

  const updateSocketEventHandlers = () => {
    socketRef.current.onmessage = (event) => {
      const eventData = JSON.parse(event.data);

      if (eventHandlers[eventData.event]) {
        eventHandlers[eventData.event](eventData);
      }
    };
  };

  useEffect(() => {
    if (!socketRef.current) {
      socketRef.current = new WebSocket(socketUrl);
    }

    updateSocketEventHandlers();

    return () => {
      socketRef.current.close();
      socketRef.current = null;
    };
  }, [socketUrl]);

  useEffect(() => {
    if (socketRef.current) {
      updateSocketEventHandlers();
    }
  }, [eventHandlers]);

  return socketRef.current;
};

useSocket.propTypes = {
  socketUrl: PropTypes.string,
  eventHandlers: PropTypes.exact({
    eventName: PropTypes.string,
    eventHandler: PropTypes.func,
  }),
};

export default useSocket;
