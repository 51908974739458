import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useOutletContext } from 'react-router-dom';

import { SMALL_SCREEN_SIZE } from '../../common/constants';
import UserDetailsStep from './UserDetailsStep';
import UserPreferencesStep from './UserPreferencesStep';
import ManualPreferencesStep from './ManualPreferencesStep';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin: 0 auto;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
  }
`;

const stepTitle = {
  0: 'Guest Registration',
  1: 'Connect Streaming App',
  2: 'Tell Us What You Like',
};

function RegistrationForm() {
  const { registrationStep, setRegistrationStep, setStepTitle, setAlert, setIsShowAlert, setDisplayButtons } = useOutletContext();

  useEffect(() => {
    setStepTitle(stepTitle[registrationStep]);
    setDisplayButtons(false);
  }, []);

  return (
    <Container>
      { registrationStep === 0 && (
        <UserDetailsStep
          showAlert={setIsShowAlert}
          setAlertMessage={setAlert}
          setRegistrationStep={setRegistrationStep}
        />
      ) }
      { registrationStep === 1 && (
        <UserPreferencesStep
          showAlert={setIsShowAlert}
          setAlertMessage={setAlert}
          setRegistrationStep={setRegistrationStep}
        />
      ) }
      { registrationStep === 2 && (
        <ManualPreferencesStep
          setAlertMessage={setAlert}
          showAlert={setIsShowAlert}
        />
      ) }
    </Container>
  );
}

export default RegistrationForm;
