export default {
  backgroundColor: 'transparent',
  border: '1px white solid',
  borderRadius: '50px',
  height: '50px',
  width: '80%',
  maxWidth: '320px',
  fontSize: '16px',
  margin: '6px 0',
  '@media (hover: hover)': {
    '&:hover': {
      backgroundColor: '#8c8c8c',
    },
  },
};
