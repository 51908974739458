import React from 'react';
import PropTypes from 'prop-types';

import SpotifyButton from '../../common/SpotifyButton';
import { USER_TYPE_GUEST, USER_TYPE_HOST } from '../../common/constants';

import { API_BASE_URL } from '../../../services/api';
import useSpotifyAuth from '../../../hooks/useSpotifyAuth';

function SpotifyAuth({
  label = 'Login with Spotify',
  userType = USER_TYPE_GUEST,
  onMessageReceived = () => {},
  onClick = () => {},
}) {
  const URL = `${API_BASE_URL}/${
    userType === USER_TYPE_GUEST ? 'account' : 'business'
  }/auth/spotify`;
  useSpotifyAuth();

  const handleClick = (event) => {
    event.preventDefault();
    onClick();
    if (window.location === window.parent.location) {
      const loginWindow = window.open(URL);
      window.addEventListener(
        'message',
        function listener(e) {
          if (e.data.type !== 'spotify') {
            return;
          }
          loginWindow.close();
          e.target.removeEventListener(e.type, listener);
          onMessageReceived(e.data);
        },
        false
      );
    } else {
      window.location = URL;
    }
  };

  return <SpotifyButton onClick={(e) => handleClick(e)} label={label} />;
}

SpotifyAuth.propTypes = {
  onMessageReceived: PropTypes.func,
  label: PropTypes.string,
  onClick: PropTypes.func,
  userType: PropTypes.oneOf([USER_TYPE_GUEST, USER_TYPE_HOST]).isRequired,
};

export default SpotifyAuth;
