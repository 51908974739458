import React from 'react';
import styled from 'styled-components';

import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { NAME } from './common/constants';
import history from '../history';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 0 16px;
  gap: 16px;
`;

const HeaderWithBackButton = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  font-size: 40px;
  font-weight: 700;
  padding-top: 30px;
`;

const Text = styled.p`
  font-size: 20px;
  overflow-wrap: normal;
  word-break: normal;
  align-self: flex-start;
`;

const backIconSx = {
  fontSize: '40px',
  padding: '0 16px',
  color: 'white',
  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
};

function About() {
  const handleOnClickBack = () => history.back(); // navigates back to the page before about.

  return (
    <Container>
      <HeaderWithBackButton>
        <IconButton
          variant="text"
          sx={backIconSx}
          onClick={handleOnClickBack}
        >
          <ArrowBackIcon fontSize="24px" />
        </IconButton>
        <p>
          About
          {' '}
          {`${NAME}`}
        </p>
      </HeaderWithBackButton>

      <Text>
        We are
        {' '}
        {`${NAME}`}
        ,
        <br />
        The first ever crowd focused social DJ
        allowing
        {' '}
        <span style={{ fontWeight: 700 }}>you</span>
        {' '}
        to take control
        <br />
        over your life's soundtrack!
      </Text>
      <Text>
        We are a new startup,
        <br />
        backed up by the Israeli Council for Higher Education,
        and we're currently running a pilot with the Wonderland resort.
      </Text>
      <Text>
        We invite you to join us and affect the music in Wonderland by connecting to our platform and engaging with the playlist.
      </Text>
      <Text>
        It's time to make your taste matter.
      </Text>
    </Container>
  );
}

export default About;
