import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #131313;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin: 30px 0;
`;

const List = styled.ol`
  margin: 0 15px;
  max-width: 650px;
`;

const SectionsTitle = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const Section = styled.div`
    display: flex;
    flex-flow: column;
    margin: 10px 0;
`;

function PrivacyPolicy() {
  return (
    <Container>
      <Title>Privacy Policy</Title>
      <List>
        <li>
          <SectionsTitle>Privacy Policy</SectionsTitle>
          <Section>
            This privacy policy sets out how Juxy uses and protects any information that
            you give Juxy when you use the application. Juxy is an early age start-up
            company that develops a platform that helps business owners manage the
            music at their venue, by building dynamic playlists that fit the current
            crowd’s musical preferences.
            (the “Application”). In order to do this, we need to know what is your taste
            in music.
          </Section>
          <Section>
            If you choose to register or submit information to the App and Services, you agree to the use
            of such data in accordance with this Privacy Policy.
            You may not use the App if you do not agree to any of the terms contained herein.
          </Section>
          <Section>
            Juxy may change this policy from time to time by updating this page. You should check this
            page from time to time to ensure that you are happy with any changes.
          </Section>
          <ul>
            <SectionsTitle>Information we collect about you</SectionsTitle>
            <Section>
              In order to use certain features of the App, you will need to register. When registering you
              will be asked to provide an email address and create a username and password.
            </Section>
            <Section>
              "personal information" and shall mean all information provided during your initial
              registration on the application and during your creation of a user profile, including, for
              example, your name, e-mail address, and other location information, your age, your gender
              and certain other demographic information or information that may enable online or offline
              contact with you. From the moment you register and begin using the app, we will monitor
              your activity on the app and log this information in our databases.
            </Section>
            <Section>
              We also collect information when you make use of the Application, including your
              comments, content and other material you may post, your browsing history, Location-based
              Information.
            </Section>
            <Section>
              In Addition, The Application will also record certain information automatically, such as your
              IP address, mobile device or computer, operating system and browser, as well as other
              metadata and statistics about Users of its App. Juxy may display this information publicly or
              provide it to others, provided that such information is provided in an aggregated and
              anonymized manner.
            </Section>
          </ul>
        </li>
        <li>
          <SectionsTitle>The Way Juxy Uses Information</SectionsTitle>
          <Section>
            We will not share your Personal Information with third parties without your explicit
            permission, except when required by law.
          </Section>
          <Section>
            Juxy uses the information that you provide or that we collect to operate, maintain, enhance,
            and provide all of the features and services found on the Juxy app as well as to track usergenerated content and Users Security.
          </Section>
          <Section>
            Any information we use collected from our users would only be used in a form of
            gathered information about a collective, and never as private personal information
            regarding you or any of the other users on our platform , and your name and personal
            details would not be linked in them.
            (E.g - 100 people at this venue liked this genre\song, but never
            “James,iamjames@email.com, from Germany liked this genre\song).
          </Section>
          <Section>
            Juxy does not share your personally identifiable information with other organizations for
            their marketing or promotional uses without your prior express consent. Please note,
            however, that any personally identifiable information that you voluntarily choose to display
            on any publicly available app Platform — such as when you publish contact information to
            complete your profile information — becomes publicly available and may be collected or
            harvested and used by others without restriction.
          </Section>
          <Section>
            If you supply Juxy with your email address, Juxy may occasionally send you e-mails to tell you
            about new features, request your feedback, or keep you up dated with Juxy's new features
            and products.
          </Section>
          <Section>
            We may use Personal Information and other information internally, for example, to help
            diagnose problems with our servers, and to make the Application more useful for you and for
            Juxy other users
          </Section>
        </li>
        <li>
          <SectionsTitle>Transfers of your Information </SectionsTitle>
          <Section>
            We may use third-party partners to help operate our app, and we may share your
            information with our affiliates, service providers and other third parties that provide
            products or services for or through this app or for our business.
          </Section>
          <Section>
            We may share statistical information with our partners, pursuant to commercial terms that
            we determine in our sole discretion. In addition, we may provide any information to third
            parties, so long as we have removed any information that identifies any specific individual or
            user, such as a name, address or contact information.
          </Section>
          <Section>
            Juxy may disclose automatically collected and other aggregate and/or anonymous nonpersonally-identifiable information (such as IP addresses, anonymous User usage data,
            listening preferences, referring or exit pages and URLs, platform types, number of clicks) with
            interested third parties to assist those parties in understanding the usage and demographic
            patterns for certain content, services, advertisements, promotions, or other functionality on
            the app.
          </Section>
          <Section>
            We may disclose some limited User information to affiliated companies or other businesses
            or persons to: provide app hosting, maintenance, and security services; fulfill orders;
            conduct data analysis and create reports; offer certain functionality; and assist Juxy in
            improving the app and creating new services features. We contractually require that these
            parties process such information in compliance with this Privacy Policy, we authorize only a
            limited use of such information, and we require these parties to use reasonable
            confidentiality measures.
          </Section>
          <Section>
            Juxy may disclose User information if required to do so by law or in the good-faith belief that
            such action is necessary to comply with state laws or respond to a court order etc'.
          </Section>
        </li>
        <li>
          <SectionsTitle>Security</SectionsTitle>
          <Section>
            We use certain commercially reasonable security measures to help keep your personal
            information safe, but we cannot guarantee that these measures will stop any users try to get
            around the privacy or security settings on the app through unforeseen and/or illegal activity.
            Therefore, while we strive to use commercially acceptable means to protect your Personal
            Information, we cannot guarantee its absolute security.
          </Section>
          <Section>
            Consequently, you acknowledge that there are circumstances in which your personal
            information may be accessed by unauthorized persons, and we cannot fully ensure or
            warrant the security of any information you transmit to the app, and you do so at your own
            risk.
          </Section>
        </li>
        <li>
          <SectionsTitle>How we use cookies</SectionsTitle>
          <Section>
            Cookies are small bits of information that we places on your computer. Juxy uses cookies to
            identify your Internet browser, and store Users' preferences. Data in cookies may be read to
            authenticate user sessions or provide services.
          </Section>
          <SectionsTitle>Consent</SectionsTitle>
          <Section>
            By disclosing any information to Juxy through the app and by using the app as a registered
            user, you are consenting to the collection, storage and use of your personal information by
            Juxy as set out in this Policy and the Terms and Conditions, and you understand and consent
            to the collection, maintenance, processing and transfer of such information in all countries
            and territories.
          </Section>
        </li>
        <li>
          <SectionsTitle>Children</SectionsTitle>
          <Section>
            Juxy is not intended for use by children. Anyone under the age of 18 is not permitted to use
            the Platform and must not attempt to register an account or submit any personal
            information to us. We do not knowingly collect any personal information from any person
            who is under the age of 13 or allow them to register an account. If it comes to our attention
            that we have collected personal data from a person under the age of 18, we will delete this
            information as quickly as possible.
          </Section>
          <li>
            <SectionsTitle>Changes to the Privacy Policy</SectionsTitle>
            <Section>
              If Juxy makes any changes to this Policy these changes will be detailed on this page in order
              to ensure that you are fully aware of what information is collected, how it is used and under
              what circumstances it will be disclosed.
            </Section>
            <Section style={{ marginBottom: '20px' }}>
              This Privacy Policy may be revised periodically without further notice to you and this will be
              reflected by a "last modified" date below. Please revisit this page to stay aware of any
              changes. In general, we only use your information in the manner described in the Privacy
              Policy in effect when we received that information, unless you agree otherwise. Your
              continued use of the app constitutes your agreement to this Privacy Policy and any future
              revisions.
            </Section>
          </li>
        </li>
      </List>
    </Container>
  );
}

export default PrivacyPolicy;
