import React from 'react';
import PropTypes from 'prop-types';
import MaterialDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const style = {
  color: 'white',
  backgroundColor: '#131313',
};

function Dialog(props) {
  const { onClose, open, children } = props;

  return (
    <MaterialDialog
      onClose={onClose}
      open={open}
      PaperProps={{
        style,
      }}
      TransitionComponent={Transition}
      {...props}
    >
      {children}
    </MaterialDialog>
  );
}

Dialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default Dialog;
