import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useMediaQuery } from '@mui/material';
import { SMALL_SCREEN_SIZE } from '../common/constants';
import settingsEmail from '../../images/settings_email.jpg';
import settingsMain from '../../images/settings_main.jpg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    width: 350px;
    font-size: 16px;
    white-space: pre-wrap;
    gap: 4px;
  }
`;

const HeaderWithBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

const Images = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? 'column' : 'row')};
  align-items: center;
`;

const Image = styled.img`
  height: 400px;
  width: 240px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    height: 300px;
  }
`;

const backIconSx = {
  color: 'white',
  margin: 0,
  padding: 0,

  '@media (hover: hover)': {
    '&:hover': {
      color: '#9c27b0',
    },
  },
};

function EmailHelp() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(`(max-width: ${SMALL_SCREEN_SIZE})`);
  const handleOnClickBack = () => navigate(-1);

  return (
    <Container>
      <HeaderWithBackButton>
        <Button variant="text" startIcon={<ArrowBackIcon style={{ fontSize: 35 }} />} sx={backIconSx} onClick={handleOnClickBack} />
        <h2>Not sure what is your spotify email?</h2>
      </HeaderWithBackButton>
      <ol>
        <li>Open the spotify app</li>
        <li>Tap on the settings icon</li>
        <li>You will be able to find your spotify account email address under your account settings</li>
      </ol>
      <Images isMobile={isMobile}>
        <Image alt="spotify settings" src={settingsMain} />
        {isMobile ? <ArrowDownwardIcon /> : <ArrowForward style={{ fontSize: '35px' }} />}
        <Image alt="spotify email" src={settingsEmail} />
      </Images>
    </Container>
  );
}

export default EmailHelp;
