import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import img from '../../../../images/JuxyDiscLogo.png';

const VenueImage = styled(CardMedia)`
  aspect-ratio: 1;
  max-height: 300px;
  max-width: 300px;
  margin: 15px;
  border-radius: 14px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: space-around;
`;

const DataContainer = styled.div``;

const cardStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  padding: '10px',
  backgroundColor: 'transparent',
  color: '#fff',
  boxShadow: 'none',
};

function VenueDataCard({ image = undefined, title = '', name = '' }) {

  return (
    <Card sx={cardStyle}>
      <VenueImage sx={{ width: '40%' }} alt={title} image={image || img} />
      <TitleContainer>
        <Typography component="h2" sx={{ fontSize: '23px' }}>
          {title}
        </Typography>
        {/* TODO: add venue's rating */}
      </TitleContainer>
      <DataContainer>
        {name}
        {/* TODO: add venue's location */}
        {/* TODO: add venue's currently playing genres */}
      </DataContainer>
    </Card>
  );
}

VenueDataCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  title: PropTypes.string,
};

export default VenueDataCard;
