// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

#root {
 height: 90vh;
}

.login-grid {
  position: absolute;
  right: 0;
  z-index: 1;
}

.button.item {
  cursor: pointer;
 }

.manual {
  font: inherit; 
  background: none; 
  border: none;
  padding: 0 1px;
  color: dodgerblue;
  cursor: pointer;
}

.button.home {
  margin: 5px;
}

.div.fields.input {
  margin: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/components/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;CACC,YAAY;AACb;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,eAAe;CAChB;;AAED;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":["body {\n  margin: 0;\n}\n\n#root {\n height: 90vh;\n}\n\n.login-grid {\n  position: absolute;\n  right: 0;\n  z-index: 1;\n}\n\n.button.item {\n  cursor: pointer;\n }\n\n.manual {\n  font: inherit; \n  background: none; \n  border: none;\n  padding: 0 1px;\n  color: dodgerblue;\n  cursor: pointer;\n}\n\n.button.home {\n  margin: 5px;\n}\n\n.div.fields.input {\n  margin: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
