/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { TERMS_AND_CONDITIONS_PATH, PRIVACY_POLICY_PATH } from '../common/constants';
import theme from '../common/theme';
import ControlledCheckbox from '../common/ControlledInputs/ControlledCheckbox';

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  color: ${theme.palette.juxy.secondary};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Link = styled.div`
  color: ${theme.palette.juxy.secondary};
  text-decoration: underline;
  margin-left: 5px;
`;

function TermsAndPrivacyPolicyBlock({
  control = {},
  checked = false,
  setChecked = () => {},
  defaultValue = false,
}) {
  const handleLinkOnClick = (path) => {
    window.open(`${window.location.origin}${path}`);
  };
  const renderLabel = () => (
    <LabelContainer>
      <LinkContainer>
        <span>Agree to the</span>
        <Link onClick={() => handleLinkOnClick(TERMS_AND_CONDITIONS_PATH)}>terms and conditions</Link>
      </LinkContainer>
      <LinkContainer>
        <span>and</span>
        <Link onClick={() => handleLinkOnClick(PRIVACY_POLICY_PATH)}>privacy policy</Link>
      </LinkContainer>
    </LabelContainer>

  );

  return (
    <ControlledCheckbox
      name="terms"
      label={renderLabel()}
      control={control}
      value={checked}
      setValue={setChecked}
      defaultValue={defaultValue}
      required
    />
  );
}

TermsAndPrivacyPolicyBlock.propTypes = {
  control: PropTypes.shape({}),
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  defaultValue: PropTypes.bool,
};

export default TermsAndPrivacyPolicyBlock;
