import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import CallMadeIcon from '@mui/icons-material/CallMade';
import IconButton from '../../common/IconButton';
import AddSongModal from './AddSongModal';

import useSocket from '../../../hooks/useSocket';
import { getUserId, getLikedSongs } from '../../../store/slices/userReducer';
import { getVenueCurrentPlaylist } from '../../../services/api';
import {
  SMALL_SCREEN_SIZE,
  SONG_ADDED_EVENT,
  NEW_PLAYLIST_EVENT,
  SONG_REMOVED_EVENT,
  CUSTOMIZE_PLAYLIST,
} from '../../common/constants';

import SongCard from './SongCard';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  max-height: 75%;
  margin: 44px 0 0 44px;
  padding: 0;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    padding: 8px;
    margin: 0;
    max-height: 50%;
  }
`;

const PlaylistContainer = styled.div`
  max-height: 90%;
  padding: 10px;
  background-color: #1f1d29;
  border-radius: 10px;
  overflow-y: auto;
  scrollbar-color: #c7c7cc transparent;
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  gap: 16px;
  padding: 0 10px;

  @media (max-width: ${SMALL_SCREEN_SIZE}) {
    gap: 10px;
  }
`;

const OptionsLabel = styled.p`
  font-size: 10px;
  color: #c7c7cc;
  margin: 0;
`;

const buttonsSx = {
  backgroundColor: 'transparent',
  color: '#ffffff',
  fontWeight: '100',
  maxWidth: '130px',
  maxHeight: '25px',
  minWidth: 'unset',
  minHeight: 'unset',
  border: '1px solid #ffffff',
  textWrap: 'nowrap',
  '&:hover': {
    backgroundColor: '#ffffff',
    color: '#9c27b0',
  },
  '.MuiButton-startIcon': {
    margin: '0',
    marginLeft: '-4px',
  },
  [`@media (max-width: ${SMALL_SCREEN_SIZE})`]: {
    fontSize: '10px',
  },
};

function Playlist({ title = 'Current Playlist' }) {
  const userId = useSelector(getUserId);
  const userLikedSongs = useSelector(getLikedSongs);

  const [songList, setSongList] = useState([]);
  const [nowPlaying, setNowPlaying] = useState(undefined);
  const [showAddSongModal, setShowAddSongModal] = useState(false);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handlePlaylistUpdate = useCallback((eventData) => {
    switch (eventData.action) {
      case SONG_ADDED_EVENT:
        setSongList((prevList) => [...prevList, eventData.song]);
        break;
      case SONG_REMOVED_EVENT:
        setSongList((prevList) => prevList.filter(({ id }) => id !== eventData.song.id));
        if (eventData.song?.id === nowPlaying) {
          setNowPlaying(undefined);
        }
        break;
      case NEW_PLAYLIST_EVENT:
        setSongList(eventData.songs);
        break;
      default:
        return;
    }
  });

  const renderList = () =>
    songList?.map((song, index) => {
      return (
        <SongCard
          key={song.id || index}
          song={{
            ...song,
            isPlaying: nowPlaying?.id === song.id,
            isLiked: userLikedSongs?.includes(song.id),
          }}
          listIndex={index + 1}
        />
      );
    });

  useSocket(undefined, {
    playlist_update: handlePlaylistUpdate,
    new_song: (eventData) => {
      setNowPlaying(eventData?.song);
    },
  });

  useEffect(() => {
    getVenueCurrentPlaylist(userId).then((list) => setSongList(list));
  }, []);

  return (
    <Container>
      <OptionsContainer>
        {title}
        <IconButton
          label={`Add ${pathname === CUSTOMIZE_PLAYLIST ? 'Song' : ''}`}
          sx={buttonsSx}
          startIcon={<AddIcon />}
          onClick={() => setShowAddSongModal(true)}
        />
        {pathname !== CUSTOMIZE_PLAYLIST && (
          <IconButton
            label="Edit"
            sx={buttonsSx}
            startIcon={<CallMadeIcon />}
            onClick={() => navigate(CUSTOMIZE_PLAYLIST)}
          />
        )}
        <OptionsContainer>
          <OptionsLabel>Fav</OptionsLabel>
          <OptionsLabel>Blacklist</OptionsLabel>
          <OptionsLabel>Remove</OptionsLabel>
        </OptionsContainer>
      </OptionsContainer>
      <PlaylistContainer>{renderList()}</PlaylistContainer>
      <AddSongModal isOpen={showAddSongModal} onClose={() => setShowAddSongModal(false)} />
    </Container>
  );
}

export default Playlist;
