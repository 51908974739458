import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #131313;
`;

const Title = styled.span`
  font-size: 32px;
  font-weight: bold;
  margin: 30px 0;
`;

const List = styled.ol`
  margin: 0 15px;
  max-width: 650px;
`;

const SectionsTitle = styled.span`
  font-weight: bold;
  text-decoration: underline;
`;

const Section = styled.div`
    display: flex;
    flex-flow: column;
    margin: 10px 0;
`;

function TermsAndCondtions() {
  return (
    <Container>
      <Title>Term and conditions</Title>
      <List>
        <li>
          <SectionsTitle>PREAMBLE</SectionsTitle>
          <ol>
            <Section style={{ fontWeight: 700 }}>
              1.1. Please read these Terms and Conditions of Service ("Terms") carefully before using this Application. By accessing or using the Application, you (the "User") accept and agree to all the Terms and Conditions below. If any of the Terms is unacceptable to you, please do not use the Application and refrain from using it in any way.
            </Section>
          </ol>
          <ol>
            <Section>
              1.2. Hello and welcome to Juxy ("The Application", "The App"). Juxy is an early age start-up company that develops a platform that helps business owners manage the music at their venue, by building dynamic playlists that fit their crowd’s musical preferences. In order to do this, we need to know what is your taste in music.
            </Section>
          </ol>
          <ol>
            <Section>
              1.3. Please read carefully These Terms, which govern, together with our Privacy Policy, your use of this Application.  THIS AGREEMENT WILL BECOME BINDING UPON YOU UPON USING THE APPLICATION.
            </Section>
          </ol>
          <ol>
            <Section>
              1.4. Juxy may occasionally amend or update these Terms at any time to reflect changes in our practices and services, without giving any prior notice to users. All amendments will come into effect and bind users once posted on this Application. Users are encouraged to check these Terms frequently for amendments.
            </Section>
          </ol>
          <ol>
            <Section>
              1.5. If you have any questions regarding the Terms, please contact us.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>
            GENERAL RESTRICTIONS ON USE
          </SectionsTitle>
          <ol>
            <Section>
              2.1. Use of and access to the Application is void where prohibited by law. By using the Application, you represent and warrant that (a) any and all registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; and (c) your use of the Application does not violate any applicable law or regulation or any obligation.
            </Section>
          </ol>
          <ol>
            <Section>
              2.2. It is strictly forbidden and you agree not to (a) unlawfully access the application, its server and/or any files related to the application and/or use any robots, spiders, crawlers and the like, for the purpose of data mining, reverse engineering or any other similar methods; (b) transmit to or via the application any content which contains any viruses, bugs, warms and other malicious programs, which could be harmful to the application or to other computers, violate third parties privacy or prevent them from using the application.
            </Section>
          </ol>
          <ol>
            <Section>
              2.3. The application can remove any data and/or content and/or to refrain from fully or partially advertising such content, according to its own discretion and without any prior notice.
            </Section>
          </ol>
          <ol>
            <Section>
              2.4. You may not use any hardware or software intended to damage or interfere with the proper working of the Application or to surreptitiously intercept any system, data or personal information from the Application. You agree not to interrupt or attempt to interrupt the operation of the Application in any way, including alter, modify or reverse-engineer.
            </Section>
          </ol>
          <ol>
            <Section>
              2.5. ou may not use any information from this application to send any spam or other unauthorized or unsolicited commercial communications or publicity materials.
            </Section>
          </ol>
          <ol>
            <Section>
              2.6. You agree not to collect or harvest any personal data of any user of the application or any Service (and agree that this shall be deemed to include Juxy account names).
            </Section>
          </ol>
          <ol>
            <Section>
              2.7. You agree not to use the application or the Services (including the comments and email features in the application) for the solicitation of business in the course of trade or in connection with a commercial enterprise.
            </Section>
          </ol>
          <ol>
            <Section>
              2.8. No part of this application may be copied, reproduced, published, publicly performed or displayed, transmitted, communicated, made available to the public, stored in any electronic retrieval system or distributed in any medium, without the prior written consent of Juxy, except as expressly allowed herein.
            </Section>
          </ol>
          <ol>
            <Section>
              2.9. Juxy may alter or amend any part of the Application at any time upon its sole discretion.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>REGISTRATION</SectionsTitle>
          <ol>
            <Section>
              3.1. At this stage, you can let us know what you like in one of the following ways.
              <ol>3.1.1. Syncing your Spotify account to your Juxy account.</ol>
              <ol>3.1.2. Giving us inputs about your musical preferences, by selecting “Manual Preferences” and selecting genres that you like.</ol>
            </Section>
          </ol>
          <ol>
            <Section>
              3.2. By syncing your spotify account, you allow us to receive data regarding your listening history on Spotify (liked songs, playlists that you’ve built, artists that you followed, etc..). We would also be allowed to receive data about your profile (email account, name, profile picture, subscription plan type, etc…).
            </Section>
            <Section>
              We will only be using this information internally, for the purpose of learning about your musical preferences, so we can operate our algorithm, and calculate what you and the people around you like to listen to, so we can try to fit the playlist to the crowd, and create a better customer experience for you and the other people using our platform, now and in the future. We will only be using this information internally, and will not transfer any private information regarding you, your listening preferences or any of the details you provide us to third parties or other users on our Platform. Any information we use collected from our users would only be used in a form of gathered information about a collective, and never as private personal information regarding you or any of the other users on our platform , and your name and personal details would not be linked in them. (E.g - 100 people at this venue liked this genre\song, but never “James,iamjames@email.com, from Germany liked this genre\song).
            </Section>
          </ol>
          <ol>
            <Section>
              3.3. When you register to use the App, you are required to provide your email address, and will choose a username and password for your account. You must ensure that the email address that you provide is, and remains, valid and monitored by you. Your email address and any other information you choose to provide about yourself will be treated in accordance with our Privacy Policy, as may be updated from time to time.
            </Section>
          </ol>
          <ol>
            <Section>
              3.4. You agree not to use the account of another User at any time, and not to allow any other person make use of your user name and password.
            </Section>
          </ol>
          <ol>
            <Section>
              3.5. We care deeply about our users and their privacy, and we also value our own at the moment. As we are currently in the early stage of running a closed pilot with Wonderland resort, we invite you to join our communities, but kindly request you to respect our privacy as well, and not to spread or share information & screenshots regarding our service.
            </Section>
          </ol>
          <ol>
            <Section>
              3.6. We care about our users and their opinions and suggestions. Because we are still in development, we reserve the right to send you a survey to fill out. Filling the questionnaire is optional, and we would not spam you with promotions, but sincerely appreciate your opinion and would be grateful for any input you give us. By agreeing to our terms and conditions you agree to receive email communication from us. We promise to keep it minimal.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>TERMINATION OF ACCOUNT</SectionsTitle>
          <ol>
            <Section>
              4.1. Juxy may for any reason, in its sole discretion and without prior notice, terminate your account, and remove from the Application any Content associated with your account, in the event that Juxy believes that such User has breached, or intends to breach, any of User's representations, warranties, or obligations under these Terms or has contravened any applicable law. Grounds for such termination may include, inter alia, (i) extended periods of inactivity, (ii) violation of these Terms, (iii) fraudulent, harassing or abusive behavior, or (iv) behavior that is harmful to other users.
            </Section>
          </ol>
          <ol>
            <Section>
              4.2. Juxy reserves the right at any time and from time to time to modify, suspend, or discontinue, temporarily or permanently, the Services or any part thereof, or User's access thereto, and to modify, suspend or delete the App, the Accounts or any part thereof.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>
            GENERAL RESTRICTIONS ON USE
          </SectionsTitle>
          <ol>
            <Section>
              5.1. Certain content and information provided on and through this App and Services, including, without limitation, Juxy’s logo, trademark, graphics, Content, information, text, images, data and other material displayed, available or present on the App or Services, that were not originated from Users, as well as the  look and feel of the App and Services (collectively, the “Juxy Content”) are the copyrighted and/or trademarked work of Juxy solely, and may not be used without express written permission from us, other than for attribution.
            </Section>
          </ol>
          <ol>
            <Section>
              5.2. Except as expressly permitted herein, you may not copy, further modify, duplicate, distribute, display, perform, sublicense, republish, retransmit, reproduce, create remix of, transfer, sell or otherwise use the Application or any User Content appearing in the Application.  You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Application. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms grants you any right to use any trademark, service mark, logo, or trade name of Juxy or any third party.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>DMCA COMPLIANCE</SectionsTitle>
          <ol>
            <Section>
              6.1. Under the Digital Millennium Copyright Act of 1998 (the “DMCA”), it is Juxy’s policy to respond to copyright owners who believe material appearing on the Application infringes their rights.  If you believe that something appearing on the Application infringes your copyright, you may send us a notice requesting that it be removed, or access to it blocked. If you believe that such a notice has been wrongly filed against you, the DMCA lets you send us a counter-notice.
            </Section>
          </ol>
          <ol>
            <Section>
              6.2. otices and counter-notices must meet the DMCA’s requirements. Be aware that there can be substantial penalties for false claims.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>LIMITATION OF LIABILITY</SectionsTitle>
          <ol>
            <Section>
              7.1. We are a new start-up, and we’re still developing our platform. Because of that, some of the features will not be working some of the time, or could be used to understand the user interest and need of their development.
              By agreeing to our terms and conditions, you are waiving any right to file a lawsuit against us due to your dissatisfaction with any of our features or the operation of our platform.
            </Section>
          </ol>
          <ol>
            <Section>
              7.2. The application and/or anyone on its behalf, shall not be liable for any damage, harm or loss, direct or indirect, resulting from any use of the application, whether to the user and/or to any third party, as a result of the use of the content and data contained in the application.
            </Section>
          </ol>
          <ol>
            <Section>
              7.3. YOU ACKNOWLEDGE AND AGREE THAT YOU ASSUME FULL RESPONSIBILITY FOR YOUR USE OF THE APPLICATION. IN NO EVENT SHALL JUXY, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING  ANY LOSS OF INCOME, PROFITS, BUSINESS OR OPPORTUNITY, OR ANY LOSS HARM OR DAMAGE TO NAME OR GOODWILL RESULTING FROM ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF THE CONTENT OF THIS APPLICATION, OR ANY APPLICATION LINKED HERETO; (II) HARM, DAMAGE OR INJURY TO PERSON OR PROPERTY OF ANY NATURE OR KIND WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF  THIS APPLICATION; (III) DAMAGE, LOSS OR HARM RESULTING FROM ANY MALFUNCTION OR UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN, (IV) DAMAGE, LOSS OR HARM RESULTING FROM ANY INTERRUPTION TO OR CESSATION OF COMMUNICATION WITH THIS APPLICATION; (V) ANY DAMAGE, LOSS OR HARM RESULTING FROM ANY VIRUSES, BUGS, WORMS, TROJAN HORSES AND OTHER HARMFUL PROGRAMS TRANSMITTED TO, THROUGH OR FROM THIS APPLICATION BY ANY THIRD PARTY.
            </Section>
          </ol>
          <ol>
            <Section>
              7.4. The application does not oblige to save a backup of all the content stored and/or present in the application. Additionally, the application may sustain the application, in whole or in part, for the purpose of maintenance, update, upgrade or for any other need, and it shall not be liable to any loss, harm or damage, which may be resulted due to such act.
            </Section>
          </ol>
          <ol>
            <Section>
              7.5. The application will not be liable to any loss, harm or damage resulting from: (1) any reliance placed by you on the completeness, accuracy or existence of any advertising, or as a result of any relationship or transaction between you and any advertiser or sponsor whose advertising appears on the Service; (2) any changes which Juxy may make to the Service, or for any permanent or temporary cessation in the provision of the Service (or any features within the Service); (3) the deletion of, corruption of, or failure to store, any Content and other communications data maintained or transmitted by or through your use of the Service.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>INDEMNIFICATION</SectionsTitle>
          <ol>
            <Section>
              8.1. Juxy may, at its sole discretion, disable/suspend User's account and User's access to, or use of, the App and/or the Services and Juxy may recover from User any losses, damages, costs or expenses incurred by Juxy resulting from or arising out of User's non-compliance with any provision of these Terms.
            </Section>
          </ol>
          <ol>
            <Section>
              8.2. You agree to indemnify and hold Juxy and its shareholders, directors, officers, employees, advisors, subsidiaries, affiliates and agents (the “Indemnified Parties”), harmless from and against all loss, damages, expenses, claims, demands and liabilities incurred or suffered by the Indemnified Parties arising out of (a) any action or omission (including representation) made by You to third parties, creating any obligation or liability regarding the Juxy Services which Juxy has not specifically assumed or approved under these Terms, (b) your breach of these Terms or the documents it incorporates by reference, or (c) your failure to comply with all applicable laws, regulations, ordinances and treaty requirements, relating, among others, to data protection , privacy rights, and copyrights.
            </Section>
          </ol>
        </li>
        <li>
          <SectionsTitle>MISCELLANEOUS</SectionsTitle>
          <ol>
            <Section>
              9.1. Entire Agreement. This is the entire agreement between you and Juxy relating to the subject matter of these Terms. These Terms may not be modified in any way except in a writing signed by Juxy.
            </Section>
          </ol>
          <ol>
            <Section>
              9.2. Separate Agreements. If any provision or provisions of this Agreement should be held to be unenforceable, such holding will not affect the other provisions hereof which shall remain in full force.
            </Section>
          </ol>
          <ol>
            <Section>
              9.3. No failure of Juxy to invoke or enforce any of the Terms and Conditions shall be considered as a waiver of any right of Juxy.
            </Section>
          </ol>
          <ol>
            <Section>
              9.4. Provision of Services. Juxy reserves the right at any time and from time to time to modify, suspend, or discontinue, temporarily or permanently, the Services or any part thereof, or User’s access thereto, and to modify, suspend or delete the App or any part thereof.
            </Section>
          </ol>
          <ol>
            <Section>
              9.5. Severability. If any provision of these Terms is held unenforceable, then such provision will be modified to reflect the parties’ intention. All remaining provisions of these Terms shall remain in full force and effect.
            </Section>
          </ol>
          <ol>
            <Section>
              9.6. Assignment. User may not assign this agreement. Juxy may assign this agreement at any time to the successor in interest in connection with a merger, consolidation or other corporate reorganization.
            </Section>
          </ol>
          <ol>
            <Section>
              9.7. Jurisdiction and Applicable Law. The laws of the State of Israel govern exclusively to these Terms and your use of the Application, and you irrevocably consent to the jurisdiction of the courts located in the Tel Aviv District for any action arising out of or relating to these Terms.
            </Section>
          </ol>
        </li>
      </List>
    </Container>
  );
}

export default TermsAndCondtions;
